import React from 'react'
import { makeStyles, createStyles, Theme, Typography, Box } from '@material-ui/core'
import { IgMediaPostType } from '../gql-global'

import { ReactComponent as PostGestureLogo } from '../icons/post_gesture.svg'
import { ReactComponent as CaretIcon } from '../icons/caret.svg'
import { ReactComponent as IGIcon } from '../icons/instagram.svg'
import { ReactComponent as TTIcon } from '../icons/tiktok_logo_outline.svg'
import CardMultiSelect from '../components/CardMultiSelect'
import { AccountLabel } from '../components/LabelMenu/LabelMenu'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      '&:hover': {
        cursor: 'default',
      },
      color: theme.palette.primary.dark,
    },
    underline: {
      textDecoration: 'underline',
      textUnderlineOffset: '4px',
      marginLeft: '16px',
      color: theme.palette.primary.dark,
    },
    clickableText: {
      display: 'flex',
      justifyContent: 'center',
    },
    caret: {
      color: theme.palette.primary.main,
      marginTop: '11px',
      position: 'relative',
      marginLeft: '6px',
    },
    icon: {
      marginTop: '32px',
      display: 'block',
      margin: 'auto',
      textAlign: 'center',
    },
    renderedValuesContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: '-12px',
      marginBottom: '-4px',
      width: '225px',
      flexDirection: 'row',
    },
    renderedText: {
      marginRight: '4px',
      color: theme.palette.primary.dark,
      fontSize: '12px',
    },
    renderedPlatformIcon: {
      marginTop: '3px',
      color: theme.palette.primary.dark,
      width: '12px',
      height: '12px',
    },
  }),
)

interface RenderedPostCriteriaValuesProps {
  values: (IgMediaPostType | 'TTFeed')[]
}

export function RenderedPostCriteriaValues({ values }: RenderedPostCriteriaValuesProps): React.ReactElement {
  const classes = useStyles()

  const igValues = values.filter(v => Object.values(IgMediaPostType).includes(v as IgMediaPostType))
  const ttValues = values.filter(v => !Object.values(IgMediaPostType).includes(v as IgMediaPostType))

  const igText: React.ReactElement[] = igValues.map((v, idx) => {
    const lower = v.toLowerCase()
    const formated = lower.charAt(0).toUpperCase() + lower.slice(1)
    return (
      <Typography className={classes.renderedText} key={idx}>
        {formated}
        {idx !== values.length - 1 ? ',' : ''}{' '}
      </Typography>
    )
  })
  return (
    <Box>
      {igValues.length > 0 && (
        <Box className={classes.renderedValuesContainer}>
          <IGIcon className={classes.renderedPlatformIcon} />
          <Typography className={classes.renderedText}> : </Typography>
          {igText}
        </Box>
      )}
      {ttValues.length > 0 && (
        <Box className={classes.renderedValuesContainer} pt={igValues.length > 0 ? 3 : 0}>
          <TTIcon className={classes.renderedPlatformIcon} />
          <Typography className={classes.renderedText}>: Feed</Typography>
        </Box>
      )}
    </Box>
  )
}

interface PostCriteriaFieldProps {
  selectedSocialAccounts: (AccountLabel<string> & { isIG: boolean })[]
  value: Set<IgMediaPostType>
  setValue: (value: Set<IgMediaPostType | 'TTFeed'>) => void
  resetValue: () => Set<IgMediaPostType | 'TTFeed'>
  isValueSelected: () => boolean
  disabled?: boolean
}

function PostCriteriaField({
  selectedSocialAccounts,
  value,
  setValue,
  resetValue,
  isValueSelected,
  disabled = false,
}: PostCriteriaFieldProps): React.ReactElement {
  const classes = useStyles()

  const hasIG = !!selectedSocialAccounts.find(account => account.isIG)
  const hasTT = !!selectedSocialAccounts.find(account => !account.isIG)

  function setValueHelper(value: Set<string>): void {
    const arr = Array.from(value).filter((v: string) => {
      return Object.values(IgMediaPostType).includes(v as IgMediaPostType)
    }) as IgMediaPostType[]
    setValue(new Set(arr))
  }

  const IGOptions = [
    {
      id: IgMediaPostType.Feed as string,
      label: 'Instagram Post',
    },
    {
      id: IgMediaPostType.Story as string,
      label: 'Instagram Story',
    },
    {
      id: IgMediaPostType.Reels as string,
      label: 'Instagram Reel',
    },
  ] as readonly { id: string; label: string }[]
  const TTOptions = [
    {
      id: 'TTFeed',
      label: 'Tiktok Post',
    },
  ] as readonly { id: string; label: string }[]

  const options = hasIG && hasTT ? IGOptions.concat(TTOptions) : hasIG ? IGOptions : TTOptions

  function resetValueHelper(): Set<string> {
    if (hasIG) {
      return resetValue()
    }
    return new Set(['TTFeed'])
  }

  const fieldValues =
    hasTT && hasIG
      ? new Set(Array.from(value as Set<IgMediaPostType | 'TTFeed'>).concat(['TTFeed']))
      : !hasIG
      ? new Set(['TTFeed'])
      : value

  function isInvalid(vals: ReadonlySet<string>) {
    const igSelected = Array.from(vals).filter(v => v !== 'TTFeed').length > 0
    const ttSelected = vals.has('TTFeed')

    if (hasIG && hasTT) {
      return !igSelected || !ttSelected
    }
    if (hasIG) {
      return !igSelected
    }
    if (hasTT) {
      return !ttSelected
    }
    return true
  }

  return (
    <Box>
      <CardMultiSelect
        value={fieldValues}
        setValue={setValueHelper}
        resetValue={resetValueHelper}
        options={options}
        isValueSelected={isValueSelected}
        forceShowCheckmark={!selectedSocialAccounts.length || (!hasIG && hasTT)}
        disabled={disabled}
        allowClear={false}
        isInvalid={isInvalid}
      >
        <PostGestureLogo className={classes.icon} />
        <Typography className={classes.typography}>Create a</Typography>
        <Box className={classes.clickableText}>
          <Typography className={`${classes.typography} ${classes.underline}`}>
            social post
            <CaretIcon className={classes.caret} />
          </Typography>
        </Box>
      </CardMultiSelect>
      <RenderedPostCriteriaValues values={Array.from(fieldValues as Set<IgMediaPostType | 'TTFeed'>)} />
    </Box>
  )
}

export default PostCriteriaField

import React, { ReactElement, useEffect } from 'react'
import * as yup from 'yup'
import { isTypeName } from '../../../types/utility'
import {
  Box,
  Button,
  createStyles,
  Dialog,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core'
import { ReactComponent as CrossIcon } from '../../../icons/cross.svg'
import { Field, Form, Formik } from 'formik'
import DiscountGenerationForm from './DiscountGenerationForm'
import {
  EcommCustomerSegmentType,
  EcommDiscountAppliesToEnum,
  EcommDiscountClassEnum,
  EcommDiscountCodeFormatEnum,
  EcommDiscountCodeTypeEnum,
  EcommDiscountCodeValueTypeEnum,
  EcommDiscountCustomerEligibilityEnum,
  EcommDiscountPurchaseTypeEnum,
  IntegrationType,
} from '../../../gql-global'
import { OptionType } from './ProductCollectionPickerMenu'
import { EcommDiscountCodeFragment } from './operations/ecomm-discount-code.generated'
import { UpsertProgramEcommDiscountCodeMutationVariables } from './operations/upsert-program-ecomm-discount-code.generated'
import { useCampaignEcommIntegrationLazyQuery } from './operations/campaign-ecomm-integration.generated'
import { Skeleton } from '@material-ui/lab'
import { useToast } from '../../../components/Alert/ToastProvider'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    caption: {
      fontSize: 14,
      marginBottom: 10,
      display: 'block',
    },
    input: {
      margin: '15px 0',
      '& .MuiInput-root': {
        fontSize: 14,
      },
      '& label.Mui-disabled': {
        color: theme.palette.primary.main,
      },
      '& .MuiInput-underline.Mui-disabled:before': {
        borderBottomStyle: 'solid',
        borderColor: theme.palette.text.hint,
      },
      '& .MuiFormHelperText-filled': {
        marginBottom: -15,
        fontSize: 14,
      },
    },
  }),
)

const schema = yup.object().shape({
  codeFormat: yup
    .mixed()
    .oneOf([...Object.values(EcommDiscountCodeFormatEnum)])
    .required(),
  discountClass: yup
    .mixed()
    .oneOf(['', ...Object.values(EcommDiscountClassEnum)])
    .required(),
  value: yup.mixed().when('discountClass', {
    is: val => !!val,
    then: yup.number().required(),
  }),
  valueType: yup.mixed().when('discountClass', {
    is: val => !!val,
    then: yup
      .mixed()
      .oneOf(['', ...Object.values(EcommDiscountCodeValueTypeEnum)])
      .required(),
  }),
  appliesTo: yup.mixed().when('discountClass', {
    is: val => val === EcommDiscountClassEnum.Product,
    then: yup
      .mixed()
      .oneOf(['', ...Object.values(EcommDiscountAppliesToEnum)])
      .required(),
  }),
  combineWithFreeShippingDiscounts: yup.boolean().required(),
  appliesOnEachItem: yup.boolean(),
  combineWithOtherProductDiscounts: yup.boolean(),
  combineWithOrderDiscounts: yup.boolean(),
  selectedItems: yup.array<OptionType>(),
  purchaseType: yup.mixed().when('sellsSubscriptions', {
    is: val => val,
    then: yup
      .mixed()
      .oneOf(['', ...Object.values(EcommDiscountPurchaseTypeEnum)])
      .required(),
  }),
  overrideMessage: yup.boolean(),
  appliesOncePerCustomer: yup.boolean().required(),
  useUsageLimit: yup.boolean(),
  usageLimit: yup.mixed().when('useUsageLimit', {
    is: val => !!val,
    then: yup.number().required(),
  }),
  customerEligibility: yup.mixed().when('discountClass', {
    is: val => !!val,
    then: yup
      .mixed()
      .oneOf(['', ...Object.values(EcommDiscountCustomerEligibilityEnum)])
      .required(),
  }),
  selectedCustomerSegments: yup.array<EcommCustomerSegmentType>(),
})

type FormSchemaType = yup.InferType<typeof schema>

interface AffiliateCodesSettingsDialogProps {
  campaignId?: string
  programId?: string
  ecommDiscountCode?: EcommDiscountCodeFragment | null
  open: boolean
  onClose(): void
  onSubmit: (params: UpsertProgramEcommDiscountCodeMutationVariables) => Promise<void>
  discountType: EcommDiscountCodeTypeEnum
}

function AffiliateCodesSettingsDialog(props: AffiliateCodesSettingsDialogProps): ReactElement {
  const { campaignId, programId, ecommDiscountCode, open, onClose, onSubmit, discountType } = props
  const { showToast } = useToast()
  const classes = useStyles()

  const [executeEcommIntegrationQuery, ecommIntegrationQueryResults] = useCampaignEcommIntegrationLazyQuery({
    variables: { id: campaignId || '' },
  })
  const ecommIntegrationLoaded = !ecommIntegrationQueryResults.loading && !ecommIntegrationQueryResults.error

  useEffect(() => {
    if (open) {
      executeEcommIntegrationQuery()
    }
  }, [executeEcommIntegrationQuery, campaignId, open])

  useEffect(() => {
    if (!!ecommIntegrationQueryResults.error) {
      showToast({
        title: 'Error getting affiliate code settings',
        message:
          'Something went wrong when trying to get the discount code settings. Please try again in a few minutes.',
      })
    }
  }, [showToast, ecommIntegrationQueryResults.error])

  const programEcommIntegration = ecommIntegrationQueryResults.data?.campaign?.program?.ecommIntegration

  const initialValues = {
    codeFormat: ecommDiscountCode?.codeFormat || EcommDiscountCodeFormatEnum.FirstLastName,
    discountClass: ecommDiscountCode?.discountClass || '',
    sellsSubscriptions:
      programEcommIntegration && 'sellsSubscriptions' in programEcommIntegration
        ? programEcommIntegration.sellsSubscriptions || false
        : false,
    value: ecommDiscountCode?.value || '',
    valueType: ecommDiscountCode?.valueType || EcommDiscountCodeValueTypeEnum.Percentage,
    appliesOnEachItem: ecommDiscountCode?.appliesOnEachItem ?? true,
    appliesTo: ecommDiscountCode?.products?.length
      ? EcommDiscountAppliesToEnum.Product
      : ecommDiscountCode?.collections?.length
      ? EcommDiscountAppliesToEnum.Collection
      : '',
    combineWithFreeShippingDiscounts: ecommDiscountCode?.combinesWithShippingDiscounts || false,
    combineWithOtherProductDiscounts: ecommDiscountCode?.combinesWithProductDiscounts || false,
    combineWithOrderDiscounts: ecommDiscountCode?.combinesWithOrderDiscounts || false,
    selectedItems: ecommDiscountCode?.collections?.length
      ? (ecommDiscountCode?.collections as OptionType[])
      : ecommDiscountCode?.products?.length
      ? (ecommDiscountCode?.products as OptionType[])
      : [],
    purchaseType: getDiscountCodePurchaseType(ecommDiscountCode),
    overrideMessage: ecommDiscountCode?.overrideMessage || false,
    useUsageLimit: !!ecommDiscountCode?.usageLimit || false,
    usageLimit: ecommDiscountCode?.usageLimit || null,
    appliesOncePerCustomer: ecommDiscountCode?.appliesOncePerCustomer || false,
    customerEligibility: ecommDiscountCode?.customerEligibility || EcommDiscountCustomerEligibilityEnum.AllCustomers,
    selectedCustomerSegments: ecommDiscountCode?.customerSegments || [],
  }

  const onSubmitForm = async (values: FormSchemaType) => {
    if (!programId) return

    if (values.discountClass === EcommDiscountClassEnum.Order) {
      values.selectedItems = []
    }

    await onSubmit({
      programId,
      data: {
        value: values.value,
        valueType: values.valueType ?? EcommDiscountCodeValueTypeEnum.Percentage,
        combinesWithShippingDiscounts: values.combineWithFreeShippingDiscounts,
        combinesWithProductDiscounts: values.combineWithOtherProductDiscounts,
        combinesWithOrderDiscounts: values.combineWithOrderDiscounts,
        collectionIds:
          values?.appliesTo === EcommDiscountAppliesToEnum.Collection ? values.selectedItems.map(s => s.id) : [],
        productIds: values?.appliesTo === EcommDiscountAppliesToEnum.Product ? values.selectedItems.map(s => s.id) : [],
        purchaseType: values.purchaseType,
        appliesTo: values.appliesTo || null,
        codeType: discountType,
        overrideMessage: values.overrideMessage,
        discountClass: values.discountClass,
        codeFormat: values.codeFormat,
        appliesOnEachItem: values.appliesOnEachItem ?? true,
        appliesOncePerCustomer: values.appliesOncePerCustomer,
        usageLimit: values.useUsageLimit ? values.usageLimit : null,
        customerEligibility: values.customerEligibility,
        ecommCustomerSegmentIds: values.selectedCustomerSegments.map(s => s.id) || [],
      },
    })
    onClose()
  }
  const isAffiliateCode = discountType === EcommDiscountCodeTypeEnum.Affiliate
  let integrationType: IntegrationType | undefined = undefined
  if (programEcommIntegration) {
    if (isTypeName(programEcommIntegration, 'CommerceCloudIntegration')) {
      integrationType = IntegrationType.CommerceCloud
    } else {
      integrationType = IntegrationType.Shopify
    }
  }
  return (
    <Dialog open={open} onClose={onClose} maxWidth={false}>
      <Formik<FormSchemaType>
        initialValues={initialValues}
        onSubmit={onSubmitForm}
        validationSchema={schema}
        enableReinitialize
      >
        {({ dirty, isValid }) => (
          <Form noValidate>
            <Box width={1200} maxHeight={720}>
              <IconButton onClick={onClose}>
                <CrossIcon width={16} height={16} />
              </IconButton>
              {ecommIntegrationLoaded ? (
                <>
                  <Box px={20} display="flex" maxHeight={620} overflow="auto">
                    <Box flex={0.9} mr={15}>
                      <Typography variant="h6">
                        {isAffiliateCode ? 'Code Discount Settings' : 'Link Discount Settings'}
                      </Typography>
                      <DiscountGenerationForm
                        campaignId={campaignId}
                        discountType={discountType}
                        integrationType={integrationType}
                      />
                    </Box>
                    <Box flex={1}>
                      <Typography variant="h6">Discount Defaults</Typography>
                      <Typography variant="caption" className={classes.caption}>
                        {isAffiliateCode
                          ? `An affiliate code with the below settings is automatically generated and sent in the welcome
                    communications when a member is approved for the program.`
                          : `An automatic discount with the below settings is automatically applied at checkout.`}
                      </Typography>
                      <Field
                        fullWidth
                        className={classes.input}
                        component={TextField}
                        label="Active date"
                        defaultValue={
                          isAffiliateCode
                            ? 'Code is activated as soon as member is approved.'
                            : 'Discount is active immediately.'
                        }
                        disabled
                      />
                      <Field
                        fullWidth
                        className={classes.input}
                        component={TextField}
                        label="Expire date"
                        minRows={isAffiliateCode ? 1 : 2}
                        multiline={!isAffiliateCode}
                        defaultValue={
                          isAffiliateCode
                            ? 'Code does not expire. Code is revoked if program membership is revoked.'
                            : 'Discount expires after the cart has been abandoned. (Shopify standard is 10 days)'
                        }
                        disabled
                      />
                      <Field
                        fullWidth
                        className={classes.input}
                        component={TextField}
                        label="Customer eligibility"
                        minRows={isAffiliateCode ? 1 : 2}
                        multiline={!isAffiliateCode}
                        defaultValue={
                          isAffiliateCode
                            ? 'Anyone can use this code at checkout.'
                            : 'Anyone who shops through an ambassador’s link gets this discount automatically applied to their cart.'
                        }
                        disabled
                      />
                      <Field
                        fullWidth
                        className={classes.input}
                        component={TextField}
                        label="Maximum discount uses"
                        defaultValue={'One use per order.'}
                        disabled
                      />
                      <Field
                        fullWidth
                        className={classes.input}
                        component={TextField}
                        label="Minimum purchase requirements"
                        defaultValue="No minimum requirements."
                        disabled
                      />
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="center" justifyContent="flex-end" mx={20} my={5}>
                    <Button color="primary" variant="contained" type="submit" disabled={!(dirty && isValid)}>
                      Done
                    </Button>
                  </Box>
                </>
              ) : (
                <AffiliateCodesSettingsDialogSkeleton />
              )}
            </Box>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

function AffiliateCodesSettingsDialogSkeleton() {
  return (
    <Box px={20} display="flex">
      <Box flex={0.9} mr={15}>
        <Skeleton height={160} />
        <Skeleton height={80} />
        <Skeleton height={80} />
        <Skeleton height={80} />
      </Box>
      <Box flex={1}>
        <Skeleton height={160} />
        <Skeleton height={80} />
        <Skeleton height={80} />
        <Skeleton height={80} />
        <Skeleton height={80} />
        <Skeleton height={80} />
      </Box>
    </Box>
  )
}

function getDiscountCodePurchaseType(ecommDiscountCode: EcommDiscountCodeFragment | null | undefined) {
  if (!ecommDiscountCode) return null

  if (ecommDiscountCode.appliesOnOneTimePurchase && ecommDiscountCode.appliesOnSubscription) {
    return EcommDiscountPurchaseTypeEnum.Both
  }

  if (ecommDiscountCode.appliesOnOneTimePurchase) {
    return EcommDiscountPurchaseTypeEnum.OneTimePurchase
  }

  if (ecommDiscountCode.appliesOnSubscription) {
    return EcommDiscountPurchaseTypeEnum.Subscription
  }

  return null
}

export default AffiliateCodesSettingsDialog

import React, { useEffect, useMemo, useState } from 'react'
import { Container, Paper, createStyles, makeStyles } from '@material-ui/core'
import ContainerEmptyState from '../../components/ContainerEmptyState/ContainerEmptyState'
import { ReactComponent as EmptyImage } from '../../images/empty-list.svg'
import ProgramActivityDialog from './ProgramActivityDialog'
import { useCampaignProgramActivitiesQuery } from './operations/campaign-program-activities.generated'
import { useParams } from 'react-router-dom'
import { CampaignDetailRouteParams } from '../routes'
import ProgramActivityTimeline, { ProgramTierGroup } from './ProgramActivityTimeline'
import { DateRangeFilter, DateRangeFilterType, DateRangeFilterUnits, DateTimeRangeFilter } from '../../gql-global'
import { realizedDateRangeFromFilter } from '../../utils/date-range-helper'
import { ProgramActivityBuilderProvider } from './reward-automation-builder/ProgramActivityBuilderProvider'
import { isEqual } from '../../utils/objects'
import { subDays, endOfMonth, isAfter } from 'date-fns'

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: `32px 16px`,
    },
  }),
)

function CampaignProgramActivity(): React.ReactElement {
  const classes = useStyles()

  const defaultDateRange: DateTimeRangeFilter = useMemo(
    () => ({
      __typename: 'RelativeDateRangeFilter',
      rangeType: DateRangeFilterType.Absolute,
      unit: DateRangeFilterUnits.Days,
      value: 30,
    }),
    [],
  )

  const { id } = useParams<CampaignDetailRouteParams>()
  const [activityDialogOpened, setActivityDialogOpened] = useState(false)
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeFilter | DateTimeRangeFilter>(defaultDateRange)
  const [selectedAutomation, setSelectedAutomation] = useState<ProgramTierGroup>()

  const dateRange = useMemo(
    () => selectedDateRange && realizedDateRangeFromFilter(selectedDateRange),
    [selectedDateRange],
  )

  const { data, loading } = useCampaignProgramActivitiesQuery({
    variables: { campaignId: id, where: dateRange ? { liveBetween: dateRange } : undefined },
  })

  const programId = data?.campaign?.program?.id ?? ''
  const programTierGroups = data?.campaign?.program?.programTierGroups ?? []

  useEffect(() => {
    const latestStartDate = data?.campaign?.program?.latestProgramActivityStartDate
    if (latestStartDate && isAfter(latestStartDate, dateRange?.lt) && isEqual(selectedDateRange, defaultDateRange)) {
      handleOnSetFutureStartDate(latestStartDate)
    }
  }, [data?.campaign?.program?.latestProgramActivityStartDate, dateRange?.lt, defaultDateRange, selectedDateRange])

  const handleOnActivityDialogOpen = (value?: ProgramTierGroup) => {
    setSelectedAutomation(value)
    setActivityDialogOpened(true)
  }

  const handleOnSetFutureStartDate = (untilDate: Date) => {
    setSelectedDateRange({
      __typename: 'AbsoluteDateTimeRangeFilter',
      rangeType: DateRangeFilterType.Absolute,
      gte: subDays(new Date(), 30),
      lt: endOfMonth(untilDate),
    })
  }

  return (
    <>
      {(programTierGroups.length > 0 || loading || !isEqual(selectedDateRange, defaultDateRange)) && (
        <ProgramActivityTimeline
          onActivityDialogOpen={handleOnActivityDialogOpen}
          programTierGroups={programTierGroups}
          isLoading={loading}
          selectedDateRange={selectedDateRange}
          onSelectedDateRange={setSelectedDateRange}
        />
      )}
      {programTierGroups.length === 0 && !loading && isEqual(selectedDateRange, defaultDateRange) && (
        <Paper>
          <Container className={classes.container}>
            <ContainerEmptyState
              image={EmptyImage}
              text="No activity found. Let's get started today!"
              callToActionText="Add New Activity"
              callToActionCallback={() => setActivityDialogOpened(true)}
            />
          </Container>
        </Paper>
      )}
      {activityDialogOpened && (
        <ProgramActivityBuilderProvider>
          <ProgramActivityDialog
            open
            programId={programId}
            selectedAutomation={selectedAutomation}
            onCancel={() => setActivityDialogOpened(false)}
            onSetFutureStartDate={handleOnSetFutureStartDate}
          />
        </ProgramActivityBuilderProvider>
      )}
    </>
  )
}

export default CampaignProgramActivity

import * as Types from '../../gql-global';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MessagesUserDataQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.RewardFilterInput>;
}>;


export type MessagesUserDataQuery = { __typename: 'Query', whoami?: { __typename: 'UserType', id: number, email: string, preferences: { __typename: 'UserPreferences', id: string, selectedSocialAccountId?: string | null }, account?: { __typename: 'AccountType', id: number, socialAccounts?: Array<{ __typename: 'IGSocialAccount', id: string } | { __typename: 'TTSocialAccount', id: string }> | null, rewards?: { __typename: 'PagedRewardType', results: Array<{ __typename: 'RewardType', id: number, name?: string | null, stats: { __typename: 'RewardStats', codesLevel?: Types.CodesLevel | null } }> } | null, organization: { __typename: 'Organization', id: string, activeProducts: Array<Types.AccountProductName> }, segments?: { __typename: 'PagedSegmentType', total: number, results: Array<{ __typename: 'SegmentType', id: number, name: string }> } | null } | null } | null };


export const MessagesUserDataDocument = gql`
    query MessagesUserData($where: RewardFilterInput) {
  whoami {
    id
    email
    preferences @client {
      id
      selectedSocialAccountId
    }
    account {
      id
      socialAccounts {
        id
      }
      rewards(where: $where) {
        results {
          id
          name
          stats {
            codesLevel
          }
        }
      }
      organization {
        id
        activeProducts
      }
      segments(limit: 200, sortBy: RECENTLY_USED) {
        total
        results {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useMessagesUserDataQuery__
 *
 * To run a query within a React component, call `useMessagesUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessagesUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagesUserDataQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useMessagesUserDataQuery(baseOptions?: Apollo.QueryHookOptions<MessagesUserDataQuery, MessagesUserDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessagesUserDataQuery, MessagesUserDataQueryVariables>(MessagesUserDataDocument, options);
      }
export function useMessagesUserDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessagesUserDataQuery, MessagesUserDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessagesUserDataQuery, MessagesUserDataQueryVariables>(MessagesUserDataDocument, options);
        }
export type MessagesUserDataQueryHookResult = ReturnType<typeof useMessagesUserDataQuery>;
export type MessagesUserDataLazyQueryHookResult = ReturnType<typeof useMessagesUserDataLazyQuery>;
export type MessagesUserDataQueryResult = Apollo.QueryResult<MessagesUserDataQuery, MessagesUserDataQueryVariables>;
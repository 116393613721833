import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { NumberParam, useQueryParam } from 'use-query-params'
import { CampaignDetailRouteParams } from '../routes'
import CustomerTable from '../../components/CustomerTable/CustomerTable'
import useSelectionState from '../../hooks/useSelectionState'
import {
  AmbassadorStatsInput,
  CommissionTierType,
  CustomerExportFieldEnum,
  CustomerMentionStatsFilters,
  EcommWebEventType,
  IgMediaPostType,
  PagedParticipants,
  ParticipantsInput,
  ParticipantSort,
  ParticipantStatus,
  PaymentPeriod,
  PaymentPeriodEnum,
  PaymentStatusEnum,
  ProgramTypeEnum,
  SocialPlatformEnum,
  SortDirection,
} from '../../gql-global'
import { useCampaignMembersQuery } from './operations/members.generated'
import { useMessageTemplatesListFilterQuery } from '../../customer/operations/query-message-templates-list-filter.generated'
import ContainerError from '../../components/ContainerError'
import ListHeader from '../../components/lists/ListHeader'
import ListActions from '../../components/lists/ListActions'
import ListActionMenu, { ListActionConfig } from '../../components/lists/ListActionMenu'
import ListCount from '../../components/lists/ListCount'
import { ReactComponent as PlusIcon } from '../../icons/plus_minor.svg'
import { ReactComponent as Export } from '../../icons/export.svg'
import { ReactComponent as OutlineCrossIcon } from '../../icons/outline-cross.svg'
import { ReactComponent as PresentIcon } from '../../icons/present.svg'
import { ReactComponent as TrophyIcon } from '../../icons/gradient-trophy.svg'
import { ReactComponent as DollarSignIcon } from '../../icons/dollar-sign.svg'

import { isApolloError, NetworkStatus } from '@apollo/client'
import ListFilters from '../../components/lists/ListFilters'
import useMemberParams, { Filters } from './use-filter-params-members'
import {
  DateRange,
  getPaymentPeriodEndDate,
  invalidSinceRealizedDateRangeFromFilter,
  realizedDateRangeFromFilter,
} from '../../utils/date-range-helper'
import { useDateRangeRef, useDateRangesRef } from '../../hooks/useDateRangeRef'
import { isNonNull, isTypeName } from '../../types/utility'
import useSortParam from './use-sort-param'
import LabelMenu from '../../components/LabelMenu'
import useBulkActionState from './use-bulk-action-state'
import {
  BULK_REJECT_PARTICIPANT_LIMIT,
  BULK_SEGMENT_ADD_LIMIT,
  EXPORT_LIMIT,
  MESSAGE_TEMPLATES_LIMIT,
  PAGE_SIZE,
  SEND_REWARD_LIMIT,
  SET_COMMISSION_TIER_LIMIT,
  tagStatusOptions,
} from './constants'
import { useRemoveParticipantMutation } from './operations/remove-participant.generated'
import {
  AddParticipantSegmentMutationVariables,
  useAddParticipantSegmentMutation,
} from './operations/add-segment.generated'
import {
  CreateParticipantSegmentMutationVariables,
  useCreateParticipantSegmentMutation,
} from './operations/create-segment.generated'
import labelsCacheUpdate from '../../mutations/labels-cache-update'
import SendRewardDialog from '../../components/SendRewardDialog'
import { useExportCampaignMembersLazyQuery } from './operations/export-campaign-members.generated'
import AddMemberDialog, { AddMemberFormFields } from './AddMemberDialog'
import { useCreateParticipantMutation } from './operations/create-participant.generated'
import {
  MembersSendRewardMutationVariables,
  useMembersSendRewardMutation,
} from './operations/members-send-reward.generated'
import ActionDialogs from './ActionsDialogs'
import {
  DateRangeOption,
  KeywordsOption,
  ParentOption,
  Options,
  SelectionOption,
  LabelsOption,
} from '../../components/lists/ListFilters/types'
import useSortDirParam from '../../customer/use-sort-dir-param'
import {
  challengeApprovalStatusOptions,
  igMentionTypeOptions,
  mediaTypeOptions,
  messageSendingMethodOptions,
  postTypeOptions,
  ttMentionTypeOptions,
} from '../../content/constants'
import { useCampaignMembersCampaignQuery } from './operations/campaign-members-campaign.generated'
import useHasFeature from '../../hooks/useHasFeature'
import { makeMentionTypesWhere } from '../../dashboard/utils'
import { cleanAllAnyNoneFilter, useDateInclusionExclusionWhereFilter } from '../../utils/filter-params'
import { sendRewardAndHandleResult } from '../../utils/rewards'
import { useToast } from '../../components/Alert/ToastProvider'
import CampaignMemberSavedFilterBar from './CampaignMemberSavedFilterBar'
import { useCampaignUserInfoQuery } from './operations/campaign-user-info.generated'
import {
  UpdateParticipantsStatusMutationVariables,
  useUpdateParticipantsStatusMutation,
} from './operations/update-participants-status.generated'
import CampaignPickWinnerDialog from './CampaignPickWinnerDialog'
import { selectedSocialPlatform } from '../../utils/social-account'
import { createCustomFieldFilters } from '../../utils/custom-field-filters'
import { useCampaignShoppableMembersQuery } from './operations/campaign-shoppable-members.generated'
import { LABEL_MANAGEMENT_ROUTE, SEGMENT_MANAGEMENT_ROUTE } from '../../settings/routes'
import { paymentPeriodsDateFormat } from '../../utils/date-format'
import { subDays, startOfMonth } from 'date-fns'
import {
  SetParticipantCommissionTierMutationVariables,
  useSetParticipantCommissionTierMutation,
} from './operations/set-participant-commission-tier.generated'
import UpsertCommissionTierModal from './campaign-settings/UpsertCommissionTierModal'
import { useUpsertCommissionTierMutation } from './campaign-settings/operations/upsert-commission-tier.generated'

function useWhereFilters(filters: Filters, isInstagramAccount: boolean, hasTikTokHashtags: boolean): ParticipantsInput {
  const currentMemberSinceDateRange = filters.approvedAt ? realizedDateRangeFromFilter(filters.approvedAt) : null
  const reffedCurrentMemberSinceDateRange = useDateRangeRef(currentMemberSinceDateRange)
  const currentMaxRewardedDateRange = filters.maxRewardedAt ? realizedDateRangeFromFilter(filters.maxRewardedAt) : null
  const reffedCurrentMaxRewardedDateRange = useDateRangeRef(currentMaxRewardedDateRange)

  const currentMinPostedAtDateRange = filters.minPostedAt ? realizedDateRangeFromFilter(filters.minPostedAt) : null

  const currentMaxPostedAtDateRange = filters.maxPostedAt
    ? invalidSinceRealizedDateRangeFromFilter(filters.maxPostedAt)
    : null
  const reffedMaxPostedAtDateRange = useDateRangeRef(currentMaxPostedAtDateRange)

  const customFieldsAllDates =
    filters.customFields?.all?.map(accf => (accf.dateValue ? realizedDateRangeFromFilter(accf.dateValue) : null)) || []
  const customFieldsAllDateRefs = useDateRangesRef(customFieldsAllDates)
  const customFieldsAll = filters.customFields?.all?.map((f, i) => ({ ...f, dateValue: customFieldsAllDateRefs[i] }))

  const customFieldsAnyDates =
    filters.customFields?.any?.map(accf => (accf.dateValue ? realizedDateRangeFromFilter(accf.dateValue) : null)) || []
  const customFieldsAnyDateRefs = useDateRangesRef(customFieldsAnyDates)
  const customFieldsAny = filters.customFields?.any?.map((f, i) => ({ ...f, dateValue: customFieldsAnyDateRefs[i] }))
  const messageDate = useDateInclusionExclusionWhereFilter(filters.messageDate)

  const currentNoteCreatedAtDateRange = filters.noteCreatedAt
    ? realizedDateRangeFromFilter(filters.noteCreatedAt)
    : null
  const reffedNoteCreatedAtDateRange = useDateRangeRef(currentNoteCreatedAtDateRange)

  return {
    status: { any: [ParticipantStatus.Approved] },
    approvedAt: reffedCurrentMemberSinceDateRange ?? undefined,
    maxRewardedAt: reffedCurrentMaxRewardedDateRange ?? undefined,
    minPostedAt: currentMinPostedAtDateRange ?? undefined,
    maxPostedAt: reffedMaxPostedAtDateRange ?? undefined,
    followerCount: filters.followerCount,
    postCount: filters.postCount,
    avgEngagementRate: filters.avgEngagementRate,
    impressions: filters.impressions,
    rewards: filters.rewards ? { any: Array.from(filters.rewards) } : undefined,
    username: filters.usernameKeywords?.length ? { keywords: filters.usernameKeywords } : undefined,
    email: filters.emailKeywords?.length ? { keywords: filters.emailKeywords } : undefined,
    biography: filters.biographyKeywords?.length ? { all: filters.biographyKeywords.map(k => [k]) } : undefined,
    notes: filters.notesKeywords?.length ? { keywords: filters.notesKeywords } : undefined,
    noteCategories: filters.noteCategories ? { any: Array.from(filters.noteCategories) } : undefined,
    noteCreatedAt: reffedNoteCreatedAtDateRange ?? undefined,
    segments: filters.segments,
    messageTemplate: filters.messageTemplate,
    messageKeywords: filters.messageKeywords,
    ...makeMentionTypesWhere(filters.mentionType, isInstagramAccount, hasTikTokHashtags),
    mediaType: filters.mediaType?.size ? { any: Array.from(filters.mediaType) } : undefined,
    postType: filters.postType?.size ? { any: Array.from(filters.postType) } : undefined,
    customFields:
      customFieldsAll || customFieldsAny
        ? {
            all: customFieldsAll || undefined,
            any: customFieldsAny || undefined,
          }
        : undefined,
    challengeIds:
      filters.challengeIds && filters.challengeIds.any
        ? {
            any: filters.challengeIds.any || undefined,
          }
        : undefined,
    challengeMediaApproval: filters.challengeMediaApproval
      ? {
          any: Array.from(filters.challengeMediaApproval),
        }
      : undefined,
    labels:
      filters.labels?.all || filters.labels?.none
        ? {
            all: filters.labels.all || undefined,
            none: filters.labels.none || undefined,
          }
        : undefined,
    messageDate,
    messageSendingMethod: cleanAllAnyNoneFilter(filters.messageSendingMethod),
    referredOrders: filters.referredOrders,
    referredSalesRevenue: filters.referredSalesRevenue,
    commissionsEarned: filters.commissionsEarned,
    linkViews: filters.linkViews,
    conversionRate: filters.conversionRate,
    commissionTiers: filters.commissionTiers,
    commissionRate: filters.commissionRate,
  }
}

function useMentionsWhereFilters(
  filters: Filters,
  campaignId: string,
  isInstagramAccount: boolean,
  hasTikTokHashtags: boolean,
): CustomerMentionStatsFilters {
  // if postedAt unset we use dateRange's value, though if there is a value it will override dateRange
  const postedAt = filters.postedAt ?? filters.dateRange
  const currentPostedDateRange = postedAt ? realizedDateRangeFromFilter(postedAt) : null
  const reffedCurrentPostedDateRange = useDateRangeRef(currentPostedDateRange)
  return {
    campaignId: {
      any: [campaignId],
    },
    postedAt: reffedCurrentPostedDateRange ?? undefined,
    ...makeMentionTypesWhere(filters.mentionType, isInstagramAccount, hasTikTokHashtags),
    tagStatus: filters.tagStatus?.size ? { any: Array.from(filters.tagStatus) } : undefined,
    challengeMediaApproval: filters.challengeMediaApproval?.size
      ? { any: Array.from(filters.challengeMediaApproval) }
      : undefined,
    mediaType: filters.mediaType?.size ? { any: Array.from(filters.mediaType) } : undefined,
    postType: filters.postType?.size ? { any: Array.from(filters.postType) } : undefined,
    challengeIds: filters.challengeIds ? { any: filters.challengeIds.any } : undefined,
    labels:
      filters.labels?.all || filters.labels?.none
        ? {
            all: filters.labels.all || undefined,
            none: filters.labels.none || undefined,
          }
        : undefined,
  }
}

function useAmbassadorStatsWhereFilters(filters: Filters): AmbassadorStatsInput {
  const postedAt = filters.postedAt ?? filters.dateRange
  const currentPostedDateRange = postedAt ? realizedDateRangeFromFilter(postedAt) : null
  const reffedCurrentPostedDateRange = useDateRangeRef(currentPostedDateRange)
  return {
    rangeAt: reffedCurrentPostedDateRange ? [reffedCurrentPostedDateRange] : undefined,
  }
}

interface CampaignMembersProps {
  programType?: ProgramTypeEnum
}

function CampaignMembers({ programType = ProgramTypeEnum.AllCustomer }: CampaignMembersProps): React.ReactElement {
  const { id } = useParams<CampaignDetailRouteParams>()
  const { data: userData } = useCampaignUserInfoQuery()
  const [selectionState, selectionDispatch] = useSelectionState()
  const [sort = ParticipantSort.ApprovedAt, setSort] = useSortParam()
  const [bulkState, dispatch] = useBulkActionState()
  const { showToast } = useToast()
  const [addMemberOpen, setAddMemberOpen] = useState(false)
  const [sortDir = SortDirection.Desc, setSortDir] = useSortDirParam()
  const [saveFilterDialogOpen, setSaveFilterDialogOpen] = useState(false)
  const { data: campaignData, loading: campaignLoading } = useCampaignMembersCampaignQuery({
    variables: {
      campaignId: id,
      challengesLimit: 200,
    },
  })

  const { hasFeature: hasTikTokHashtags } = useHasFeature('tiktokHashtags')

  const isShoppable = programType === ProgramTypeEnum.ShoppableAmbassador
  const customerTableEntity = isShoppable ? 'shoppableParticipant' : 'participant'

  const campaign = campaignData?.campaign

  // only used on page load, set when we get data
  const [viewing = PAGE_SIZE, setViewing] = useQueryParam('viewing', NumberParam)
  const limitRef = useRef(viewing)
  const { filters, setFilters, isDirty } = useMemberParams()
  const currentDateRange = filters.dateRange ? realizedDateRangeFromFilter(filters.dateRange) : null
  const reffedCurrentDateRange = useDateRangeRef(currentDateRange)

  const selectedSocialAccountId = userData?.whoami?.preferences?.selectedSocialAccountId

  const socialPlatform = selectedSocialPlatform(userData)
  const isIGSocialAccount = socialPlatform === SocialPlatformEnum.Instagram
  const isTTSocialAccount = socialPlatform === SocialPlatformEnum.Tiktok

  const program = campaign?.program
  const paymentPeriodInterval = program?.programPayoutSettings?.period || PaymentPeriodEnum.Monthly
  const maturationPeriodDays = program?.programPayoutSettings?.orderMaturationPeriodDays || 0

  const periodPaymentDate = getPaymentPeriodEndDate(paymentPeriodInterval, 0)
  const periodEndDate = getPaymentPeriodEndDate(paymentPeriodInterval, maturationPeriodDays)
  const periodStartDate = subDays(startOfMonth(new Date()), maturationPeriodDays)
  periodStartDate.setUTCHours(0, 0, 0, 0)

  const paymentPeriods = [
    {
      id: 'current',
      name: paymentPeriodsDateFormat(periodPaymentDate, 'long'),
      value: { endAt: periodEndDate, startAt: periodStartDate, paymentAt: periodPaymentDate },
    },
    ...(campaignData?.campaign?.paymentPeriods?.map(pp => ({
      id: pp.id,
      name: paymentPeriodsDateFormat(pp.paymentAt, 'long'),
      value: pp,
    })) ?? []),
  ]

  const selectedPaymentPeriodIds = filters.paymentPeriods?.any
  const selectedPaymentPeriods = paymentPeriods?.filter(pp => selectedPaymentPeriodIds?.includes(pp.id.toString()))

  const selectedPaymentPeriodsRangeAt: DateRange[] | undefined = selectedPaymentPeriods
    ? selectedPaymentPeriods.map(pp => ({ gte: pp.value.startAt, lt: pp.value.endAt }))
    : undefined

  const combinedRangeAt =
    selectedPaymentPeriodsRangeAt && reffedCurrentDateRange
      ? [...selectedPaymentPeriodsRangeAt, reffedCurrentDateRange]
      : selectedPaymentPeriodsRangeAt ?? (reffedCurrentDateRange ? [reffedCurrentDateRange] : undefined)

  const filterAwaitingApprovalStatus =
    filters.orderStatus?.size === 1
      ? // if it isn't 'awaiting_approval' it can only be 'approved', and false will filter awaiting orders away, so only approved remain
        Array.from(filters.orderStatus)[0] === 'awaiting_approval'
      : undefined
  const filterCommissionPaymentStatuses = filters.commissionsPaymentStatus?.size
    ? (Array.from(filters.commissionsPaymentStatus) as PaymentStatusEnum[])
    : undefined

  const where: ParticipantsInput = {
    ...useWhereFilters(filters, isIGSocialAccount, hasTikTokHashtags),
    platform: socialPlatform,
    // filter to only customers with orders if these filters exists for backwards compatibility
    hasOrders: filterAwaitingApprovalStatus !== undefined || filterCommissionPaymentStatuses !== undefined,
  }

  const mentionsWhere = {
    ...useMentionsWhereFilters(filters, id, isIGSocialAccount, hasTikTokHashtags),
    socialAccountId: selectedSocialAccountId ? { any: [selectedSocialAccountId] } : undefined,
  }

  const ambassadorStatsWhere = useAmbassadorStatsWhereFilters(filters)

  const activeStoryMentionsWhere = {
    postType: { any: [IgMediaPostType.Story] },
    expiredStories: false,
    campaignId: {
      any: [id],
    },
    socialAccountId: selectedSocialAccountId ? { any: [selectedSocialAccountId] } : undefined,
  }

  const nextPaymentDate = campaignData?.campaign?.nextPaymentDate

  const {
    data: membersData,
    loading: membersLoading,
    error: membersError,
    fetchMore: membersFetchMore,
    networkStatus: membersNetworkStatus,
    refetch: membersRefetch,
  } = useCampaignMembersQuery({
    skip:
      !selectedSocialAccountId ||
      !socialPlatform ||
      programType === ProgramTypeEnum.ShoppableAmbassador ||
      !programType,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      campaignId: id,
      where,
      mentionsWhere,
      activeStoryMentionsWhere,
      limit: limitRef.current,
      sortBy: sort,
      sortDirection: sortDir,
      pendingWhere: {
        platform: socialPlatform,
      },
    },
  })

  const programCurrencyCode = campaign?.program?.currencyCode
  const programId = campaign?.program?.id

  const programIds = programId ? { any: [programId] } : undefined

  const participantsAmbassadorStatsWhere: AmbassadorStatsInput = {
    currencyCode: programCurrencyCode!,
    programIds: programIds,
    paymentStatusesFilter: filterCommissionPaymentStatuses,
    rangeAt: combinedRangeAt,
    ordersAwaitingApproval: filterAwaitingApprovalStatus,
    disjointRefunds: false,
    eventTypes: [EcommWebEventType.Landed, EcommWebEventType.DiscountRedeemed],
  }

  const {
    data: shoppableData,
    loading: shoppableLoading,
    error: shoppableError,
    fetchMore: shoppableFetchMore,
    networkStatus: shoppableNetworkStatus,
    refetch: shoppableRefetch,
  } = useCampaignShoppableMembersQuery({
    skip:
      !selectedSocialAccountId ||
      !socialPlatform ||
      programType !== ProgramTypeEnum.ShoppableAmbassador ||
      !programType ||
      !programCurrencyCode,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      campaignId: id,
      where,
      mentionsWhere,
      participantsAmbassadorStatsWhere: participantsAmbassadorStatsWhere,
      activeStoryMentionsWhere,
      limit: limitRef.current,
      sortBy: sort,
      sortDirection: sortDir,
    },
  })

  const data = isShoppable ? shoppableData : membersData
  const loading = !campaignLoading ? (isShoppable ? shoppableLoading : membersLoading) : true
  const error = isShoppable ? shoppableError : membersError
  const fetchMore = isShoppable ? shoppableFetchMore : membersFetchMore
  const networkStatus = isShoppable ? shoppableNetworkStatus : membersNetworkStatus
  const refetch = isShoppable ? shoppableRefetch : membersRefetch

  const challenges = (campaignData?.campaign?.program?.challenges?.results || []).map(challenge => {
    return { id: challenge.id, name: challenge.name }
  })

  const [createParticipant] = useCreateParticipantMutation({
    onCompleted: () => refetch(),
  })
  const commissionTierSavingError = () => {
    showToast({
      title: 'Error: Saving Commission Tier',
      message: 'Something went wrong while saving the Commission Tier, please try again.',
      severity: 'error',
    })
    dispatch({ type: 'done' })
  }
  const [upsertCommissionTier] = useUpsertCommissionTierMutation({
    onError: () => {
      commissionTierSavingError()
    },
  })
  const [setParticipantCommissionTierMutation] = useSetParticipantCommissionTierMutation({
    refetchQueries: ['CustomerActivities'],
    onCompleted: () => {
      refetch()
      selectionDispatch({ type: 'reset' })
    },
  })
  const [addParticipantSegment] = useAddParticipantSegmentMutation({
    update: labelsCacheUpdate,
  })
  const [createParticipantSegment] = useCreateParticipantSegmentMutation({
    update: labelsCacheUpdate,
  })

  const [removeParticipant] = useRemoveParticipantMutation({
    update(cache, { data }) {
      if (!data?.removeParticipant?.ok || !data?.removeParticipant?.participant) {
        return
      }
      const cacheId = cache.identify(data.removeParticipant.participant)
      cache.evict({ id: cacheId })
      cache.gc()
    },
    onCompleted: () => refetch(),
  })
  const [sendReward] = useMembersSendRewardMutation()

  const [updateStatus] = useUpdateParticipantsStatusMutation({
    update(cache, { data: updateData }) {
      const changed = updateData?.updateParticipantStatus?.participants
      if (data?.campaign && changed) {
        const cacheKey = cache.identify(data.campaign)
        cache.modify({
          id: cacheKey,
          fields: {
            participants(value: PagedParticipants, { storeFieldName, isReference, readField, DELETE }) {
              const ids = changed.map(p => p.id)
              if (
                storeFieldName.includes(ParticipantStatus.Pending) &&
                !storeFieldName.includes(ParticipantStatus.Approved)
              ) {
                const results = value.results.filter(p => {
                  const id: number | undefined = isReference(p) ? readField('id', p) : p.id
                  if (!id) return false
                  return !ids.includes(id)
                })
                return {
                  ...value,
                  total: value.total - ids.length,
                  results,
                }
              }
              return DELETE
            },
          },
        })
        for (const field of ['mentionStats', 'mentions', 'participantStats', 'participantStatsTimeseries']) {
          cache.evict({
            id: cacheKey,
            fieldName: field,
          })
        }
        cache.gc()
      }
    },
    onCompleted: () => {
      refetch()
      selectionDispatch({ type: 'reset' })
    },
  })

  const exportUrlRef = useRef<string | null>(null)
  const [exportMembers, exportResults] = useExportCampaignMembersLazyQuery()
  const exportUrl = exportResults.data?.campaign?.participants?.csvUrl
  useEffect(() => {
    if (exportUrl && exportUrl !== exportUrlRef.current) {
      exportUrlRef.current = exportUrl
      window.location.href = exportUrl
    }
  }, [exportUrl])

  const saveCommissionTier = async (tierName: string, commissionPercentage: number, participantId?: number) => {
    createCommissionTierAndSet(tierName, commissionPercentage, participantId)
  }

  const accountSegments = campaignData?.whoami?.account?.segments?.results || []
  const accountId = campaignData?.whoami?.account?.id
  const loadingMore = networkStatus === NetworkStatus.fetchMore
  const loadingInitial = loading && !loadingMore
  const totalMembers = data?.campaign?.participants?.total || 0
  const shownMemberCount = data?.campaign?.participants?.results.length
  const newViewingCount = shownMemberCount || limitRef.current
  const campaignRewards = campaign?.program?.tiers?.map(t => t?.reward).filter(isNonNull) || []
  const hasSocialAccount = isIGSocialAccount || isTTSocialAccount
  // update viewing url param based on what we got from the api
  useEffect(() => setViewing((Math.ceil(newViewingCount / PAGE_SIZE) || 1) * PAGE_SIZE), [newViewingCount, setViewing])
  const organization = campaignData?.whoami?.account?.organization
  const hasHitSegmentLimit = !!organization && organization.segmentLimit.hasHitLimit

  const { cursor } = data?.campaign?.participants || {}
  function handleLoadMore(): void {
    if (cursor) {
      void fetchMore({
        variables: {
          cursor,
          limit: PAGE_SIZE,
        },
      })
    }
  }

  const noteCategoryOptions =
    campaignData?.noteCategories && !campaignLoading
      ? campaignData.noteCategories.map(c => {
          return { id: c.id, label: c.name }
        })
      : []

  function handleFiltersChanged(newFilters: Filters): void {
    limitRef.current = PAGE_SIZE
    selectionDispatch({ type: 'reset' })
    setFilters(newFilters)
  }

  function addSegment(segmentId: number, participantId?: number): void {
    let affectedIgUsersCount = 0

    let variables: AddParticipantSegmentMutationVariables = {
      sortDirection: sortDir,
      sortBy: sort,
      segmentId: segmentId.toString(),
    }
    if (participantId) {
      variables = {
        ...variables,
        participantIds: [participantId.toString()],
      }
      affectedIgUsersCount = 1
    } else if (!selectionState) {
      return
    } else if (selectionState === 'ALL') {
      affectedIgUsersCount =
        totalMembers && totalMembers < BULK_SEGMENT_ADD_LIMIT ? totalMembers : BULK_SEGMENT_ADD_LIMIT
      variables = { ...variables, campaignId: id || '', where: where, mentionsWhere: mentionsWhere }
    } else {
      const participantIds = Array.from(selectionState)
        .slice(0, BULK_SEGMENT_ADD_LIMIT)
        .map(id => id.toString())
      variables = { ...variables, participantIds }
      affectedIgUsersCount = participantIds.length
    }

    variables.ambassadorStatsWhere = programType === ProgramTypeEnum.ShoppableAmbassador ? ambassadorStatsWhere : null

    addParticipantSegment({
      variables: variables,
    })
      .then(() => {
        const segmentName = accountSegments.find(s => s.id === segmentId)?.name
        showToast({
          title: 'Success: Applying Segment',
          message: `Segment '${segmentName}' was applied to ${affectedIgUsersCount || ''} member${
            affectedIgUsersCount === 1 ? '' : 's'
          }.`,
          severity: 'success',
          autoHideDuration: 5000,
        })
      })
      .catch(() => {
        showToast({
          title: 'Error: Adding Segment',
          message: 'Something went wrong when adding this segment to the member(s), please try again.',
        })
      })
  }

  function createSegment(name: string, participantId?: number): void {
    let affectedIgUsersCount = 0

    let variables: CreateParticipantSegmentMutationVariables = {
      sortDirection: sortDir,
      sortBy: sort,
      name: name,
      accountId: accountId?.toString() || '',
    }
    if (participantId) {
      variables = {
        ...variables,
        participantIds: [participantId.toString()],
      }
      affectedIgUsersCount = 1
    } else if (!selectionState) {
      return
    } else if (selectionState === 'ALL') {
      affectedIgUsersCount =
        totalMembers && totalMembers < BULK_SEGMENT_ADD_LIMIT ? totalMembers : BULK_SEGMENT_ADD_LIMIT
      variables = { ...variables, campaignId: id || '', where: where, mentionsWhere: mentionsWhere }
    } else {
      const participantIds = Array.from(selectionState)
        .slice(0, BULK_SEGMENT_ADD_LIMIT)
        .map(id => id.toString())
      variables = { ...variables, participantIds }
      affectedIgUsersCount = participantIds.length
    }

    variables.ambassadorStatsWhere = programType === ProgramTypeEnum.ShoppableAmbassador ? ambassadorStatsWhere : null

    createParticipantSegment({
      variables: variables,
    })
      .then(() => {
        showToast({
          title: 'Success: Applying Segment',
          message: `Segment '${name}' was applied to ${affectedIgUsersCount || ''} member${
            affectedIgUsersCount === 1 ? '' : 's'
          }.`,
          severity: 'success',
          autoHideDuration: 5000,
        })
      })
      .catch(() => {
        showToast({
          title: 'Error: Adding Segment',
          message: 'Something went wrong when adding this segment to the member(s), please try again.',
        })
      })
  }

  function setParticipantCommissionTier(commissionTierId: string, participantId?: number, tierName?: string): void {
    let variables: SetParticipantCommissionTierMutationVariables = {
      sortDirection: sortDir,
      sortBy: sort,
      commissionTierId: commissionTierId,
    }
    if (participantId) {
      variables = {
        ...variables,
        participantIds: [participantId.toString()],
      }
    } else if (!selectionState) {
      return
    } else if (selectionState === 'ALL') {
      variables = { ...variables, where: where, mentionsWhere: mentionsWhere }
    } else {
      const participantIds = Array.from(selectionState)
        .slice(0, SET_COMMISSION_TIER_LIMIT)
        .map(id => id.toString())
      variables = { ...variables, participantIds }
    }

    setParticipantCommissionTierMutation({
      variables: variables,
    })
      .then(result => {
        const affectedRows = result.data?.setParticipantCommissionTier?.affectedRows ?? 0
        const commissionTierName =
          tierName ?? shoppableData?.campaign?.program?.commissionTiers?.find(ct => ct.id === commissionTierId)?.name
        showToast({
          title: 'Success: Setting Commission Tier',
          message: `Commission Tier '${commissionTierName}' was applied to ${affectedRows || ''} member${
            affectedRows === 1 ? '' : 's'
          }.`,
          severity: 'success',
          autoHideDuration: 5000,
        })
        dispatch({ type: 'done' })
      })
      .catch(() => {
        showToast({
          title: 'Error: Setting Commission Tier',
          message: 'Something went wrong when setting the commission tier to the member(s), please try again.',
        })
        dispatch({ type: 'done' })
      })
  }

  async function createCommissionTierAndSet(tierName: string, commissionPercentage: number, participantId?: number) {
    const result = await upsertCommissionTier({
      variables: {
        programId: shoppableData?.campaign?.program?.id!,
        data: {
          name: tierName,
          ratePercentage: commissionPercentage,
        },
      },
    })
    const tier_id = result.data?.upsertCommissionTier?.commissionTier?.id
    if (!tier_id) {
      commissionTierSavingError()
    } else {
      setParticipantCommissionTier(tier_id, participantId, tierName)
    }
  }

  function exportList(): void {
    if (!selectionState) {
      return
    }
    let exportWhere: ParticipantsInput
    let limit = EXPORT_LIMIT
    if (selectionState === 'ALL') {
      exportWhere = where
    } else {
      exportWhere = {
        participantIds: {
          any: Array.from(selectionState).map(s => s.toString()),
        },
      }
      limit = selectionState.size
    }

    const shoppableFields = [
      CustomerExportFieldEnum.TotalOrders,
      CustomerExportFieldEnum.Sales,
      CustomerExportFieldEnum.Revenue,
      CustomerExportFieldEnum.Landings,
      CustomerExportFieldEnum.Conversion,
      CustomerExportFieldEnum.PendingCommissions,
      CustomerExportFieldEnum.PaidCommissions,
      CustomerExportFieldEnum.OwedCommissions,
    ]

    const fields = [
      isTTSocialAccount ? CustomerExportFieldEnum.TtUsername : CustomerExportFieldEnum.IgUsername,
      CustomerExportFieldEnum.Email,
      isTTSocialAccount ? CustomerExportFieldEnum.TtAvatar : CustomerExportFieldEnum.IgAvatar,
      CustomerExportFieldEnum.MemberSince,
      CustomerExportFieldEnum.PostCount,
      isTTSocialAccount ? CustomerExportFieldEnum.TtFollowers : CustomerExportFieldEnum.IgFollowers,
      CustomerExportFieldEnum.AvgEngagementRate,
      CustomerExportFieldEnum.Impressions,
      isTTSocialAccount ? CustomerExportFieldEnum.IgUsername : CustomerExportFieldEnum.TtUsername,
      CustomerExportFieldEnum.Address,
      CustomerExportFieldEnum.Address_2,
      CustomerExportFieldEnum.City,
      CustomerExportFieldEnum.State,
      CustomerExportFieldEnum.Country,
      CustomerExportFieldEnum.Zip,
      CustomerExportFieldEnum.FirstName,
      CustomerExportFieldEnum.LastName,
      CustomerExportFieldEnum.Phone,
      CustomerExportFieldEnum.DiscountCodes,
      CustomerExportFieldEnum.StorefrontUrl,
      CustomerExportFieldEnum.ExternalId,
      ...(isShoppable ? shoppableFields : []),
    ]

    exportMembers({
      variables: {
        campaignId: id,
        where: exportWhere,
        mentionsWhere,
        ambassadorsWhere: participantsAmbassadorStatsWhere,
        limit: limit,
        sortBy: sort,
        sortDirection: sortDir,
        fields,
      },
    })
  }

  async function removeList(): Promise<void> {
    let variables: Partial<UpdateParticipantsStatusMutationVariables> = {}
    if (selectionState === 'ALL') {
      variables = {
        campaignId: id,
        limit: Math.min(BULK_REJECT_PARTICIPANT_LIMIT, totalMembers),
        where,
        mentionsWhere,
        sortDirection: sortDir,
        sortBy: sort,
      }
    } else if (selectionState) {
      variables.participantIds = Array.from(selectionState).map(i => i.toString())
    }
    try {
      const result = await updateStatus({
        variables: {
          ...variables,
          status: ParticipantStatus.Rejected,
        },
      })
      const participants = result.data?.updateParticipantStatus?.participants
      showToast({
        title: 'Success: Removing Members',
        message: `Rejected ${participants?.length.toLocaleString() ?? ''} members.`,
        severity: 'success',
        autoHideDuration: 5000,
      })
    } catch {
      showToast({
        title: 'Error: Removing Members',
        message: 'Something went wrong when removing these members, please try again.',
      })
    }
  }

  function handleConfirmBulkAction(): void {
    if (bulkState?.step !== 'confirmation') {
      return
    }
    switch (bulkState.action) {
      case 'export':
        exportList()
        break
      case 'addSegment':
        addSegment(bulkState.id, bulkState.participant?.id)
        break
      case 'createSegment':
        createSegment(bulkState.name, bulkState.participant?.id)
        break
      case 'gift':
        void handleSendReward(bulkState.reward, bulkState.offline, bulkState.participant?.id)
        break
      case 'rewardChallenge':
        void handleSendChallengeFulfillment(
          bulkState.reward,
          bulkState.offline,
          bulkState.challengeId,
          bulkState.participant?.id,
        )
        break
      case 'removeBulk':
        removeList()
        break
      case 'remove':
        const id = bulkState.participant.id
        const username = bulkState.participant.customer?.igUser?.username
        removeParticipant({
          variables: {
            participantId: id.toString(),
          },
        }).then(() =>
          showToast({
            title: 'Success: Removed Member',
            message: `Removed member ${username}`,
            severity: 'success',
            autoHideDuration: 5000,
          }),
        )
        break
    }
    dispatch({ type: 'done' })
  }

  function handleAddMember(member: AddMemberFormFields): void {
    setAddMemberOpen(false)
    createParticipant({
      variables: {
        campaignId: id,
        participant: member,
      },
    })
      .then(result =>
        showToast({
          title: 'Success: Added Member',
          message: `Added member ${result.data?.createParticipant?.participant?.customer?.igUser?.username || ''}`,
          severity: 'success',
          autoHideDuration: 5000,
        }),
      )
      .catch(error => {
        let message = 'Something went wrong when adding a member, please try again.'
        if (isApolloError(error) && error.graphQLErrors.some(e => e.extensions?.code === 'DUPLICATE_KEY')) {
          message = 'Member with that email or username already exists in the campaign.'
        }
        showToast({ title: 'Error: Adding Member', message })
      })
  }

  async function handleSendReward(
    reward: { id: number; name?: string | null },
    offline: boolean,
    participantId?: number,
  ): Promise<void> {
    const customerId = data?.campaign?.participants?.results.find(p => p.id === participantId)?.customer?.id
    if (offline && !customerId) {
      showToast({ title: 'Error: Sending Reward', message: 'Cannot copy reward to clipboard for multiple members' })
      return
    }
    const variables: MembersSendRewardMutationVariables = {
      rewardId: reward.id.toString(),
      offlineDelivery: offline && !!participantId,
      limit: Math.min(totalMembers, SEND_REWARD_LIMIT),
    }
    if (customerId) {
      variables.customerIds = [customerId.toString()]
    } else if (selectionState instanceof Set) {
      variables.customerIds = data?.campaign?.participants?.results
        .filter(p => selectionState.has(p.id))
        .map(p => p.customer?.id.toString())
        .filter((id: string | undefined): id is string => !!id)
    } else if (selectionState === 'ALL') {
      variables.campaignId = id
      variables.where = where
      variables.mentionsWhere = mentionsWhere
      variables.sortBy = sort
      variables.sortDirection = sortDir
      variables.ambassadorStatsWhere = programType === ProgramTypeEnum.ShoppableAmbassador ? ambassadorStatsWhere : null
    } else {
      return
    }
    await sendRewardAndHandleResult(reward, sendReward, variables, offline, showToast)
  }

  const showPickWinnerBulk = where.challengeIds?.any?.length === 1 && !!data?.campaign?.participants?.results?.length

  const singleChallengeFilterId = where.challengeIds?.any?.length === 1 ? where.challengeIds.any[0] || '' : ''

  type ActionOptionType = 'addSegment' | 'gift' | 'rewardChallenge' | 'setCommissionTier'
  type BulkActionOptionType = 'segment' | 'export' | 'gift' | 'rewardChallenge' | 'removeBulk' | 'setCommissionTier'

  async function handleSendChallengeFulfillment(
    reward: { id: number; name?: string | null },
    offline: boolean,
    challengeId: string,
    participantId?: number,
  ): Promise<void> {
    const customerId = data?.campaign?.participants?.results.find(p => p.id === participantId)?.customer?.id
    if (offline && !customerId) {
      showToast({ title: 'Error: Sending Reward', message: 'Cannot copy reward to clipboard for multiple members' })
      return
    }
    const variables: MembersSendRewardMutationVariables = {
      rewardId: reward.id.toString(),
      offlineDelivery: offline && !!participantId,
      limit: Math.min(totalMembers, SEND_REWARD_LIMIT),
      challengeId: challengeId,
    }
    if (customerId) {
      variables.customerIds = [customerId.toString()]
    } else if (selectionState instanceof Set) {
      variables.customerIds = data?.campaign?.participants?.results
        .filter(p => selectionState.has(p.id))
        .map(p => p.customer?.id.toString())
        .filter((id: string | undefined): id is string => !!id)
    } else if (selectionState === 'ALL') {
      variables.campaignId = id
      variables.where = where
      variables.mentionsWhere = mentionsWhere
      variables.sortBy = sort
      variables.sortDirection = sortDir
    } else {
      return
    }
    await sendRewardAndHandleResult(reward, sendReward, variables, offline, showToast)
  }

  const selectedLabelIds = new Set<number>()
  if (selectionState !== 'ALL') {
    selectionState?.forEach((n: number) => {
      selectedLabelIds.add(n)
    })
  }
  const bulkActionOptions: ListActionConfig<BulkActionOptionType>[] = [
    ...(isShoppable
      ? [
          {
            label: 'Change Tier',
            action: 'setCommissionTier',
            icon: <DollarSignIcon width={20} />,
          } as ListActionConfig<BulkActionOptionType>,
        ]
      : []),
    { action: 'segment', label: 'Segment', icon: <PlusIcon width={16} /> },
    {
      action: 'gift',
      label: 'Send Reward',
      icon: <PresentIcon width={16} height={16} />,
    },
    ...(showPickWinnerBulk
      ? [
          {
            action: 'rewardChallenge',
            label: 'Pick winner',
            icon: <TrophyIcon width={16} height={16} />,
          } as ListActionConfig<BulkActionOptionType>,
        ]
      : []),
    { action: 'export', label: 'Export', icon: <Export /> },
    { action: 'removeBulk', label: 'Remove', icon: <OutlineCrossIcon width={20} /> },
  ]
  const actionOptions: ListActionConfig<ActionOptionType>[] = [
    ...(isShoppable
      ? [
          {
            label: 'Change Tier',
            action: 'setCommissionTier',
            icon: <DollarSignIcon width={20} />,
          } as ListActionConfig<ActionOptionType>,
        ]
      : []),
    { label: 'Segment', action: 'addSegment', icon: <PlusIcon width={20} /> },
    { label: 'Send Reward', action: 'gift', icon: <PresentIcon width={20} /> },
    ...(showPickWinnerBulk
      ? [
          {
            action: 'rewardChallenge',
            label: 'Pick winner',
            icon: <TrophyIcon width={16} height={16} />,
          } as ListActionConfig<ActionOptionType>,
        ]
      : []),
  ]

  const selectedCount = selectionState === null ? 0 : selectionState === 'ALL' ? totalMembers : selectionState.size

  const customFields = campaignData?.whoami?.account?.customFields || []
  const customFieldsFilterConfig = createCustomFieldFilters(customFields)

  const { data: messageTemplatesListData } = useMessageTemplatesListFilterQuery({
    skip: !selectedSocialAccountId,
    variables: {
      socialAccountId: selectedSocialAccountId || '',
      limit: MESSAGE_TEMPLATES_LIMIT,
    },
  })

  const messageTemplates =
    messageTemplatesListData?.socialAccount &&
    isTypeName(messageTemplatesListData?.socialAccount, 'IGSocialAccount') &&
    messageTemplatesListData?.socialAccount?.messageTemplates
  const messageTemplatesList = messageTemplates ? messageTemplates.results : []

  const accountLabels = userData?.whoami?.account?.labels?.results || []

  const labelOption: LabelsOption = {
    name: 'labels',
    type: 'labels',
    label: 'Labels',
    entity: 'label',
    selectionOptions: accountLabels,
    includeField: 'all' as const,
    seeAllLink: LABEL_MANAGEMENT_ROUTE.path,
  }
  const affiliateActivityFilters: Options = [
    {
      name: 'affiliateActivity',
      type: 'parent' as const,
      label: 'Affiliate Activity',
      children: [
        {
          name: 'paymentPeriods',
          type: 'labels' as const,
          label: 'Pay Date & Period',
          entity: 'payment period' as const,
          includeField: 'any',
          selectionOptions: paymentPeriods,
          customLabelRenderer: label => (
            <Box display="grid" gridTemplateColumns="10em 11em" alignItems="center">
              <Typography style={{ fontSize: '.9rem' }}>{label.name}</Typography>
              {!!label.value && (
                <Typography variant="subtitle2" style={{ fontSize: '.7rem' }}>
                  Approved orders for <br />
                  {paymentPeriodsDateFormat(label.value.startAt)} - {paymentPeriodsDateFormat(label.value.endAt)}
                </Typography>
              )}
            </Box>
          ),
        } as LabelsOption<PaymentPeriod>,
        {
          name: 'referredOrders',
          type: 'numericalRange',
          label: 'Referred Orders',
          numberFormat: 'number',
          presets: [],
        },
        {
          name: 'referredSalesRevenue',
          type: 'numericalRange',
          label: 'Referred Sales Revenue',
          numberFormat: 'currency',
          currencyCode: programCurrencyCode ?? undefined,
          min: 0,
          max: 1_000_000_000,
          presets: [
            { gte: 0, lte: 0 },
            { gt: 0 },
            { lte: 1000 },
            { gte: 1000, lte: 10_000 },
            { gte: 10_000, lte: 1_000_000 },
            { gte: 100_000, lte: 10_000_000 },
            { gte: 1_000_000 },
          ],
        },
        {
          name: 'commissionsEarned',
          type: 'numericalRange',
          label: 'Commissions Earned',
          numberFormat: 'currency',
          currencyCode: programCurrencyCode ?? undefined,
          min: 0,
          max: 1_000_000_000,
          presets: [
            { gte: 0, lte: 0 },
            { gt: 0 },
            { lte: 1000 },
            { gte: 1000, lte: 10_000 },
            { gte: 10_000, lte: 1_000_000 },
            { gte: 100_000, lte: 10_000_000 },
            { gte: 1_000_000 },
          ],
        },
        {
          name: 'linkViews',
          type: 'numericalRange',
          label: 'Sessions',
          min: 0,
          max: 1_000_000_000,
          presets: [
            { lte: 1000 },
            { gte: 1000, lte: 10_000 },
            { gte: 10_000, lte: 1_000_000 },
            { gte: 100_000, lte: 10_000_000 },
            { gte: 1_000_000 },
          ],
        },
        {
          type: 'numericalRange',
          name: 'conversionRate',
          label: 'Conversion Rate',
          numberFormat: 'percent',
          presets: [],
        },
        {
          name: 'orderStatus',
          type: 'selection',
          label: 'Order Status',
          selectionOptions: [
            {
              id: 'approved',
              label: 'Approved',
            },
            {
              id: 'awaiting_approval',
              label: 'Awaiting Approval',
            },
          ],
        },
        {
          name: 'commissionsPaymentStatus',
          type: 'selection',
          label: 'Commissions Status',
          selectionOptions: [
            {
              id: PaymentStatusEnum.Paid,
              label: 'Earned',
            },
            {
              id: PaymentStatusEnum.Pending,
              label: 'Upcoming',
            },
            {
              id: PaymentStatusEnum.Owed,
              label: 'Owed',
            },
          ],
        } as SelectionOption<PaymentStatusEnum>,
        {
          name: 'commissionTiers',
          type: 'labels',
          entity: 'commission tier',
          label: 'Commission Tier',
          includeField: 'any' as const,
          selectionOptions: shoppableData?.campaign?.program?.commissionTiers ?? [],
          exclude: true,
        } as LabelsOption<CommissionTierType>,
        {
          type: 'numericalRange',
          name: 'commissionRate',
          label: 'Commission Rate',
          numberFormat: 'percent',
          presets: [],
        },
      ],
    },
  ]
  let campaignOptions: Options = [
    { name: 'dateRange', type: 'dateRange', label: 'Date Range', entity: 'normal' },
    { name: 'postedAt', type: 'dateRange', label: 'Post Date', entity: 'normal' },
    ...(isShoppable ? [...affiliateActivityFilters] : []),
    {
      name: 'segments',
      type: 'labels' as const,
      label: 'Segments',
      entity: 'segment' as const,
      selectionOptions: accountSegments,
      includeField: 'all' as const,
      seeAllLink: SEGMENT_MANAGEMENT_ROUTE.path,
    },
    {
      name: 'usernameKeywords',
      type: 'keywords',
      label: 'Username',
      useChips: true,
    },
    ...(!!challenges.length
      ? [
          {
            name: 'challengeIds',
            type: 'labels',
            label: 'Challenges',
            entity: 'challenge',
            selectionOptions: challenges,
            includeField: 'any' as const,
          } as LabelsOption,
        ]
      : []),
    {
      name: 'messagesGroup',
      type: 'parent' as const,
      label: 'Messages',
      children: [
        {
          name: 'messageTemplate',
          type: 'labels' as const,
          label: 'Template Name ',
          entity: 'message' as const,
          selectionOptions: messageTemplatesList,
          includeField: 'all' as const,
        },
        {
          name: 'messageDate',
          type: 'dateRange',
          label: 'Message Date',
          entity: 'normalPlusHours',
          exclude: true,
        } as DateRangeOption,
        {
          name: 'messageKeywords',
          type: 'keywords' as const,
          label: 'Message Keywords ',
          includeField: 'all' as const,
          useChips: true,
          allowSpaces: true,
        },
        {
          name: 'messageSendingMethod',
          type: 'selection',
          label: 'Sending Method',
          selectionOptions: messageSendingMethodOptions,
          exclude: true,
        } as SelectionOption,
      ],
    },
    {
      name: 'emailKeywords',
      type: 'keywords',
      label: 'Email',
      useChips: true,
    },
    {
      name: 'biographyKeywords',
      type: 'keywords',
      label: 'Biography',
      useChips: true,
      allowSpaces: true,
    },
    {
      name: 'noteInfo',
      type: 'parent',
      label: 'Notes',
      children: [
        {
          name: 'notesKeywords',
          type: 'keywords',
          label: 'Note Keyword',
          useChips: true,
          allowSpaces: true,
        } as KeywordsOption,
        {
          name: 'noteCategories',
          type: 'selection',
          label: 'Note Category',
          selectionOptions: noteCategoryOptions,
        } as SelectionOption,
        {
          name: 'noteCreatedAt',
          type: 'dateRange',
          label: 'Note Date',
          entity: 'normal',
        },
      ],
    } as ParentOption,
    {
      name: 'memberInfo',
      type: 'parent',
      label: 'Member Info',
      children: [
        {
          name: 'followerCount',
          type: 'numericalRange',
          label: 'Follower Count',
          min: 0,
          max: 1_000_000_000,
          presets: [
            { lte: 1000 },
            { gte: 1000, lte: 10_000 },
            { gte: 10_000, lte: 1_000_000 },
            { gte: 100_000, lte: 10_000_000 },
            { gte: 1_000_000 },
          ],
        },
        {
          type: 'numericalRange',
          name: 'avgEngagementRate',
          label: 'Engagement Rate',
          min: 0,
          max: 300,
          sliderRange: {
            gte: 0,
            lte: 150,
          },
          numberFormat: 'percent',
        },
        {
          name: 'impressions',
          type: 'numericalRange',
          label: 'Impressions',
          min: 0,
          max: 300_000_000,
          presets: [
            { lte: 1000 },
            { gte: 1000, lte: 10_000 },
            { gte: 10_000, lte: 1_000_000 },
            { gte: 100_000, lte: 10_000_000 },
            { gte: 1_000_000 },
          ],
        },
      ],
    },
    {
      name: 'socialActivity',
      type: 'parent',
      label: 'Social Activity',
      children: [
        { name: 'approvedAt', type: 'dateRange', label: 'Member Since Date', entity: 'normal' },
        {
          type: 'numericalRange',
          name: 'postCount',
          label: 'Post Count',
          min: 0,
          max: 5000,
          sliderRange: {
            gte: 0,
            lte: 1000,
          },
        },
        {
          name: 'maxPostedAt',
          type: 'dateRange',
          label: 'Inactive Since',
          entity: 'inactiveFilter',
        },
        { name: 'maxRewardedAt', type: 'dateRange', label: 'Last Reward Date', entity: 'normal' },
        {
          name: 'rewards',
          type: 'selection',
          label: 'Reward',
          selectionOptions: campaignRewards.map(r => ({
            id: r.id.toString(),
            label: r.name || `Reward ${r.id.toString()}`,
          })),
        },
        {
          name: 'minPostedAt',
          type: 'dateRange',
          label: 'First Post Date',
          entity: 'normal',
        } as DateRangeOption,
      ],
    },
    {
      name: 'postInfo',
      type: 'parent',
      label: 'Post Info',
      children: [
        ...(isIGSocialAccount || hasTikTokHashtags
          ? [
              {
                name: 'mentionType',
                type: 'selection',
                label: 'Mention Type',
                selectionOptions: isIGSocialAccount ? igMentionTypeOptions : ttMentionTypeOptions,
              } as SelectionOption,
            ]
          : []),
        {
          name: 'mediaType',
          type: 'selection',
          label: 'Media Type',
          selectionOptions: mediaTypeOptions,
        } as SelectionOption,
        ...(isIGSocialAccount
          ? [
              {
                name: 'postType',
                type: 'selection',
                label: 'Post Type',
                selectionOptions: postTypeOptions,
              } as SelectionOption,
            ]
          : []),
        labelOption,
      ],
    },
    {
      name: 'postStatus',
      type: 'parent',
      label: 'Post Status',
      children: [
        {
          name: 'tagStatus',
          type: 'selection',
          label: 'Program Status',
          selectionOptions: tagStatusOptions,
        } as SelectionOption,
        {
          name: 'challengeMediaApproval',
          type: 'selection',
          label: 'Challenge Status',
          selectionOptions: challengeApprovalStatusOptions,
        },
      ],
    },
  ]
  if (customFields && customFields.length > 0) {
    campaignOptions = [
      ...campaignOptions,
      {
        name: 'customFields',
        type: 'parent',
        label: 'Custom Field Data',
        children: customFieldsFilterConfig,
      } as ParentOption,
    ]
  }
  const excludeOptions = [
    {
      name: 'segments',
      type: 'labels' as const,
      label: 'Segments',
      entity: 'segment' as const,
      selectionOptions: accountSegments,
      includeField: 'all' as const,
      seeAllLink: SEGMENT_MANAGEMENT_ROUTE.path,
    },
    {
      name: 'messagesGroup',
      type: 'parent' as const,
      label: 'Messages',
      children: [
        {
          name: 'messageTemplate',
          type: 'labels' as const,
          label: 'Template Name ',
          entity: 'message' as const,
          selectionOptions: messageTemplatesList,
        },
        {
          name: 'messageDate',
          type: 'dateRange',
          label: 'Message Date',
          entity: 'normalPlusHours',
          exclude: true,
        } as DateRangeOption,
        {
          name: 'messageKeywords',
          type: 'keywords' as const,
          label: 'Message Keywords ',
          useChips: true,
          allowSpaces: true,
        },
        {
          name: 'messageSendingMethod',
          type: 'selection',
          label: 'Sending Method',
          selectionOptions: messageSendingMethodOptions,
          exclude: true,
        } as SelectionOption,
      ],
    },
  ]

  const customerTableSharedProps = {
    isIGSocialAccount,
    hasCampaigns: true,
    limit: 10,
    emptyText: 'No members found',
    ready: !loadingInitial && hasSocialAccount,
    loadingMore: loadingMore,
    useCustomerPageLinks: true,
    list: data?.campaign?.participants?.results || [],
    nextPaymentDate: nextPaymentDate ?? undefined,
    sort,
    setSort: (x: ParticipantSort, forceDir?: SortDirection) => {
      if (forceDir) {
        setSortDir(forceDir)
      } else if (x === sort) {
        const sortDirection = sortDir === SortDirection.Desc ? SortDirection.Asc : SortDirection.Desc
        setSortDir(sortDirection)
      }
      selectionDispatch({ type: 'reset' })
      setSort(x)
    },
    sortDir,
    selectionDispatch,
    selectionState,
    multiSelect: true,
    actions: actionOptions,
    deleteAction: { label: 'Remove', action: 'delete', icon: <OutlineCrossIcon width={20} /> },
    onSelectCustomerAction: (participantId: number, action: ActionOptionType | 'delete') => {
      const participant = data?.campaign?.participants?.results?.find(p => p.id === participantId)
      switch (action) {
        case 'delete':
          if (!participant) {
            return
          }
          dispatch({
            type: 'start',
            action: 'remove',
            participant,
          })
          break
        case 'addSegment':
          dispatch({ type: 'start', action: 'segment', selectedCount: 1, participant })
          break
        case 'setCommissionTier':
          dispatch({ type: 'start', action: 'setCommissionTier', selectedCount: 1, participant })
          break
        case 'gift':
          dispatch({ type: 'start', action: 'gift', participant, selectedCount: 1 })
          break
        case 'rewardChallenge':
          dispatch({ type: 'start', action: 'rewardChallenge', participant, selectedCount: 1 })
          break
      }
    },
  }

  return (
    <>
      <CampaignMemberSavedFilterBar
        filters={filters}
        setFilters={handleFiltersChanged}
        accountId={userData?.whoami?.account?.id}
        campaignId={id}
        accountFilters={
          userData?.whoami?.account?.customerFilters?.filter(filter => {
            if (filter?.value?.campaigns) {
              // invalid if this campaign is in campaigns.none
              const none = filter.value.campaigns.none
              if (none && !!none.find(campaignId => campaignId === id)) {
                return false
              }

              // invalid if this campaign is not in campaigns.any
              const any = filter.value.campaigns.any
              if (any && !any.find(campaignId => campaignId === id)) {
                return false
              }
            }
            return true
          }) || []
        }
        isInstagramAccount={isIGSocialAccount ?? false}
        setSaveFilterDialogOpen={setSaveFilterDialogOpen}
        saveFilterDialogOpen={saveFilterDialogOpen}
      />
      <ListFilters
        contentType="members"
        isDirty={isDirty}
        onChangeFilters={handleFiltersChanged}
        options={campaignOptions}
        excludeOptions={[...excludeOptions, labelOption]}
        filters={filters}
        setSaveFilterDialogOpen={setSaveFilterDialogOpen}
      />
      <ListHeader>
        <Box mr={2}>
          <ListCount loading={loadingInitial} count={totalMembers} units="Members" />
        </Box>
        {!loadingInitial && (
          <Button
            variant="text"
            color={selectionState === 'ALL' ? 'secondary' : 'primary'}
            onClick={() => selectionDispatch({ type: 'toggleSelectAll' })}
          >
            {selectionState !== 'ALL'
              ? `Select All ${totalMembers.toLocaleString()}`
              : `All ${totalMembers.toLocaleString()} Selected`}
          </Button>
        )}
        <Box flexGrow={1} />
        <ListActions>
          {!!selectionState && !!totalMembers && (
            <ListActionMenu
              actions={bulkActionOptions}
              onSelectAction={action => {
                dispatch({
                  type: 'start',
                  action,
                  selectedCount,
                })
              }}
            />
          )}
          <Button
            color="primary"
            variant="outlined"
            startIcon={<PlusIcon width={16} />}
            onClick={() => setAddMemberOpen(true)}
          >
            Add Member
          </Button>
        </ListActions>
      </ListHeader>
      {customerTableEntity === 'shoppableParticipant' ? (
        <CustomerTable
          {...customerTableSharedProps}
          showCommissionsColumn={
            (shoppableData?.campaign?.program?.commissionsEnabled ||
              shoppableData?.campaign?.program?.hasAttributedCommissions) ??
            false
          }
          entity={customerTableEntity}
          includedCommissionsStatuses={new Set(filterCommissionPaymentStatuses)}
          ordersAwaitingApproval={filterAwaitingApprovalStatus ?? null}
          currencyCode={programCurrencyCode || undefined}
        />
      ) : (
        <CustomerTable {...customerTableSharedProps} entity={customerTableEntity} />
      )}
      {error && (
        <Box display="flex" justifyContent="center">
          <ContainerError text="Error loading customers." />
        </Box>
      )}
      {!error && cursor && (
        <Box display="flex" flexDirection="row" justifyContent="center" mt={8}>
          <Button variant="outlined" color="primary" size="large" onClick={handleLoadMore} disabled={loadingMore}>
            Load more
          </Button>
        </Box>
      )}
      <LabelMenu
        editable
        noListIcons
        entity="commission tier"
        title="Add to Commission Tier"
        extraCreateItem="Create New Tier"
        open={bulkState?.step === 'pickCommissionTier'}
        variant="dialog"
        labels={shoppableData?.campaign?.program?.commissionTiers || []}
        style={{ maxHeight: '340px' }}
        onSelect={id => {
          if (bulkState?.step !== 'pickCommissionTier') return
          setParticipantCommissionTier(id, bulkState.participant?.id)
        }}
        onCreate={(name: string) => {
          dispatch({ type: 'createCommissionTier', name })
        }}
        onUpdate={() => {
          dispatch({ type: 'done' })
        }}
        onDelete={() => {
          dispatch({ type: 'done' })
        }}
        hasHitLimit={false}
        onCancel={() => {
          dispatch({ type: 'done' })
        }}
        allowedActions={[]}
      />
      <LabelMenu
        editable
        entity="segment"
        seeAllLink={SEGMENT_MANAGEMENT_ROUTE.path}
        open={bulkState?.step === 'pickSegment'}
        variant="dialog"
        labels={accountSegments || []}
        onSelect={id => {
          dispatch({ type: 'selectedSegment', id })
        }}
        onCreate={(name: string) => {
          dispatch({ type: 'createSegment', name })
        }}
        onUpdate={() => {
          dispatch({ type: 'done' })
        }}
        onDelete={() => {
          dispatch({ type: 'done' })
        }}
        hasHitLimit={hasHitSegmentLimit}
        onCancel={() => {
          dispatch({ type: 'done' })
        }}
        selectedLabelIds={
          selectedLabelIds.size > 0 ? selectedLabelIds : selectionState === 'ALL' ? selectionState : undefined
        }
        allowedActions={[]}
      />
      <ActionDialogs
        bulkState={bulkState}
        dispatch={dispatch}
        selectedCount={selectedCount}
        onConfirm={handleConfirmBulkAction}
        entityName="members"
      />
      <AddMemberDialog
        open={addMemberOpen}
        onSave={handleAddMember}
        onCancel={() => setAddMemberOpen(false)}
        platforms={
          new Set(
            campaign?.program?.socialAccounts?.map(s =>
              s.__typename === 'IGSocialAccount' ? SocialPlatformEnum.Instagram : SocialPlatformEnum.Tiktok,
            ) || [],
          )
        }
        isIGRequired={!!campaign?.program?.hosts?.every(a => a.igRequired)}
        isTTRequired={!!campaign?.program?.hosts?.every(a => a.ttRequired)}
      />
      <SendRewardDialog
        open={bulkState?.step === 'pickReward'}
        onClose={() => dispatch({ type: 'done' })}
        onSendReward={(reward, offline) => dispatch({ type: 'selectedReward', reward, offline })}
        canAutoDeliver
        canCopyCode={bulkState?.step === 'pickReward' && !!bulkState.participant}
      />
      <CampaignPickWinnerDialog
        open={bulkState?.step === 'pickWinner'}
        onClose={() => dispatch({ type: 'done' })}
        challengeId={singleChallengeFilterId}
        onSendReward={async (reward, challengeId, offline) =>
          dispatch({ type: 'selectedChallengeReward', reward, challengeId, offline })
        }
      />
      {bulkState?.step === 'creation' && bulkState.action === 'createCommissionTier' && (
        <UpsertCommissionTierModal
          open={true}
          existingCommissionTiers={shoppableData?.campaign?.program?.commissionTiers}
          cancelCallback={() =>
            dispatch({
              type: 'start',
              action: 'createCommissionTier',
              selectedCount,
              participant: bulkState.participant,
            })
          }
          initialTierName={bulkState.name}
          doneCallback={(tierName: string, commissionPercentage: number) =>
            saveCommissionTier(tierName, commissionPercentage, bulkState.participant?.id)
          }
        />
      )}
    </>
  )
}

export default CampaignMembers

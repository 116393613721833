import React from 'react'

interface XAxisTickProps {
  index: number
  x: number
  y: number
  payload: { value: string }
}

function withXAxisTick(gap: number, fill: string, width?: number): (p: XAxisTickProps) => React.ReactElement | null {
  function XAxisTick({ index, x, y, payload: { value } }: XAxisTickProps): React.ReactElement | null {
    const factor = width && width < 1200 ? 20 : 0
    if (index % gap !== 0) {
      return null
    }
    return (
      <g transform={`translate(${x - factor},${y})`}>
        <text fill={fill} dy={22} dx={-6} textAnchor="middle" transform={`rotate(-${factor})`}>
          {value.split('-').length === 2 ? value.substring(0, value.indexOf('-')) : value}
        </text>
      </g>
    )
  }
  return XAxisTick
}

export default withXAxisTick

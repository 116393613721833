import { CommissionTierType } from '../../../gql-global'
import { CampaignSettingsQuery } from './operations/campaign-settings.generated'
import React, { useState } from 'react'
import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { gray, primary } from '../../../loudcrowd-theme'

import { ReactComponent as CustomerIcon } from '../../../icons/people-outlined-cropped.svg'
import { ReactComponent as PlusIcon } from '../../../icons/plus_minor.svg'
import { ReactComponent as PencilIcon } from '../../../icons/edit_pencil.svg'
import { ReactComponent as TrashIcon } from '../../../icons/trash_can.svg'
import { format } from 'date-fns'
import { useUpsertCommissionTierMutation } from './operations/upsert-commission-tier.generated'
import { useToast } from '../../../components/Alert/ToastProvider'
import UpsertCommissionTierModal from './UpsertCommissionTierModal'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { CAMPAIGN_ROUTE, CampaignDetailRouteParams } from '../../routes'
import { encodeFilterParams } from '../use-filter-params-members'
import ActionMenu, { ActionType } from '../../../components/ActionsMenu/ActionsMenu'
import DeleteCommissionTierModal from './DeleteCommissionTierModal'

const useStyles = makeStyles(() =>
  createStyles({
    tableBorder: {
      border: '1px solid',
      borderColor: gray,
    },
    field: {
      marginTop: 20,
      marginBottom: 20,
    },
    tierNameCell: {
      position: 'relative',
    },
    tierNameLabel: {
      marginTop: 'auto',
      marginBottom: '2px',
    },
    defaultTierCaption: {
      position: 'absolute',
      top: '8px',
      fontStyle: 'italic',
    },
    defaultEditButton: {
      width: '80px',
    },
    assignMembersButton: {
      width: '160px',
    },
    cellContainerWithButton: {
      display: 'grid',
      gridTemplateColumns: '1fr auto',

      '& button': {
        maxHeight: '30px',
      },
    },
    affiliateCountCell: {
      width: '160px',
    },
    affiliateCountContainer: {
      color: primary[500],
    },
  }),
)

interface CommissionTierListProps {
  commissionTiers: CommissionTierType[]
  program: NonNullable<NonNullable<CampaignSettingsQuery['campaign']>['program']>
  refreshAfterMutation: () => Promise<void>
}

export default function CommissionTierList({
  commissionTiers,
  program,
  refreshAfterMutation,
}: CommissionTierListProps): React.ReactElement {
  const classes = useStyles()
  const { showToast } = useToast()
  const { id: campaignId } = useParams<CampaignDetailRouteParams>()

  const [commissionTierModalOpen, setCommissionTierModalOpen] = useState(false)
  const [deleteCommissionTierModalOpen, setDeleteCommissionTierModalOpen] = useState(false)
  const [selectedCommission, setSelectedCommission] = useState<CommissionTierType | null>(null)
  const [isCommissionTierHovered, setIsCommissionTierHovered] = useState<boolean[]>([])

  const setIsCommissionTierHoveredAtIndex = (val: boolean, i: number) => {
    setIsCommissionTierHovered(arr => {
      let newArr = [...arr]
      newArr[i] = val
      return newArr
    })
  }

  const [upsertCommissionTier] = useUpsertCommissionTierMutation({
    onError: () => {
      showToast({
        title: 'Error: Saving Commission Tier',
        message: 'Something went wrong while saving the Commission Tier, please try again.',
        severity: 'error',
      })
    },
    onCompleted: () => {
      showToast({
        title: 'Success',
        message: 'Commission Tier successfully updated',
        severity: 'success',
      })
    },
  })

  const openCommissionTierModal = (newSelectedCommissionTier: CommissionTierType | null) => {
    setSelectedCommission(newSelectedCommissionTier)
    setCommissionTierModalOpen(true)
  }

  const saveCommissionTier = async (tierName: string, commissionPercentage: number) => {
    await upsertCommissionTier({
      variables: {
        programId: program.id,
        data: {
          id: selectedCommission?.id,
          name: tierName,
          ratePercentage: commissionPercentage,
        },
      },
    })
    setCommissionTierModalOpen(false)
    await refreshAfterMutation()
  }

  const deleteCommissionTier = async (newSelectedCommissionTier: CommissionTierType | null) => {
    setCommissionTierModalOpen(false)
    if (newSelectedCommissionTier?.id === undefined) return
    setSelectedCommission(newSelectedCommissionTier)
    setDeleteCommissionTierModalOpen(true)
  }

  const defaultCommissionTierIdx = commissionTiers.findIndex(ct => ct.id === program.defaultCommissionTier?.id)
  const commissionTiersWithDefaultFirst = defaultCommissionTierIdx
    ? [
        commissionTiers[defaultCommissionTierIdx] as CommissionTierType,
        ...commissionTiers.filter((_, i) => i !== defaultCommissionTierIdx),
      ]
    : commissionTiers

  return (
    <>
      <Box className={classes.field}>
        <TableContainer component={Box}>
          <Table style={{ tableLayout: 'auto' }}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableBorder}>
                  <Typography variant="subtitle1" color="textPrimary">
                    Tier Name
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableBorder}>
                  <Typography variant="subtitle1" color="textPrimary">
                    Commission Rate
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableBorder}>
                  <Typography variant="subtitle1" color="textPrimary">
                    Affiliate Members
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableBorder}>
                  <Typography variant="subtitle1" color="textPrimary">
                    Last Updated
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {commissionTiers.length === 0 && (
                <TableRow>
                  <TableCell colSpan={4} className={classes.tableBorder}>
                    <Typography variant="subtitle1" color="textPrimary">
                      Press the "Add New Tier" button to start creating the Program's commissions structure
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {commissionTiersWithDefaultFirst.map((ct, i) => (
                <TableRow
                  onMouseEnter={() => setIsCommissionTierHoveredAtIndex(true, i)}
                  onMouseLeave={() => setIsCommissionTierHoveredAtIndex(false, i)}
                >
                  <TableCell className={`${classes.tableBorder} ${classes.tierNameCell}`}>
                    <Box className={classes.cellContainerWithButton}>
                      <Box className={classes.tierNameLabel}>
                        {ct.id === program.defaultCommissionTier?.id ? (
                          <Typography className={classes.defaultTierCaption} variant="caption" color="secondary">
                            Default
                          </Typography>
                        ) : null}
                        <Typography color="primary">{ct.name}</Typography>
                      </Box>
                      {ct.id === program.defaultCommissionTier?.id ? (
                        <Box>
                          <Button
                            onClick={() => openCommissionTierModal(ct)}
                            variant="outlined"
                            color="primary"
                            className={classes.defaultEditButton}
                            style={{ visibility: isCommissionTierHovered[i] ? 'visible' : 'hidden' }}
                          >
                            Edit
                          </Button>
                        </Box>
                      ) : (
                        <Box style={{ visibility: isCommissionTierHovered[i] ? 'visible' : 'hidden' }}>
                          <ActionMenu
                            disabled={false}
                            isMenuItemDisabled={(action: ActionType) => false}
                            actions={[
                              {
                                action: 'edit',
                                icon: <PencilIcon width={16} />,
                                label: 'Edit',
                                actionCallback: () => openCommissionTierModal(ct),
                              },
                              {
                                action: 'delete',
                                icon: <TrashIcon width={16} />,
                                label: 'Delete',
                                actionCallback: () => {
                                  deleteCommissionTier(ct)
                                },
                              },
                            ]}
                          />
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell className={classes.tableBorder}>{ct.ratePercentage}%</TableCell>
                  <TableCell className={`${classes.tableBorder} ${classes.affiliateCountCell}`}>
                    <Box className={classes.cellContainerWithButton}>
                      <Link
                        variant="body2"
                        color="primary"
                        component={RouterLink}
                        style={{ display: 'flex' }}
                        to={{
                          pathname: `${CAMPAIGN_ROUTE.path}/${campaignId}/members`,
                          search: `?${encodeFilterParams({
                            commissionTiers: { any: [ct.id] },
                          })}`,
                        }}
                      >
                        <Box
                          className={classes.affiliateCountContainer}
                          minWidth={120}
                          display="flex"
                          alignItems="center"
                          gridGap={5}
                        >
                          <CustomerIcon fill="currentColor" height={24} width={24} />
                          <span>{ct.affiliateMembersCount}</span>
                        </Box>
                      </Link>
                      <Box>
                        {!ct.affiliateMembersCount && (
                          <Link
                            variant="body2"
                            color="primary"
                            component={RouterLink}
                            style={{ display: 'flex' }}
                            to={{
                              pathname: `${CAMPAIGN_ROUTE.path}/${campaignId}/members`,
                            }}
                          >
                            <Button
                              variant="outlined"
                              color="primary"
                              className={classes.assignMembersButton}
                              style={{ visibility: isCommissionTierHovered[i] ? 'visible' : 'hidden' }}
                            >
                              Assign members
                            </Button>
                          </Link>
                        )}
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell className={classes.tableBorder}>{format(ct.updatedAt, 'MMMM d, yyyy')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          onClick={() => openCommissionTierModal(null)}
          style={{ marginTop: '15px' }}
          startIcon={<PlusIcon width={16} />}
          color="primary"
          variant="contained"
        >
          Add New Tier
        </Button>
      </Box>
      <UpsertCommissionTierModal
        open={commissionTierModalOpen}
        existingCommissionTiers={program.commissionTiers}
        cancelCallback={() => setCommissionTierModalOpen(false)}
        doneCallback={saveCommissionTier}
        selectedCommissionTier={selectedCommission}
        deleteCallback={() => deleteCommissionTier(selectedCommission)}
        allowDelete={!program.defaultCommissionTier || program.defaultCommissionTier.id !== selectedCommission?.id}
      />
      <DeleteCommissionTierModal
        open={deleteCommissionTierModalOpen}
        close={() => setDeleteCommissionTierModalOpen(false)}
        deletingCommissionTier={selectedCommission}
        defaultCommissionTier={program.defaultCommissionTier!}
        refreshAfterMutation={refreshAfterMutation}
      />
    </>
  )
}

import React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  MenuItem,
  makeStyles,
  createStyles,
  Theme,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import { IntegrationRow_RefersionIntegration_Fragment } from './operations/integration-row.generated'
import { Formik, Field, Form, FieldArray } from 'formik'
import { TextField, Select } from 'formik-material-ui'
import * as yup from 'yup'
import { IntegrationType } from '../gql-global'
import { isApolloError } from '@apollo/client'
import { ReactComponent as TrashIcon } from '../icons/trash_can.svg'

const schema = yup.object({
  name: yup.string().trim().required('Integration name is required.'),
  key: yup.string().trim().required('Refersion key is required.'),
  igUsernameFields: yup
    .array()
    .of(yup.string().trim().required('username field cannot be empty'))
    .min(1)
    .ensure()
    .compact()
    .required('Username field id required.'),
  ttUsernameFields: yup.array().of(yup.string().trim().required('username field cannot be empty')).compact(),
})

export type AddEditCustomerIntegrationFormFields = yup.InferType<typeof schema> & {
  integrationType: IntegrationType
}

interface AddEditCustomerIntegrationProps {
  open: boolean
  onCancel(): void
  onSubmit(values: AddEditCustomerIntegrationFormFields): Promise<void>
  existing?: IntegrationRow_RefersionIntegration_Fragment
  error?: Error | null
}

const platformNameMap: Record<IntegrationType.Refersion, string> = {
  [IntegrationType.Refersion]: 'Refersion',
}

const defaultFields: AddEditCustomerIntegrationFormFields = {
  integrationType: IntegrationType.Refersion,
  name: 'Refersion',
  key: '',
  igUsernameFields: [''],
  ttUsernameFields: [''],
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    field: {
      marginBottom: theme.spacing(5),
    },
    select: {
      width: '100%',
    },
    igUsernameField: {
      width: '100%',
    },
  }),
)

function sanitizeValues(values: Readonly<AddEditCustomerIntegrationFormFields>): AddEditCustomerIntegrationFormFields {
  return {
    ...values,
    name: values.name.trim(),
    key: values.key.trim(),
    igUsernameFields: values.igUsernameFields.map(f => f.trim()).filter(f => !!f),
    ttUsernameFields: values.ttUsernameFields.map(f => f.trim()).filter(f => !!f),
  }
}

function AddEditCustomerIntegration({
  open,
  onCancel,
  existing,
  onSubmit,
  error,
}: AddEditCustomerIntegrationProps): React.ReactElement {
  const styles = useStyles()

  async function handleSubmit(values: AddEditCustomerIntegrationFormFields): Promise<void> {
    await onSubmit({
      ...sanitizeValues(values),
    })
  }
  const errorMessage =
    (error &&
      (isApolloError(error) && error.graphQLErrors.some(e => e.extensions?.code === 'DUPLICATE_KEY')
        ? 'CRM Integration with that name already exists'
        : `Error ${!!existing ? 'editing' : 'creating'} this CRM Integration`)) ||
    ''

  return (
    <Dialog open={open} onClose={onCancel}>
      <Formik<AddEditCustomerIntegrationFormFields>
        initialValues={existing || defaultFields}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        <Form noValidate>
          <DialogTitle>{!!existing ? 'Edit' : 'Add'} Integration</DialogTitle>
          <DialogContent>
            <Box display="flex" flexDirection="column" minWidth={300}>
              <Field
                className={`${styles.field} ${styles.select}`}
                component={Select}
                label="Platform"
                name="integrationType"
              >
                <MenuItem value={IntegrationType.Refersion}>{platformNameMap[IntegrationType.Refersion]}</MenuItem>
              </Field>
              <Field className={styles.field} component={TextField} label="Name" name="name" />
              <Field className={styles.field} component={TextField} label="API Key" name="key" />
              <FieldArray
                name="igUsernameFields"
                render={({ form, remove, push }) => {
                  return (
                    <Box mb={5}>
                      {form.values.igUsernameFields.map((_: unknown, i: number) => (
                        <Box mb={2} key={i}>
                          <Field
                            className={styles.igUsernameField}
                            component={TextField}
                            label="Instagram Username Field ID"
                            name={`igUsernameFields[${i}]`}
                            InputProps={{
                              endAdornment:
                                i > 0 ? (
                                  <InputAdornment position="end">
                                    <IconButton onClick={() => remove(i)} edge="end">
                                      <TrashIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ) : undefined,
                            }}
                          />
                        </Box>
                      ))}
                      <Button onClick={() => push('')}>Add Username Field</Button>
                    </Box>
                  )
                }}
              />
              <FieldArray
                name="ttUsernameFields"
                render={({ form, remove, push }) => {
                  return (
                    <Box mb={5}>
                      {form.values.ttUsernameFields.map((_: unknown, i: number) => (
                        <Box mb={2} key={i}>
                          <Field
                            className={styles.igUsernameField}
                            component={TextField}
                            label="TikTok Username Field ID"
                            name={`ttUsernameFields[${i}]`}
                            InputProps={{
                              endAdornment:
                                i > 0 ? (
                                  <InputAdornment position="end">
                                    <IconButton onClick={() => remove(i)} edge="end">
                                      <TrashIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ) : undefined,
                            }}
                          />
                        </Box>
                      ))}
                      <Button onClick={() => push('')}>Add TikTok Username Field</Button>
                    </Box>
                  )
                }}
              />
            </Box>
            {errorMessage}
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel} color="secondary">
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  )
}

export default AddEditCustomerIntegration

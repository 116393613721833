import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import {
  Box,
  Button,
  Menu,
  createStyles,
  makeStyles,
  Typography,
  Divider,
  MenuItem,
  Checkbox,
  List,
} from '@material-ui/core'
import { primary } from '../../../loudcrowd-theme'
import MetricIcon from '../../../components/metric/MetricIcon'
import { IgChallengeSaFragment } from '../../../challenges/operations/ig-challenge-sa.generated'
import { TtChallengeSaFragment } from '../../../challenges/operations/tt-challenge-sa.generated'
import { isTypeName } from '../../../types/utility'
import MenuHeader from '../../../components/MenuHeader'

export type SAFragment =
  | ({ __typename: 'IGSocialAccount' } & IgChallengeSaFragment)
  | ({ __typename: 'TTSocialAccount' } & TtChallengeSaFragment)

const useStyles = makeStyles(theme =>
  createStyles({
    inlineButton: {
      color: primary[500],
      fontSize: 'inherit',
      minWidth: 0,
      fontWeight: 400,
      textDecoration: 'underline',
      padding: 0,
      marginBottom: 4,
      textAlign: 'left',
      height: 'inherit',
      '& > .MuiButton-label': {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
      },
      '&:hover': {
        backgroundColor: 'transparent',
        textDecoration: 'underline',
      },
    },
    menu: {
      width: 320,
      border: '1px solid rgba(0, 0, 0, 0.01)',
    },
    renderedIcon: {
      marginTop: '3px',
      color: theme.palette.primary.dark,
      marginRight: '6px',
    },
    sentenceIcon: {
      color: theme.palette.primary.main,
      margin: '5px 6px 0',
    },
    sentenceSeparator: {
      color: theme.palette.text.primary,
      marginLeft: 8,
      textDecoration: 'underline',
      textDecorationColor: 'white',
    },
    metricIcon: {
      marginRight: 8,
      marginTop: 2,
    },
    list: {
      margin: '0 -24px',
      maxHeight: 200,
      overflowY: 'auto',
    },
  }),
)

interface BrandAccountFilterProps {
  socialAccounts: SAFragment[]
  preButtonLabel?: string
  selectedSocialAccountIds: string[] | null
  onSelectedSocialAccountIds: (selected: string[]) => void
}

const BrandAccountFilter: FC<BrandAccountFilterProps> = ({
  socialAccounts,
  preButtonLabel,
  selectedSocialAccountIds,
  onSelectedSocialAccountIds,
}) => {
  const classes = useStyles()
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const [values, setValues] = useState<string[]>(selectedSocialAccountIds || [])

  useEffect(() => {
    setValues(selectedSocialAccountIds || [])
  }, [menuOpen, selectedSocialAccountIds])

  const socialAccountOptions = useMemo(
    () =>
      socialAccounts.map(account => {
        return {
          id: account.id,
          label: account.socialUser.username,
          isIG: isTypeName(account, 'IGSocialAccount'),
          icon: (
            <MetricIcon
              className={classes.metricIcon}
              icon={isTypeName(account, 'IGSocialAccount') ? 'instagram' : 'tiktok'}
            />
          ),
        }
      }) || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [socialAccounts],
  )

  const selectedSocialAccounts = socialAccountOptions.filter(sa => selectedSocialAccountIds?.includes(sa.id))

  const handleOnSetValue = (value: string) => {
    const newValues = new Set(values)
    newValues.has(value) ? newValues.delete(value) : newValues.add(value)
    setValues(Array.from(newValues))
  }

  const handleSave = () => {
    onSelectedSocialAccountIds(values)
    setMenuOpen(false)
  }

  const handleReset = () => {
    onSelectedSocialAccountIds(socialAccountOptions.map(sa => sa.id))
    setMenuOpen(false)
  }

  return (
    <>
      {preButtonLabel && (
        <Box mr={1} display="inline">
          {preButtonLabel}
        </Box>
      )}
      <Button variant="text" className={classes.inlineButton} onClick={() => setMenuOpen(true)} ref={buttonRef}>
        mentions{' '}
        {selectedSocialAccounts.length > 0
          ? selectedSocialAccounts?.map((sa, index) => (
              <React.Fragment key={index}>
                <MetricIcon icon={sa?.isIG ? 'instagram' : 'tiktok'} className={classes.sentenceIcon} /> @{sa?.label}
                {index < selectedSocialAccounts.length - 1 && <span className={classes.sentenceSeparator}>or</span>}
              </React.Fragment>
            ))
          : 'this brand account'}
      </Button>
      <Menu
        open={menuOpen}
        anchorEl={buttonRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        getContentAnchorEl={null}
        onClose={() => setMenuOpen(false)}
        classes={{ paper: classes.menu }}
      >
        <MenuHeader title="Social Accounts" />
        <Box display="flex" flexDirection="column" p={5}>
          <Typography>Choose which brand account(s) to tag</Typography>
          <List className={classes.list}>
            {socialAccountOptions.map(option => (
              <MenuItem key={option.id} value={option.id} onClick={() => handleOnSetValue(option.id)}>
                <Checkbox checked={!!values?.find(sa => sa === option.id) ?? false} />
                {option.icon}
                {option.label}
              </MenuItem>
            ))}
          </List>
        </Box>
        <Divider />
        <Box display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
          <Button color="secondary" onClick={handleReset}>
            Reset
          </Button>
          <Button color="primary" variant="contained" onClick={handleSave} disabled={!values.length}>
            Save
          </Button>
        </Box>
      </Menu>
    </>
  )
}

export default BrandAccountFilter

import React, { useMemo } from 'react'
import {
  ResponsiveContainer,
  BarChart as RechartsBarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Cell,
  Tooltip,
  TooltipProps,
  ReferenceLine,
} from 'recharts'
import { SimplePaletteColorOptions, Theme, useTheme } from '@material-ui/core/styles'
import { numberFormat, NumberFormatTypes } from '../../utils/number-format'
import { ChartConfigProps, ChartVRefPoint, MultiChartProps, TimeDataPoint } from './types'
import { chartValueFormatter, convertTimeData, convertVRefLines } from './helpers'
import { DEFAULT_COLOR } from './constants'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import withXAxisTick from './withXAxisTick'
import { useWindowSize } from 'usehooks-ts'

export type MultiBarChartProps<K extends string, T extends TimeDataPoint<K>> = MultiChartProps<K, T>

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    refLineWrapper: {
      border: 'none',
      backgroundColor: theme.palette['secondary'].main,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[9],
      margin: 0,
      padding: '3px 16px',
      minHeight: '30px',
      minWidth: '120px',
    },
    refLabelStyle: {
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightBold,
    },
    wrapperStyle: { height: '62px', minWidth: '120px' },
    textStyle: {
      fontFamily: theme.typography.fontFamily,
      margin: 0,
      padding: 0,
    },
    itemStyle: {
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'capitalize',
    },
  }),
)

interface CustomTooltipProps extends TooltipProps {
  currencyCode?: string
  chartVRefLines?: ChartVRefPoint[]
  dataFormat: NumberFormatTypes | undefined
  configs: Omit<ChartConfigProps, 'dataFormat'>[]
}

const CustomTooltip = ({
  active,
  payload,
  label,
  currencyCode,
  chartVRefLines,
  configs,
  dataFormat,
}: CustomTooltipProps) => {
  const theme = useTheme()
  const classes = useStyles()
  if (active && payload && payload.length >= configs.length) {
    const ref = chartVRefLines?.find(l => l.dateRange === payload[0]?.payload.dateRange)
    return (
      <div>
        {ref && (
          <div className={classes.refLineWrapper}>
            <p className={classes.refLabelStyle + ' ' + classes.textStyle}>{ref.topLabel}</p>
            {ref.botLabel && <p className={classes.refLabelStyle + ' ' + classes.textStyle}>{ref.botLabel}</p>}
          </div>
        )}
        {configs.map((config, idx) => {
          const backgroundColor =
            theme.palette[config.color || DEFAULT_COLOR]?.[
              (config.paletteOption || 'main') as keyof SimplePaletteColorOptions
            ]
          const labelColor = theme.palette[config.color || DEFAULT_COLOR].light
          const p = payload[idx]
          return (
            <div
              key={idx}
              style={{
                border: 'none',
                backgroundColor: backgroundColor,
                borderRadius: theme.shape.borderRadius,
                boxShadow: theme.shadows[9],
                margin: 0,
                padding: '8px 16px',
                height: '62px',
                minWidth: '120px',
              }}
            >
              <p
                style={{
                  color: labelColor,
                  fontFamily: theme.typography.fontFamily,
                  margin: 0,
                  padding: 0,
                }}
              >
                {label}
              </p>
              <p className={classes.itemStyle + ' ' + classes.textStyle}>
                {config.label} {p ? chartValueFormatter(p.value as string, p, dataFormat, currencyCode) : ''}
              </p>
            </div>
          )
        })}
      </div>
    )
  }

  return null
}

function MultiBarChart<K extends string, T extends TimeDataPoint<K>>({
  chartConfigs,
  currencyCode,
  dataKeys,
  data,
  dataFormat,
  vRefLines,
}: MultiBarChartProps<K, T>): React.ReactElement | null {
  const theme = useTheme()
  const chartData = convertTimeData(data)

  const { width } = useWindowSize()
  const gap = Math.ceil(chartData.length / 12)
  const XAxisTick = useMemo(
    () => withXAxisTick(gap, theme.palette.secondary.main, width),
    [gap, theme.palette.secondary.main, width],
  )
  return (
    <ResponsiveContainer width="99%">
      <RechartsBarChart data={chartData} barGap={0} margin={{ top: 10, left: 30, bottom: 20 }}>
        <CartesianGrid vertical={false} />
        <XAxis
          padding={{ left: 25, right: 25 }}
          dataKey="dateRange"
          axisLine={false}
          tickLine={false}
          interval={0}
          tick={XAxisTick}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          tick={({ x, y, payload: { value, offset } }) => (
            <text textAnchor="end" x={x - 30} y={y + offset} fill={theme.palette.secondary.main}>
              {numberFormat(value, { format: dataFormat, currencyCode: currencyCode })}
            </text>
          )}
        />
        <Tooltip
          animationDuration={500}
          cursor={{ fill: 'none' }}
          content={(p: TooltipProps) => (
            <CustomTooltip
              {...p}
              chartVRefLines={vRefLines ? convertVRefLines(vRefLines) : undefined}
              configs={chartConfigs}
              dataFormat={dataFormat}
              currencyCode={currencyCode}
            />
          )}
        />
        {dataKeys.map((key, idx) => {
          return (
            <Bar key={key} dataKey={key} barSize={20}>
              {chartData.map((_, index) => {
                const color = chartConfigs[idx]?.color || DEFAULT_COLOR
                const paletteOption = chartConfigs[idx]?.paletteOption || 'main'
                const colorDict = theme.palette[color]
                return <Cell key={index} fill={colorDict[paletteOption as keyof SimplePaletteColorOptions]} />
              })}
            </Bar>
          )
        })}
        {vRefLines &&
          convertVRefLines(vRefLines).map(vRefLine => {
            return (
              <ReferenceLine
                key={vRefLine.dateRange}
                x={vRefLine.dateRange}
                stroke={theme.palette['secondary'].main}
                strokeWidth="2"
                strokeDasharray="3"
                position="start"
              />
            )
          })}
      </RechartsBarChart>
    </ResponsiveContainer>
  )
}

export default MultiBarChart

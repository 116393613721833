import React, { useEffect, useState } from 'react'
import { Box, Button, createStyles, Dialog, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core'
import { error } from '../../../loudcrowd-theme'
import { CustomerDetailsFragment } from '../../../customer/operations/customer-details.generated'

const useStyles = makeStyles(() =>
  createStyles({
    dialogPaper: {
      width: '30%',
      minWidth: '400px',
      height: '35%',
      minHeight: '280px',
      display: 'flex',
      flexDirection: 'column',
    },
    dialogField: { width: '100%', marginBottom: '20px' },
    dialogButton: { width: '80px' },
    cancelButton: { marginRight: '15px' },
    deleteButton: {
      color: error[600],
      borderColor: error[600],
      marginLeft: '-16px',
    },
  }),
)

type CommissionTierType = NonNullable<
  CustomerDetailsFragment['programParticipants'][number]['program']['commissionTiers']
>[number]

interface UpsertCommissionTierModalProps {
  open: boolean
  selectedCommissionTier?: CommissionTierType | null
  existingCommissionTiers: Pick<CommissionTierType, 'name' | 'id' | '__typename'>[] | null | undefined
  doneCallback: (tierName: string, commissionPercentage: number) => void
  cancelCallback: () => void
  deleteCallback?: () => void
  allowDelete?: boolean
  initialTierName?: string | null
}
const UpsertCommissionTierModal: React.FC<UpsertCommissionTierModalProps> = ({
  open,
  selectedCommissionTier,
  existingCommissionTiers,
  doneCallback,
  deleteCallback,
  cancelCallback,
  initialTierName,
  allowDelete = true,
}) => {
  const classes = useStyles()
  const [tierName, setTierName] = useState<string | null>(null)
  const [commissionPercentage, setCommissionPercentage] = useState<string | null>(null)
  const [percentageErrorMessage, setPercentageErrorMessage] = useState<string | null>(null)
  const [nameErrorMessage, setNameErrorMessage] = useState<string | null>(null)

  useEffect(() => {
    if (selectedCommissionTier) {
      setTierName(selectedCommissionTier.name)
      setCommissionPercentage(selectedCommissionTier.ratePercentage.toString())
      return
    }
    setTierName(initialTierName ?? null)
    setCommissionPercentage(null)
  }, [selectedCommissionTier, initialTierName, open])
  useEffect(() => {
    let errorMsg = null
    if (!!commissionPercentage) {
      const commissionPercentageInt = Number.parseInt(commissionPercentage)
      // Regex check is not redundant here, parseInt allows extra possibly non numberic characters if the
      // string starts with an Integer (so it might have trailing garbage)
      if (!Number.isInteger(commissionPercentageInt) || /[^0-9]/.test(commissionPercentage)) {
        errorMsg = 'Commission Percentage must be a number'
      } else if (commissionPercentageInt < 0 || commissionPercentageInt > 100) {
        errorMsg = 'Commission Percentage must be an Integer between 0 & 100'
      }
    }
    setPercentageErrorMessage(errorMsg)
  }, [commissionPercentage])
  useEffect(() => {
    let errorMsg = null
    if (!!tierName) {
      if (tierName.length > 50) {
        errorMsg = 'Name is too long (maximum is 50 characters)'
      } else if (
        existingCommissionTiers
          ?.filter(ct => !selectedCommissionTier || ct.id !== selectedCommissionTier.id)
          .map(ct => ct.name)
          ?.includes(tierName)
      ) {
        errorMsg = 'You must choose a unique name for this tier'
      }
    }
    setNameErrorMessage(errorMsg)
  }, [existingCommissionTiers, selectedCommissionTier, tierName])

  const percentageAdornment = { endAdornment: <InputAdornment position="end">%</InputAdornment> }

  return (
    <Dialog classes={{ paper: classes.dialogPaper }} open={open} onClose={cancelCallback}>
      <Box
        style={{ width: '100%', height: '100%', display: 'grid', gridTemplateRows: '9fr 1fr', padding: '3% 5% 3% 5%' }}
      >
        <Box>
          <Typography variant="h5" style={{ paddingBottom: '10px' }}>
            {!!selectedCommissionTier ? 'Edit' : 'Add'} Commission Tier
          </Typography>
          <Box>
            <TextField
              InputLabelProps={{ shrink: true }}
              className={classes.dialogField}
              label="Tier Name"
              placeholder="Provide a unique name that creators will see on their dashboard"
              value={tierName}
              onChange={e => setTierName(e.target.value)}
              error={!!nameErrorMessage}
              helperText={nameErrorMessage}
            ></TextField>
          </Box>
          <Box>
            <TextField
              InputLabelProps={{ shrink: true }}
              className={classes.dialogField}
              label="Commission Percentage"
              placeholder="Set an Integer number between 0 & 100"
              value={commissionPercentage}
              onChange={e => setCommissionPercentage(e.target.value)}
              error={!!percentageErrorMessage}
              helperText={percentageErrorMessage}
              InputProps={percentageAdornment}
            ></TextField>
          </Box>
        </Box>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Box style={{ textAlign: 'start', width: 'auto' }}>
            {!!selectedCommissionTier && (
              <Button
                className={`${classes.dialogButton} ${classes.deleteButton}`}
                onClick={() => !!deleteCallback && deleteCallback()}
                variant="text"
                size="medium"
                disabled={!allowDelete}
              >
                Delete
              </Button>
            )}
          </Box>
          <Box style={{ textAlign: 'end', width: '100%' }}>
            <Button
              className={`${classes.dialogButton} ${classes.cancelButton}`}
              onClick={cancelCallback}
              color="secondary"
              size="medium"
            >
              Cancel
            </Button>
            <Button
              className={classes.dialogButton}
              disabled={!!(percentageErrorMessage || nameErrorMessage) || !tierName || !commissionPercentage}
              onClick={() => doneCallback(tierName!, Number.parseInt(commissionPercentage!))}
              color="primary"
              size="medium"
              variant="contained"
            >
              Done
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

export default UpsertCommissionTierModal

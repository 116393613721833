import React, { ReactElement } from 'react'
import { Box, Typography } from '@material-ui/core'
import { DiscountCodeSettingBlockType } from './DiscountSettingsBlock'

interface SettingsBlockProps {
  blocks: DiscountCodeSettingBlockType[]
}

function SettingsBlocks({ blocks }: SettingsBlockProps): ReactElement {
  return (
    <Box display="flex" padding={5} gridGap={25}>
      {blocks.map((block, idx) => (
        <Box flex={1} key={idx}>
          <Typography variant="caption">{block.title}</Typography>
          <Box display="flex" paddingLeft={3}>
            <Box display="flex" flexDirection="column" gridGap={5} marginTop={2}>
              {block.items.map((item, itemIdx: number) => (
                <Typography variant="caption" color="secondary" key={itemIdx}>
                  {item.title}
                </Typography>
              ))}
            </Box>
            <Box textAlign="right" display="flex" flexDirection="column" gridGap={5} marginTop={2}>
              {block.items.map((item, itemIdx: number) => (
                <Typography variant="caption" key={itemIdx}>
                  {item.value}
                </Typography>
              ))}
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default SettingsBlocks

import * as Types from '../../../gql-global';

import { gql } from '@apollo/client';
import { TableRowShoppableParticipantFragmentDoc } from '../../../components/CustomerTable/operations/table-row-shoppable-participant.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CampaignShoppableMembersQueryVariables = Types.Exact<{
  activeStoryMentionsWhere: Types.CustomerMentionStatsFilters;
  campaignId: Types.Scalars['ID'];
  cursor?: Types.InputMaybe<Types.Scalars['String']>;
  limit: Types.Scalars['Int'];
  mentionsWhere: Types.CustomerMentionStatsFilters;
  participantsAmbassadorStatsWhere?: Types.InputMaybe<Types.AmbassadorStatsInput>;
  sortBy?: Types.InputMaybe<Types.ParticipantSort>;
  sortDirection?: Types.InputMaybe<Types.SortDirection>;
  where?: Types.InputMaybe<Types.ParticipantsInput>;
}>;


export type CampaignShoppableMembersQuery = { __typename: 'Query', campaign?: { __typename: 'CampaignType', id: number, participants?: { __typename: 'PagedParticipants', cursor?: string | null, total: number, results: Array<{ __typename: 'ProgramParticipantType', id: number, approvedAt?: Date | null, createdAt: Date, status: Types.ParticipantStatus, customer: { __typename: 'CustomerType', email?: string | null, fullName?: string | null, id: number, activeStoryMentionsStats?: { __typename: 'CustomerMentionStats', postCount: number } | null, ambassadorStats?: { __typename: 'AmbassadorStats', commissionsOwed: number, commissionsPaid: number, commissionsPending: number, landings: number, ordersApproved: number, ordersAwaitingApproval: number, revenueApproved: number, revenueAwaitingApproval: number, totalLandedOrders: number, totalOrders: number } | null, igUser?: { __typename: 'IGUser', avatarUrl?: string | null, followerCount?: number | null, id: string, username: string, storiesIgSocialAccounts?: Array<{ __typename: 'IGSocialAccount', id: string, fbUsers?: Array<{ __typename: 'FBUser', id: string, isValid: boolean }> | null }> | null } | null, mentionStats?: { __typename: 'CustomerMentionStats', avgEngagementRate: number, impressions: number, maxPostDate?: Date | null, postCount: number } | null, ttUser?: { __typename: 'TTUser', avatarUrl?: string | null, followerCount?: number | null, id: string, username: string } | null }, program: { __typename: 'ProgramType', id: string, commissionsEnabled: boolean }, programCommissionTier?: { __typename: 'CommissionTierType', id: string, name: string } | null }> } | null, program?: { __typename: 'ProgramType', id: string, commissionsEnabled: boolean, hasAttributedCommissions: boolean, commissionTiers?: Array<{ __typename: 'CommissionTierType', id: string, name: string }> | null, socialAccounts: Array<{ __typename: 'IGSocialAccount', id: string } | { __typename: 'TTSocialAccount', id: string }> } | null } | null };


export const CampaignShoppableMembersDocument = gql`
    query CampaignShoppableMembers($activeStoryMentionsWhere: CustomerMentionStatsFilters!, $campaignId: ID!, $cursor: String, $limit: Int!, $mentionsWhere: CustomerMentionStatsFilters!, $participantsAmbassadorStatsWhere: AmbassadorStatsInput, $sortBy: ParticipantSort, $sortDirection: SortDirection, $where: ParticipantsInput) {
  campaign(id: $campaignId) {
    id
    participants(
      ambassadorStatsWhere: $participantsAmbassadorStatsWhere
      cursor: $cursor
      limit: $limit
      mentionsWhere: $mentionsWhere
      sortBy: $sortBy
      sortDirection: $sortDirection
      where: $where
    ) {
      cursor
      results {
        id
        ...TableRowShoppableParticipant
      }
      total
    }
    program {
      id
      commissionsEnabled
      commissionTiers {
        id
        name
      }
      hasAttributedCommissions
      socialAccounts {
        id
      }
    }
  }
}
    ${TableRowShoppableParticipantFragmentDoc}`;

/**
 * __useCampaignShoppableMembersQuery__
 *
 * To run a query within a React component, call `useCampaignShoppableMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignShoppableMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignShoppableMembersQuery({
 *   variables: {
 *      activeStoryMentionsWhere: // value for 'activeStoryMentionsWhere'
 *      campaignId: // value for 'campaignId'
 *      cursor: // value for 'cursor'
 *      limit: // value for 'limit'
 *      mentionsWhere: // value for 'mentionsWhere'
 *      participantsAmbassadorStatsWhere: // value for 'participantsAmbassadorStatsWhere'
 *      sortBy: // value for 'sortBy'
 *      sortDirection: // value for 'sortDirection'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCampaignShoppableMembersQuery(baseOptions: Apollo.QueryHookOptions<CampaignShoppableMembersQuery, CampaignShoppableMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignShoppableMembersQuery, CampaignShoppableMembersQueryVariables>(CampaignShoppableMembersDocument, options);
      }
export function useCampaignShoppableMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignShoppableMembersQuery, CampaignShoppableMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignShoppableMembersQuery, CampaignShoppableMembersQueryVariables>(CampaignShoppableMembersDocument, options);
        }
export type CampaignShoppableMembersQueryHookResult = ReturnType<typeof useCampaignShoppableMembersQuery>;
export type CampaignShoppableMembersLazyQueryHookResult = ReturnType<typeof useCampaignShoppableMembersLazyQuery>;
export type CampaignShoppableMembersQueryResult = Apollo.QueryResult<CampaignShoppableMembersQuery, CampaignShoppableMembersQueryVariables>;
import React from 'react'
import { formatChartMonthDay } from '../../components/charts/helpers'
import { vRefLine } from '../../components/charts/types'
import TabbedMetricBarChart, { DataConfig } from '../../components/SummarizedMetricBarChart'
import {
  AbsoluteDateTimeRangeFilter,
  AmbassadorStatsInput,
  AmbassadorStatsTimeseriesInput,
  DateRangeFilterType,
  DateRangeFilterUnits,
  DateTimeRangeFilter,
  EcommWebEventType,
} from '../../gql-global'
import { DateRange, getPreviousRangeDates, realizedDateRangeFromFilter } from '../../utils/date-range-helper'
import { useCampaignRevenueStatsQuery } from './operations/campaign-revenue-stats.generated'
import { useCampaignQuery } from './operations/campaign.generated'
import { useCampaignUserInfoQuery } from './operations/campaign-user-info.generated'
import { selectedSocialPlatform } from '../../utils/social-account'

type TabOptions = 'revenue' | 'commission' | 'roa'

type TimeseriesOptions = 'revenue' | 'commission'

interface CampaignProgramRevenueAttributionProps {
  id: string
  dateRangeFilter: DateTimeRangeFilter | AbsoluteDateTimeRangeFilter | null | undefined
}

function constructAmbassadorStatsInput(dateRange: DateRange | undefined | null): AmbassadorStatsInput {
  const eventTypes = [EcommWebEventType.Landed, EcommWebEventType.DiscountRedeemed]
  if (dateRange) {
    return {
      rangeAt: [dateRange],
      disjointRefunds: false,
      eventTypes: eventTypes,
    }
  }
  return { eventTypes: eventTypes }
}

function constructAmbassadorStatsTimeseriesInput(
  dateRange: DateRange | undefined | null,
): AmbassadorStatsTimeseriesInput {
  const eventTypes = [EcommWebEventType.Landed, EcommWebEventType.DiscountRedeemed]
  return dateRange ? { rangeAt: dateRange, disjointRefunds: false, eventTypes: eventTypes } : { eventTypes: eventTypes }
}

function CampaignProgramRevenueAttribution({
  id,
  dateRangeFilter,
}: CampaignProgramRevenueAttributionProps): React.ReactElement {
  const { data: userData } = useCampaignUserInfoQuery()

  const socialPlatform = selectedSocialPlatform(userData)

  const { data: campaignData } = useCampaignQuery({
    skip: !socialPlatform,
    variables: { id: id, platform: socialPlatform! },
  })
  const lastThirtyDateRangeFilter: DateTimeRangeFilter = {
    __typename: 'RelativeDateRangeFilter',
    rangeType: DateRangeFilterType.Relative,
    unit: DateRangeFilterUnits.Days,
    value: 30,
  }
  const startAt = campaignData?.campaign?.startAt
  const dateRange = dateRangeFilter && realizedDateRangeFromFilter(dateRangeFilter)
  const previousDateRange = dateRangeFilter
    ? getPreviousRangeDates(dateRangeFilter)
    : getPreviousRangeDates(lastThirtyDateRangeFilter)

  const {
    data: ambassadorStatsData,
    loading,
    error,
  } = useCampaignRevenueStatsQuery({
    variables: {
      campaignId: id,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || new Date().getTimezoneOffset().toString(),
      currentAmbassadorStatsInput: constructAmbassadorStatsInput(dateRange),
      previousAmbassadorStatsInput: constructAmbassadorStatsInput(previousDateRange),
      ambassadorStatsTimeseriesInput: constructAmbassadorStatsTimeseriesInput(dateRange),
    },
  })

  const revenue = ambassadorStatsData?.campaign?.ambassadorStats?.revenue || 0
  const commission = ambassadorStatsData?.campaign?.ambassadorStats?.commissionsTotal || 0
  const ROA = commission === 0 ? 0 : Math.ceil(revenue / commission)
  const summary = {
    revenue: revenue,
    commission: commission,
    roa: ROA,
  }

  const previousRevenue = ambassadorStatsData?.campaign?.previousAmbassadorStats?.revenue || 0
  const previousCommission = ambassadorStatsData?.campaign?.previousAmbassadorStats?.commissions || 0
  const previousROA = previousCommission === 0 ? 0 : Math.ceil(previousRevenue / previousCommission)
  const previousSummary = {
    revenue: previousRevenue,
    commission: previousCommission,
    roa: previousROA,
  }

  const timeseries =
    ambassadorStatsData?.campaign?.ambassadorStatsTimeseries?.map(d => {
      return {
        startDate: d.startDate,
        endDate: d.endDate,
        revenue: d.revenue,
        commission: d.commissions,
      }
    }) || []

  const refLines: vRefLine[] = []
  if (startAt) {
    for (const stats of timeseries) {
      if (stats.startDate <= startAt && stats.endDate > startAt) {
        refLines.push({
          x: {
            startDate: stats.startDate,
            endDate: stats.endDate,
          },
          topLabel: `Program Start: ${formatChartMonthDay(startAt)}`,
        })
        break
      }
    }
  }

  const currencyCode = ambassadorStatsData?.campaign?.program?.currencyCode || 'USD'

  const TabConfig: readonly [DataConfig<TabOptions>, DataConfig<TabOptions>, DataConfig<TabOptions>] = [
    {
      metricType: 'revenue',
      dataKey: 'revenue',
      currencyCode: currencyCode,
    },
    {
      metricType: 'commission',
      dataKey: 'commission',
      currencyCode: currencyCode,
    },
    {
      metricType: 'roa',
      dataKey: 'roa',
      currencyCode: currencyCode,
    },
  ] as const
  const TimeseriesConfig: readonly [DataConfig<TimeseriesOptions>, DataConfig<TimeseriesOptions>] = [
    {
      metricType: 'revenue',
      dataKey: 'revenue',
      currencyCode: currencyCode,
    },
    {
      metricType: 'commission',
      dataKey: 'commission',
      currencyCode: currencyCode,
    },
  ] as const

  return (
    <TabbedMetricBarChart
      title="Program Revenue Attribution"
      config={TabConfig}
      timeseriesConfig={TimeseriesConfig}
      timeseriesDataFormat={'currency'}
      data={summary}
      previousData={previousSummary}
      timeseries={timeseries}
      loading={loading}
      dateRangeFilter={dateRangeFilter}
      vRefLines={refLines}
      error={!!error}
    />
  )
}

export default CampaignProgramRevenueAttribution

import {
  Box,
  Button,
  Divider,
  InputAdornment,
  Menu,
  OutlinedInput,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React, { useEffect, useState, useRef } from 'react'
import theme from '../loudcrowd-theme'
import { CustomerDetailsFragment } from './operations/customer-details.generated'
import { numberFormat } from '../utils/number-format'
import {
  CurrencyCodeEnum,
  PaymentPeriod,
  PaymentPeriodEnum,
  ProgramPayoutSettingsType,
  ProgramTypeEnum,
} from '../gql-global'
import { useAmbassadorStatsLazyQuery } from './operations/ambassador-stats.generated'
import { SelectionList } from '../components/SelectionPicker/SelectionList'
import { ReactComponent as ChevronDownMinorIcon } from '../icons/chevron-down_minor.svg'
import { ReactComponent as SearchIcon } from '../icons/search.svg'
import { subDays, startOfMonth, subMinutes } from 'date-fns'
import {
  formatUTCDateToClientLocalTime,
  paymentPeriodsDateFormat,
  paymentPeriodsNextPayoutFormat,
} from '../utils/date-format'
import { getPaymentPeriodEndDate } from '../utils/date-range-helper'

type PaymentPeriodSelectOption = {
  label: string
  value: Partial<PaymentPeriod>
}

interface CustomerDetailStatsProps {
  socialAccountId: string
  customer?: CustomerDetailsFragment | null
  isShoppableAmbassador: boolean
}

const CustomerDetailStats = ({
  socialAccountId,
  customer,
  isShoppableAmbassador,
}: CustomerDetailStatsProps): JSX.Element => {
  /**
   * Queries
   */
  const [executeAmbassadorStatsQuery, ambassadorStatsQueryResults] = useAmbassadorStatsLazyQuery()

  /**
   * States
   */
  const [availablePaymentPeriods, setAvailablePaymentPeriodArr] = useState<PaymentPeriodSelectOption[]>([])
  const [selectedPaymentPeriods, setSelectedPaymentPeriods] = useState<PaymentPeriodSelectOption[]>([])
  const [currentPaymentPeriod, setCurrentPaymentPeriod] = useState<PaymentPeriodSelectOption | null>(null)
  const [currencyCode, setCurrencyCode] = useState<CurrencyCodeEnum>(CurrencyCodeEnum.Usd)
  const [nextPaymentDate, setNextPaymentDate] = useState<Date | null>()
  const [programPayoutSettings, setProgramPayoutSettings] = useState<Partial<ProgramPayoutSettingsType> | null>()

  /**
   * Effects
   */

  // Execute initial queries - grab timeseries data for stats by period & get current period pending/awaiting approval data
  useEffect(() => {
    // don't hit the api with empty values for socialAccountId or customerId
    if (!socialAccountId || !customer?.id) return

    const program = customer?.programParticipants
      .filter(pp => pp.program.programType === ProgramTypeEnum.ShoppableAmbassador)
      .sort(
        (a, b) =>
          (b.program.programPayoutSettings?.orderMaturationPeriodDays || 0) -
          (a.program.programPayoutSettings?.orderMaturationPeriodDays || 0),
      )[0]?.program

    setNextPaymentDate(program?.nextPaymentDate)
    setCurrencyCode(program?.currencyCode || CurrencyCodeEnum.Usd)
    setProgramPayoutSettings(program?.programPayoutSettings)

    const paymentPeriodInterval = program?.programPayoutSettings?.period || PaymentPeriodEnum.Monthly
    const maturationPeriodDays = program?.programPayoutSettings?.orderMaturationPeriodDays || 0

    const periodPaymentDate = getPaymentPeriodEndDate(paymentPeriodInterval, 0)
    const periodEndDate = getPaymentPeriodEndDate(paymentPeriodInterval, maturationPeriodDays)
    const periodStartDate = subDays(startOfMonth(new Date()), maturationPeriodDays)
    periodStartDate.setUTCHours(0, 0, 0, 0)

    const currentPaymentPeriod = {
      label: paymentPeriodsDateFormat(periodPaymentDate, 'long'),
      value: {
        id: 'current',
        endAt: periodEndDate,
        startAt: periodStartDate,
        paymentAt: periodPaymentDate,
      },
    }
    setCurrentPaymentPeriod(currentPaymentPeriod)
    setSelectedPaymentPeriods([currentPaymentPeriod])

    if (customer?.paymentPeriods) {
      setAvailablePaymentPeriodArr([
        currentPaymentPeriod,
        ...customer.paymentPeriods.map(paymentPeriod => ({
          label: paymentPeriodsDateFormat(paymentPeriod.paymentAt, 'long'),
          value: paymentPeriod,
        })),
      ])
    }

    void executeAmbassadorStatsQuery({
      variables: {
        socialAccountId: socialAccountId,
        customerId: customer?.id.toString() || '',
        pendingStatsRangeAt: [{ lt: currentPaymentPeriod.value.endAt, gte: currentPaymentPeriod.value.startAt }],
        currencyCode: program?.currencyCode || CurrencyCodeEnum.Usd,
        ...(!!programPayoutSettings?.orderMaturationPeriodDays
          ? { orderMaturationDate: subDays(new Date(), programPayoutSettings.orderMaturationPeriodDays) }
          : {}),
      },
    })
  }, [customer, executeAmbassadorStatsQuery, socialAccountId, programPayoutSettings])

  // When dropdown filtering is used, aggregate data and set stats state
  useEffect(() => {
    if (!socialAccountId || !customer?.id) return

    void executeAmbassadorStatsQuery({
      variables: {
        socialAccountId: socialAccountId,
        customerId: customer?.id.toString() || '',
        pendingStatsRangeAt: selectedPaymentPeriods.map(paymentPeriod => ({
          gte: paymentPeriod.value.startAt,
          lt: paymentPeriod.value.endAt,
        })),
        currencyCode: currencyCode,
        ...(!!programPayoutSettings?.orderMaturationPeriodDays
          ? { orderMaturationDate: subDays(new Date(), programPayoutSettings.orderMaturationPeriodDays) }
          : {}),
      },
    })
  }, [
    customer,
    executeAmbassadorStatsQuery,
    socialAccountId,
    programPayoutSettings,
    selectedPaymentPeriods,
    currencyCode,
  ])

  const loading = ambassadorStatsQueryResults.loading
  const statsData = ambassadorStatsQueryResults.data?.socialAccount?.customer
  const defaultPeriodSelected =
    selectedPaymentPeriods.length === 1 && selectedPaymentPeriods[0] === currentPaymentPeriod

  return (
    <>
      {isShoppableAmbassador && (
        <>
          <Box display="flex" flexWrap="nowrap" mt={6} px={3} width={'100%'}>
            <Box flex={1} ml={2} mr={5}>
              <Typography color="secondary" variant="body2">
                Total Referred Revenue
              </Typography>
              <Typography style={{ marginTop: theme.spacing(1), fontWeight: 400 }} variant="h5">
                {loading && <Skeleton component="span" />}
                {!loading &&
                  numberFormat(statsData?.totalAmbassadorStats?.revenueApproved || 0, {
                    format: 'currency',
                    currencyCode: currencyCode,
                  })}
              </Typography>
            </Box>
            <Box flex={1} ml={2} mr={5}>
              <Typography color="secondary" variant="body2">
                Total Referred Orders
              </Typography>
              <Typography style={{ marginTop: theme.spacing(1), fontWeight: 400 }} variant="h5">
                {loading && <Skeleton component="span" />}
                {!loading && numberFormat(statsData?.totalAmbassadorStats?.ordersApproved || 0)}
              </Typography>
            </Box>
            <Box flex={1} ml={2} mr={5}>
              <Typography color="secondary" variant="body2">
                Commissions Earned
              </Typography>
              <Typography style={{ marginTop: theme.spacing(1), fontWeight: 400 }} variant="h5">
                {loading && <Skeleton component="span" />}
                {!loading &&
                  numberFormat(
                    (statsData?.totalAmbassadorStats?.commissionsPaid || 0) +
                      (statsData?.totalAmbassadorStats?.commissionsPending || 0),
                    { format: 'currency', currencyCode: currencyCode },
                  )}
              </Typography>
            </Box>
          </Box>
          <Box
            mt={6}
            py={3}
            px={3}
            width={'100%'}
            style={{ backgroundColor: theme.palette.secondary.light, borderRadius: '5px' }}
          >
            <Box display={'flex'} flexDirection="row" style={{ alignItems: 'center' }} mx={2} marginBottom={3}>
              <CustomerDetailStatsPaymentPeriodFilter
                availablePaymentPeriods={availablePaymentPeriods}
                setSelectedPaymentPeriods={setSelectedPaymentPeriods}
                currentPaymentPeriod={currentPaymentPeriod}
              />
              <Typography variant="caption" style={{ marginLeft: 20, fontStyle: 'italic', color: '#7C7CA4' }}>
                {defaultPeriodSelected && (
                  <>
                    <Typography variant="subtitle2" style={{ fontSize: '.7rem' }}>
                      Period ends{' '}
                      {!!currentPaymentPeriod.value.endAt &&
                        formatUTCDateToClientLocalTime(subMinutes(currentPaymentPeriod.value.endAt, 1))}
                    </Typography>
                  </>
                )}
                {!defaultPeriodSelected && selectedPaymentPeriods.length === 1 && (
                  <>
                    <Typography variant="subtitle2" style={{ fontSize: '.7rem' }}>
                      Approved orders for
                    </Typography>
                    <Typography variant="subtitle2" style={{ fontSize: '.7rem' }}>
                      {`${paymentPeriodsDateFormat(
                        selectedPaymentPeriods[0]?.value.startAt || new Date(),
                      )} - ${paymentPeriodsDateFormat(selectedPaymentPeriods[0]?.value.endAt || new Date())}`}
                    </Typography>
                  </>
                )}
              </Typography>
            </Box>
            <Box display="flex" flexWrap="nowrap">
              <Box flex={1} ml={2}>
                <Typography style={{ marginTop: theme.spacing(1), fontWeight: 500, fontSize: 15 }} variant="body1">
                  {loading && <Skeleton component="span" />}
                  {!loading &&
                    `${numberFormat(statsData?.filteredAmbassadorStats?.revenueApproved || 0, {
                      format: 'currency',
                      currencyCode: currencyCode,
                    })} revenue`}
                </Typography>
              </Box>
              <Box flex={1} ml={2}>
                <Typography style={{ marginTop: theme.spacing(1), fontWeight: 500, fontSize: 15 }} variant="body1">
                  {loading && <Skeleton component="span" />}
                  {!loading && `${numberFormat(statsData?.filteredAmbassadorStats?.ordersApproved || 0)} orders`}
                </Typography>
              </Box>
              <Box flex={1} ml={2}>
                {selectedPaymentPeriods.length === 1 && selectedPaymentPeriods[0] === currentPaymentPeriod ? (
                  <Box display="flex" flexDirection={'row'} alignItems={'center'}>
                    <Typography style={{ marginTop: theme.spacing(1), fontWeight: 500, fontSize: 15 }} variant="body1">
                      {loading && <Skeleton component="span" />}
                      {!loading &&
                        nextPaymentDate &&
                        `+${numberFormat(statsData?.filteredAmbassadorStats?.commissionsPending || 0, {
                          format: 'currency',
                          currencyCode: currencyCode,
                        })}`}
                    </Typography>
                    <Typography
                      style={{
                        marginTop: theme.spacing(1),
                        fontWeight: 400,
                        fontSize: 15,
                        marginLeft: 5,
                        fontStyle: 'italic',
                      }}
                      variant="body1"
                    >
                      {loading && <Skeleton component="span" />}
                      {!loading && nextPaymentDate && `Next Payout ${paymentPeriodsNextPayoutFormat(nextPaymentDate)}`}
                    </Typography>
                  </Box>
                ) : (
                  <>
                    <Typography style={{ marginTop: theme.spacing(1), fontWeight: 500, fontSize: 15 }} variant="body1">
                      {loading && <Skeleton component="span" />}
                      {!loading &&
                        `${numberFormat(statsData?.filteredAmbassadorStats?.commissionsPaid || 0, {
                          format: 'currency',
                          currencyCode: currencyCode,
                        })} paid`}
                    </Typography>
                    <Typography
                      style={{ marginTop: theme.spacing(1), fontWeight: 400, fontSize: 13, fontStyle: 'italic' }}
                      variant="body1"
                    >
                      {loading && <Skeleton component="span" />}
                      {!loading &&
                        nextPaymentDate &&
                        !!statsData?.filteredAmbassadorStats?.commissionsPending &&
                        statsData?.filteredAmbassadorStats?.commissionsPending > 0 &&
                        `+${numberFormat(statsData?.filteredAmbassadorStats?.commissionsPending, {
                          format: 'currency',
                          currencyCode: currencyCode,
                        })} Next Payout ${paymentPeriodsNextPayoutFormat(nextPaymentDate)}`}
                    </Typography>
                    <Typography
                      style={{
                        marginTop: theme.spacing(1),
                        fontWeight: 400,
                        fontSize: 13,
                        fontStyle: 'italic',
                        color: theme.palette.error.dark,
                      }}
                      variant="body1"
                    >
                      {loading && <Skeleton component="span" />}
                      {!loading &&
                        !!statsData?.filteredAmbassadorStats?.commissionsOwed &&
                        statsData?.filteredAmbassadorStats?.commissionsOwed > 0 &&
                        `+${numberFormat(statsData?.filteredAmbassadorStats?.commissionsOwed, {
                          format: 'currency',
                          currencyCode: currencyCode,
                        })} Owed`}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
            {selectedPaymentPeriods[0] === currentPaymentPeriod && (
              <Box display="flex" flexWrap="nowrap">
                <Box flex={1} ml={2}>
                  <Typography style={{ marginTop: theme.spacing(1), fontWeight: 400 }} variant="caption">
                    {!loading &&
                      `${numberFormat(statsData?.filteredAmbassadorStats?.revenueAwaitingApproval || 0, {
                        format: 'currency',
                        currencyCode: currencyCode,
                      })} awaiting approval`}
                  </Typography>
                </Box>
                <Box flex={1} ml={2}>
                  <Typography style={{ marginTop: theme.spacing(1), fontWeight: 400 }} variant="caption">
                    {!loading &&
                      `${numberFormat(
                        statsData?.filteredAmbassadorStats?.ordersAwaitingApproval || 0,
                      )} awaiting approval`}
                  </Typography>
                </Box>
                <Box flex={1} ml={2}>
                  <Typography style={{ marginTop: theme.spacing(1), fontWeight: 400 }} variant="caption">
                    {!loading &&
                      `${numberFormat(statsData?.filteredAmbassadorStats?.commissionsAwaitingApproval || 0, {
                        format: 'currency',
                        currencyCode: currencyCode,
                      })} awaiting approval`}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </>
      )}
    </>
  )
}

export default CustomerDetailStats

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.primary.main,
      border: '1px solid',
      borderRadius: 100,
      padding: '0px 10px',
      cursor: 'pointer',
      height: '27px',
      fontWeight: 500,
    },
    chevronIcon: {
      marginLeft: '3px',
      width: '17px',
    },
    placeholder: {
      display: 'none',
    },
    select: {
      '& .MuiSelect-select': {
        color: theme.palette.secondary.main,
        opacity: 0.65,
      },
    },
    menu: {
      '& .MuiMenu-paper': {
        minWidth: '250px !important',
        height: '375px',
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        marginTop: '50px',
      },
      '& .MuiList-root': {
        height: '215px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        padding: 0,
      },
      '& ul': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    search: {
      width: '100%',
    },
  }),
)

interface CustomerDetailStatsPaymentPeriodFilterProps {
  availablePaymentPeriods: PaymentPeriodSelectOption[]
  setSelectedPaymentPeriods: React.Dispatch<React.SetStateAction<PaymentPeriodSelectOption[]>>
  currentPaymentPeriod: PaymentPeriodSelectOption | null
}

const CustomerDetailStatsPaymentPeriodFilter = ({
  availablePaymentPeriods,
  setSelectedPaymentPeriods,
  currentPaymentPeriod,
}: CustomerDetailStatsPaymentPeriodFilterProps) => {
  const defaultDateRangeLabel = 'Current Period'
  const classes = useStyles()
  const filterRef = useRef(null)

  /**
   * States
   */
  const [menuOpen, setMenuOpen] = useState(false)
  const [selectionListIds, setSelectionListIds] = useState<ReadonlySet<string>>()

  const [filteredPaymentPeriods, setFilteredPaymentPeriods] =
    useState<PaymentPeriodSelectOption[]>(availablePaymentPeriods)
  const [dateFilterLabel, setDateFilterLabel] = useState(defaultDateRangeLabel)
  const [searchText, setSearchText] = useState<string>('')

  /**
   * Effect
   */
  useEffect(() => {
    if (searchText === '') setFilteredPaymentPeriods(availablePaymentPeriods)
    const lowerSearchText = (searchText || '').toLowerCase()
    setFilteredPaymentPeriods(
      availablePaymentPeriods.filter(paymentPeriod => paymentPeriod.label.toLowerCase().includes(lowerSearchText)),
    )
  }, [searchText, availablePaymentPeriods])

  useEffect(() => {
    if (selectionListIds?.size === 0) {
      setSelectedPaymentPeriods(currentPaymentPeriod ? [currentPaymentPeriod] : [])
      setDateFilterLabel(defaultDateRangeLabel)
      return
    }
    let selectedPaymentPeriods = availablePaymentPeriods.filter(
      paymentPeriod => paymentPeriod.value.id && selectionListIds?.has(paymentPeriod.value.id),
    )

    if (selectedPaymentPeriods.length === 1) {
      setDateFilterLabel(selectedPaymentPeriods[0]?.label || '')
    } else if (selectedPaymentPeriods.length > 1) {
      setDateFilterLabel('Multiple payment periods')
    } else {
      setDateFilterLabel(defaultDateRangeLabel)
    }
    setSelectedPaymentPeriods(selectedPaymentPeriods)
  }, [selectionListIds, availablePaymentPeriods, currentPaymentPeriod, setSelectedPaymentPeriods])

  useEffect(() => {
    setFilteredPaymentPeriods(availablePaymentPeriods)
    if (currentPaymentPeriod?.value.id) {
      setSelectionListIds(new Set([currentPaymentPeriod.value.id]))
    }
  }, [availablePaymentPeriods, currentPaymentPeriod])

  return (
    <>
      <Button
        className={classes.button}
        onClick={() => {
          setMenuOpen(!menuOpen)
        }}
        ref={filterRef}
      >
        {dateFilterLabel} <ChevronDownMinorIcon className={classes.chevronIcon} />
      </Button>
      <Menu
        className={classes.menu}
        placeholder="Current Period"
        open={menuOpen}
        onClose={() => {
          setMenuOpen(false)
        }}
        anchorEl={filterRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        style={{ flex: 1, width: '100%' }}
      >
        <Box style={{ width: '100%', textAlign: 'center' }} mb={2}>
          <Typography style={{ fontSize: '.8rem', fontWeight: 600, margin: '10px 0' }}>Payment Dates</Typography>
          <Divider />
        </Box>
        <Box paddingX={2} width={'100%'}>
          <OutlinedInput
            type="text"
            className={classes.search}
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            fullWidth
            autoFocus
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon width={16} height={16} />
              </InputAdornment>
            }
          />
          {filteredPaymentPeriods.length === 0 && (
            <Typography style={{ paddingTop: '20px', paddingLeft: '10px', position: 'absolute' }}>
              No payment periods to display
            </Typography>
          )}
        </Box>
        <SelectionList
          options={filteredPaymentPeriods.map(paymentPeriod => ({
            id: paymentPeriod.value.id ?? '',
            label: paymentPeriod.label,
          }))}
          selectedOptions={selectionListIds}
          onChangeOptions={selectedValues => {
            setSelectionListIds(selectedValues)
            setMenuOpen(false)
          }}
          toggleReset={false}
          onReset={() => {
            setSearchText('')
            setDateFilterLabel(defaultDateRangeLabel)
            return new Set<string>()
          }}
          styles={{ width: '100%' }}
        />
      </Menu>
    </>
  )
}

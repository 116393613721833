import React, { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
  IgMediaPostType,
  MentionTypes,
  ProgramTierBuffer,
  ProgramTierFulfillmentType,
  ProgramTierGroupStatus,
  ProgramTierInput,
  RewardType,
  SocialPlatformEnum,
} from '../../../gql-global'
import { POST_COUNT_CRITERIA_DEFAULT_VALUES, RewardAutomationTypes } from './constants'
import { IgMentionTypes } from '../constants'
import { ProgramTier, ProgramTierGroup } from '../ProgramActivityTimeline'
import { isTypeName } from '../../../types/utility'
import {
  ProgramActivitySocialAccountsQuery,
  useProgramActivitySocialAccountsQuery,
} from './operations/program-activity-social-accounts.generated'
import { deepClone, isEqual } from '../../../utils/objects'

export interface PostCountCriteriaType {
  tierId?: string
  totalPosts?: number
  isBuffered?: boolean
  bufferDateRange?: ProgramTierBuffer
  selectedPostTypes?: Set<IgMediaPostType | SocialPlatformEnum.Tiktok>
  selectedMentionTypes?: Set<IgMentionTypes>
}

export interface MultiTierCriteriaType {
  postCountCriteria?: PostCountCriteriaType
  reward?: Partial<RewardType>
}

export type ProgramActivityBuilderValueTypes = {
  id?: string
  status?: ProgramTierGroup['status']
  reward?: Partial<RewardType>
  occasion?: RewardAutomationTypes
  socialAccountIds?: string[]
  fulfillmentLimit?: number
  singlePostCriteria?: PostCountCriteriaType
  multiTierCriteria?: MultiTierCriteriaType[]
}

export type ProgramActivityBuilderContextType = {
  values: ProgramActivityBuilderValueTypes
  socialAccounts?: ProgramActivitySocialAccountsQuery['socialAccounts']
  includesInstagram: boolean
  includesTikTok: boolean
  isReorderingTiers: boolean
  needsArchiveAndCreateNew: boolean
  buildCriteria: (criteria?: PostCountCriteriaType) => PostCountCriteriaType
  isFormValid: () => boolean
  setSingleReward: (reward?: Partial<RewardType>) => void
  setOccasion: (occasion?: RewardAutomationTypes) => void
  setSocialAccountIds: (socialAccountIds?: string[]) => void
  setFulfillmentLimit: (fulfillmentLimit?: number) => void
  setSinglePostCriteria: (criteria?: PostCountCriteriaType) => void
  setMultiTierCriteria: (criteria?: MultiTierCriteriaType[]) => void
  handleAddTierCriteria: () => void
  handleRemoveTierCriteria: (index: number) => void
  handleInitialData: (data?: ProgramTierGroup) => void
  buildActivityBuilderDataToSave: () => ProgramTierInput[]
}

export const ProgramActivityBuilderContext = createContext<ProgramActivityBuilderContextType>({
  values: {},
  socialAccounts: [],
  includesInstagram: true,
  includesTikTok: true,
  isReorderingTiers: false,
  needsArchiveAndCreateNew: false,
  buildCriteria: () => ({}),
  isFormValid: () => false,
  setSingleReward: () => undefined,
  setOccasion: () => undefined,
  setSocialAccountIds: () => undefined,
  setFulfillmentLimit: () => undefined,
  setSinglePostCriteria: () => undefined,
  setMultiTierCriteria: () => undefined,
  handleAddTierCriteria: () => undefined,
  handleRemoveTierCriteria: () => undefined,
  handleInitialData: () => undefined,
  buildActivityBuilderDataToSave: () => [],
})

export const ProgramActivityBuilderProvider: FC = ({ children }) => {
  const [values, setValues] = useState<ProgramActivityBuilderValueTypes>({})
  const [isLoadingInitialData, setIsLoadingInitialData] = useState<boolean>(false)
  const [isReorderingTiers, setIsReorderingTiers] = useState<boolean>(false)
  const [needsArchiveAndCreateNew, setNeedsArchiveAndCreateNew] = useState<boolean>(false)
  const [initialData, setInitialData] = useState<ProgramActivityBuilderValueTypes>({})

  const { data } = useProgramActivitySocialAccountsQuery()
  const socialAccounts = useMemo(() => data?.socialAccounts ?? [], [data?.socialAccounts])

  const includesSocialAccountType = useCallback(
    (type: 'IGSocialAccount' | 'TTSocialAccount') => {
      return values?.socialAccountIds?.length
        ? values.socialAccountIds.some(id =>
            isTypeName(socialAccounts.find(sa => sa.id === id) ?? { __typename: '' }, type),
          )
        : true
    },
    [socialAccounts, values.socialAccountIds],
  )

  const includesInstagram = useMemo(() => includesSocialAccountType('IGSocialAccount'), [includesSocialAccountType])
  const includesTikTok = useMemo(() => includesSocialAccountType('TTSocialAccount'), [includesSocialAccountType])

  const buildCriteria = (criteria: PostCountCriteriaType = {}) => {
    const defaultPostCriteriaValues = deepClone(POST_COUNT_CRITERIA_DEFAULT_VALUES)
    let result: Partial<PostCountCriteriaType> = { ...criteria, ...defaultPostCriteriaValues }

    if (isLoadingInitialData) {
      setIsLoadingInitialData(false)
      result = {
        ...criteria,
        selectedPostTypes: includesTikTok
          ? criteria.selectedPostTypes?.add(SocialPlatformEnum.Tiktok)
          : criteria.selectedPostTypes,
      }
    }

    if (includesTikTok && !includesInstagram) {
      result = {
        tierId: criteria.tierId ?? undefined,
        isBuffered: criteria.isBuffered ?? false,
        bufferDateRange: criteria.bufferDateRange ?? ProgramTierBuffer.OneDay,
        totalPosts: criteria.totalPosts ?? undefined,
      }
    }

    if (includesInstagram && !includesTikTok) {
      result = { ...defaultPostCriteriaValues, ...criteria }
      result.selectedPostTypes?.delete(SocialPlatformEnum.Tiktok)
    }

    return result
  }

  useEffect(() => {
    const defaultPostCriteriaValues = deepClone(POST_COUNT_CRITERIA_DEFAULT_VALUES)
    const commonValues = {
      occasion: values.occasion,
      socialAccountIds: values.socialAccountIds,
      fulfillmentLimit: values.fulfillmentLimit,
    }

    switch (values.occasion) {
      case RewardAutomationTypes.SINGLE_TIER_MONTHLY:
        setValues({
          ...commonValues,
          reward: values.reward,
          singlePostCriteria: values.singlePostCriteria
            ? buildCriteria(values.singlePostCriteria)
            : defaultPostCriteriaValues,
        })
        break
      case RewardAutomationTypes.MULTI_TIER_MONTHLY:
        setValues({
          ...commonValues,
          multiTierCriteria: values.multiTierCriteria?.length
            ? values.multiTierCriteria.map(criteria => ({
                reward: criteria.reward,
                postCountCriteria: buildCriteria(criteria.postCountCriteria),
              }))
            : [{ postCountCriteria: defaultPostCriteriaValues }, { postCountCriteria: defaultPostCriteriaValues }],
        })
        break
    }
    // we only want to run this when the occasion or socialAccountIds values change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.occasion, values.socialAccountIds])

  useEffect(() => {
    if (
      initialData?.id ||
      initialData?.status === ProgramTierGroupStatus.Active ||
      initialData?.status === ProgramTierGroupStatus.Paused
    ) {
      const socialAccountsChanged =
        values?.socialAccountIds?.length !== initialData?.socialAccountIds?.length ||
        !!values?.socialAccountIds?.some(id => !initialData?.socialAccountIds?.includes(id))

      const postCriteriaChange =
        !isEqual(values.singlePostCriteria, initialData.singlePostCriteria) ||
        values.multiTierCriteria?.length !== initialData.multiTierCriteria?.length ||
        !!values.multiTierCriteria?.some((criteria, index) => {
          return !isEqual(criteria.postCountCriteria, initialData.multiTierCriteria?.[index]?.postCountCriteria)
        })

      setNeedsArchiveAndCreateNew(socialAccountsChanged || postCriteriaChange)
    }
  }, [values, initialData])

  const isFormValid = () => {
    const isPostCriteriaValid =
      (!!values.singlePostCriteria?.totalPosts && !!values?.reward?.id) ||
      values.multiTierCriteria?.every(criteria => {
        return !!criteria?.postCountCriteria?.totalPosts && !!criteria?.reward?.id
      })

    return !!values?.occasion && !!values?.socialAccountIds?.length && !!isPostCriteriaValid
  }

  const setSingleReward = (reward?: Partial<RewardType>) => {
    setValues({ ...values, reward })
  }

  const setOccasion = (occasion?: RewardAutomationTypes) => {
    setValues({ ...values, occasion })
  }

  const setSocialAccountIds = (socialAccountIds?: string[]) => {
    setValues({ ...values, socialAccountIds })
  }

  const setFulfillmentLimit = (fulfillmentLimit?: number) => {
    setValues({ ...values, fulfillmentLimit })
  }

  const setSinglePostCriteria = (criteria?: PostCountCriteriaType) => {
    setValues({ ...values, singlePostCriteria: criteria })
  }

  const setMultiTierCriteria = (criteria?: MultiTierCriteriaType[]) => {
    const needsReordering = !!criteria?.some((current, index) => {
      const currentTotalPosts = current.postCountCriteria?.totalPosts ?? 0
      const nextTotalPosts = criteria?.[index + 1]?.postCountCriteria?.totalPosts
      return nextTotalPosts && currentTotalPosts > nextTotalPosts
    })

    if (needsReordering) {
      setIsReorderingTiers(true)
      const orderedCriteria = criteria?.sort((a, b) => {
        if (!a?.postCountCriteria?.totalPosts) return 1
        if (!b?.postCountCriteria?.totalPosts) return -1
        return a.postCountCriteria.totalPosts - b.postCountCriteria.totalPosts
      })
      setValues({ ...values, multiTierCriteria: orderedCriteria })
      setTimeout(() => setIsReorderingTiers(false), 300)
    } else {
      setValues({ ...values, multiTierCriteria: criteria })
    }
  }

  const handleAddTierCriteria = () => {
    const newCriteria = { postCountCriteria: buildCriteria() }
    if (!values?.multiTierCriteria) {
      setMultiTierCriteria([newCriteria])
      return
    }
    setMultiTierCriteria([...values?.multiTierCriteria, newCriteria])
  }

  const handleRemoveTierCriteria = (index: number) => {
    setMultiTierCriteria(values?.multiTierCriteria?.filter((_, key) => key !== index))
  }

  const handleInitialData = (data?: ProgramTierGroup) => {
    setIsLoadingInitialData(true)
    const isSingleTierAutomation = data?.tiers?.length === 1
    const firstTier = data?.tiers?.[0]
    const commonValues = {
      fulfillmentLimit: firstTier?.fulfillmentLimit ?? undefined,
      socialAccountIds: (firstTier?.socialAccounts || socialAccounts).map(sa => sa.id),
    }
    const includeInstagramPostOptions = (tier?: Partial<ProgramTier>) =>
      tier?.socialAccounts?.some(sa => isTypeName(sa, 'IGSocialAccount'))
        ? {
            selectedMentionTypes: new Set(
              (tier?.mentionTypes as unknown as IgMentionTypes[])?.filter(
                mentionType => mentionType !== IgMentionTypes.Story,
              ),
            ),
            selectedPostTypes: new Set(tier?.postTypes),
          }
        : {}

    let newValues: ProgramActivityBuilderValueTypes

    if (isSingleTierAutomation) {
      newValues = {
        ...commonValues,
        occasion: RewardAutomationTypes.SINGLE_TIER_MONTHLY,
        reward: firstTier?.reward ?? undefined,
        singlePostCriteria: {
          ...includeInstagramPostOptions(firstTier),
          tierId: firstTier?.id,
          isBuffered: !!firstTier?.triggerBuffer,
          bufferDateRange: firstTier?.triggerBuffer ?? ProgramTierBuffer.OneDay,
          totalPosts: firstTier?.triggerQuantity ?? 0,
        },
      }
    } else {
      newValues = {
        ...commonValues,
        occasion: RewardAutomationTypes.MULTI_TIER_MONTHLY,
        multiTierCriteria:
          data?.tiers?.map(tier => ({
            reward: tier.reward ?? undefined,
            postCountCriteria: {
              ...includeInstagramPostOptions(tier),
              tierId: tier.id,
              isBuffered: !!tier.triggerBuffer,
              bufferDateRange: tier.triggerBuffer ?? ProgramTierBuffer.OneDay,
              totalPosts: tier.triggerQuantity ?? 0,
            },
          })) ?? [],
      }
    }
    setValues(newValues)
    setInitialData({ id: data?.id, status: data?.status, ...newValues })
  }

  const buildActivityBuilderDataToSave = () => {
    const parseValues = (criteria?: PostCountCriteriaType, reward?: Partial<RewardType>) => {
      const postTypes = Array.from(criteria?.selectedPostTypes ?? []).filter(
        value => value !== SocialPlatformEnum.Tiktok,
      )
      const selectedMentionTypes = criteria?.selectedMentionTypes ?? new Set()
      const mentionTypes = Array.from(
        postTypes.includes(IgMediaPostType.Story)
          ? selectedMentionTypes.add(IgMentionTypes.Story)
          : selectedMentionTypes,
      )

      return {
        fulfillmentLimit: values.fulfillmentLimit ?? 0,
        fulfillmentType: ProgramTierFulfillmentType.Monthly,
        socialAccountIds: values?.socialAccountIds ?? [],
        id: criteria?.tierId,
        rewardId: reward?.id ? reward.id.toString() : '',
        mentionTypes: mentionTypes.length ? (mentionTypes as unknown as MentionTypes[]) : undefined,
        postTypes: postTypes.length ? (postTypes as unknown as IgMediaPostType[]) : undefined,
        triggerBuffer: criteria?.isBuffered ? criteria.bufferDateRange : undefined,
        triggerQuantity: criteria?.totalPosts ?? 0,
      }
    }

    switch (values.occasion) {
      case RewardAutomationTypes.SINGLE_TIER_MONTHLY:
        const criteria = values?.singlePostCriteria

        return [parseValues(criteria, values.reward)]

      case RewardAutomationTypes.MULTI_TIER_MONTHLY:
        return (
          values.multiTierCriteria?.map(item => {
            const criteria = item?.postCountCriteria
            const reward = item?.reward

            return parseValues(criteria, reward)
          }) ?? []
        )
    }
    return []
  }

  return (
    <ProgramActivityBuilderContext.Provider
      value={{
        values,
        socialAccounts,
        includesTikTok,
        includesInstagram,
        isReorderingTiers,
        needsArchiveAndCreateNew,
        buildCriteria,
        isFormValid,
        setSingleReward,
        setOccasion,
        setSocialAccountIds,
        setFulfillmentLimit,
        setSinglePostCriteria,
        setMultiTierCriteria,
        handleAddTierCriteria,
        handleRemoveTierCriteria,
        handleInitialData,
        buildActivityBuilderDataToSave,
      }}
    >
      {children}
    </ProgramActivityBuilderContext.Provider>
  )
}

export const useProgramActivityBuilder = (): ProgramActivityBuilderContextType =>
  useContext(ProgramActivityBuilderContext)

import React, { useState } from 'react'
import { Box, Button } from '@material-ui/core'
import {
  IntegrationRow_RefersionIntegration_Fragment,
  IntegrationRowFragmentDoc,
} from './operations/integration-row.generated'
import TableHeader, { TableHeaderCell } from './TableHeader'
import TableRow, { TableRowCell } from './TableRow'
import { Skeleton } from '@material-ui/lab'
import { useCreateIntegrationMutation } from './operations/create-integration.generated'
import { useUpdateIntegrationMutation } from './operations/update-integration.generated'
import { useTestIntegrationMutation } from './operations/test-integration.generated'
import AddEditCustomerIntegration, { AddEditCustomerIntegrationFormFields } from './AddEditCustomerIntegration'

const typeNameMap: Record<IntegrationRow_RefersionIntegration_Fragment['__typename'], string> = {
  RefersionIntegration: 'Refersion',
}

interface CustomerIntegrationsProps {
  accountId?: string | null
  integrations: IntegrationRow_RefersionIntegration_Fragment[]
  loading?: false
}

type AddEditState = { open: true; editingIntegration?: IntegrationRow_RefersionIntegration_Fragment } | { open: false }

function CustomerIntegrations({ accountId, loading, integrations }: CustomerIntegrationsProps): React.ReactElement {
  const [addEditState, setAddEditState] = useState<AddEditState>({ open: false })
  const [addEditError, setAddEditError] = useState<Error | null>(null)
  const [testState, setTestState] = useState<string | null>(null)
  const [createIntegration] = useCreateIntegrationMutation({
    update(cache, { data }): void {
      const customerIntegration = data?.createIntegration?.integration
      if (!customerIntegration) {
        return
      }
      cache.modify({
        id: cache.identify({ __typename: 'AccountType', id: accountId }),
        fields: {
          integrations(existing = []) {
            const newRef = cache.writeFragment({
              data: customerIntegration,
              fragment: IntegrationRowFragmentDoc,
            })
            return [...existing, newRef]
          },
        },
      })
    },
  })
  const [updateIntegration] = useUpdateIntegrationMutation()
  const [testIntegration, { error: testError, data: testData, loading: testing }] = useTestIntegrationMutation()

  async function handleAddEdit(values: AddEditCustomerIntegrationFormFields): Promise<void> {
    if (!addEditState.open) {
      return
    }
    try {
      if (addEditState.editingIntegration) {
        await updateIntegration({
          variables: {
            ...addEditState.editingIntegration,
            ...values,
          },
        })
      } else {
        await createIntegration({
          variables: {
            ...values,
          },
        })
      }
      handleClose()
      return
    } catch (e) {
      if (e instanceof Error) {
        setAddEditError(e)
      }
    }
  }

  function handleClose() {
    setAddEditError(null)
    setAddEditState({ open: false })
  }

  async function handleTest(id: string) {
    setTestState(id)
    await testIntegration({
      variables: {
        id,
      },
    })
  }

  return (
    <div>
      {(loading || !!integrations.length) && (
        <TableHeader>
          <TableHeaderCell>Integration</TableHeaderCell>
          <TableHeaderCell>API Key</TableHeaderCell>
          <TableHeaderCell />
          <TableHeaderCell />
          <TableHeaderCell />
          <TableHeaderCell />
        </TableHeader>
      )}
      {loading && (
        <>
          <TableRow>
            <TableRowCell>
              <Skeleton width={200} />
            </TableRowCell>
            <TableRowCell>
              <Skeleton width={200} />
            </TableRowCell>
            <TableRowCell />
            <TableRowCell />
            <TableRowCell />
            <TableRowCell />
          </TableRow>
          <TableRow>
            <TableRowCell>
              <Skeleton width={200} />
            </TableRowCell>
            <TableRowCell>
              <Skeleton width={200} />
            </TableRowCell>
            <TableRowCell />
            <TableRowCell />
            <TableRowCell />
            <TableRowCell />
          </TableRow>
        </>
      )}
      {integrations.map(i => (
        <TableRow key={i.id}>
          <TableRowCell>
            {typeNameMap[i.__typename]} - {i.name}
          </TableRowCell>
          <TableRowCell minWidth={0} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            {i.key}
          </TableRowCell>
          <TableRowCell />
          <TableRowCell />
          <TableRowCell textAlign="right">
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => setAddEditState({ open: true, editingIntegration: i })}
            >
              Edit
            </Button>
          </TableRowCell>
          <TableRowCell display="flex" justifyContent="flex-end" alignItems="center">
            <Box mr={2}>
              {testState === i.id && (
                <>
                  {testing && 'Testing...'}
                  {!testing && (testData?.testIntegration?.ok ? 'Success!' : 'API Key Failed')}
                  {!testing && testError?.message}
                </>
              )}
            </Box>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => handleTest(i.id)}
              disabled={testing && testState === i.id}
            >
              Test
            </Button>
          </TableRowCell>
        </TableRow>
      ))}
      <Button size="large" variant="contained" color="primary" onClick={() => setAddEditState({ open: true })}>
        Add Integration
      </Button>
      <AddEditCustomerIntegration
        open={addEditState.open}
        onCancel={handleClose}
        onSubmit={handleAddEdit}
        error={addEditError}
        existing={addEditState.open ? addEditState.editingIntegration : undefined}
      />
    </div>
  )
}

export default CustomerIntegrations

import React from 'react'
import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import Page from '../Page'
import RouterTabs, { RouterTab } from '../components/RouterTabs'
import withAuthorization from '../withAuthorization'
import { MESSAGE_ACTIVITY_ROUTE, MESSAGE_ROUTE } from './routes'
import MessageList from './MessagesList'
import ActivityList from './ActivityList'
import useTitle from '../utils/use-title'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabs: {
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
  }),
)

const Messages: React.FC = () => {
  const { path, url } = useRouteMatch()
  const classes = useStyles()
  useTitle('Messages')
  return (
    <Page>
      <Box mx={10}>
        <Box mb={5}>
          <Typography variant="h5">Messages</Typography>
        </Box>
        <RouterTabs className={classes.tabs} indicatorColor="primary" textColor="primary">
          <RouterTab label="Manage" value={url} replace />
          <RouterTab label="Activity" value={`${url}/activity`} replace />
        </RouterTabs>
        <Box mt={5}>
          <Switch>
            <Route path={path} component={MessageList} exact />
            <Route path={MESSAGE_ACTIVITY_ROUTE.path} component={ActivityList} exact />
          </Switch>
        </Box>
      </Box>
    </Page>
  )
}

export default withAuthorization(MESSAGE_ROUTE)(Messages)

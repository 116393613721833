import React, { useState, useEffect, useCallback } from 'react'
import { makeStyles, createStyles, MenuProps, Box, Divider, Checkbox } from '@material-ui/core'
import { ReactComponent as NoMediaImage } from '../../../images/no-media.svg'
import {
  CampaignProgramCollectionsQuery,
  useCampaignProgramCollectionsLazyQuery,
} from './operations/campaign-program-collections.generated'
import {
  CampaignProgramProductsQuery,
  useCampaignProgramProductsLazyQuery,
} from './operations/campaign-program-products.generated'
import { EcommDiscountAppliesToEnum, IntegrationType } from '../../../gql-global'
import MultiSelectSearch from '../../../components/MultiSelectSearch'

const useStyles = makeStyles(theme =>
  createStyles({
    wrapper: {
      minWidth: 465,
    },
    searchInput: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    button: {
      borderRadius: 5,
    },
    image: {
      width: 40,
      height: 40,
      marginRight: theme.spacing(2),
      padding: 5,
    },
    listItem: {
      cursor: 'pointer',
    },
  }),
)

const PAGE_SIZE = 15

export type OptionType = {
  id: string
  title?: string | null
  imageSrc?: string | null
}

type ProductCollectionPickerMenuProps = {
  campaignId?: string
  open: boolean
  anchorEl?: MenuProps['anchorEl']
  onCancel(): void
  onAdd(params: OptionType[]): void
  pickerType: EcommDiscountAppliesToEnum
  selectedItems: OptionType[]
  confirmButtonText?: string
  integrationType?: IntegrationType
}

const ProductCollectionPickerMenu: React.FC<ProductCollectionPickerMenuProps> = props => {
  const { open, anchorEl, pickerType, campaignId, selectedItems, onCancel, onAdd, confirmButtonText, integrationType } =
    props
  const classes = useStyles()
  const [selectedOptions, setSelectedOptions] = useState<OptionType[]>(selectedItems)

  const [executeProductQuery, { data: productData, fetchMore: fetchMoreProducts }] =
    useCampaignProgramProductsLazyQuery()
  const [executeCollectionQuery, { data: collectionData, fetchMore: fetchMoreCollections }] =
    useCampaignProgramCollectionsLazyQuery()

  const loadData = useCallback(
    async (searchText?: string) => {
      if (!campaignId) return
      const variables = { id: campaignId || '', limit: PAGE_SIZE, searchText: searchText || '' }
      pickerType === EcommDiscountAppliesToEnum.Product
        ? executeProductQuery({ variables })
        : executeCollectionQuery({ variables: { ...variables, activeFilter: false } })
    },
    [pickerType, campaignId, executeCollectionQuery, executeProductQuery],
  )

  const handleOnClick = async (option: OptionType) => {
    if (!!selectedOptions.find(o => o.id === option?.id)) {
      setSelectedOptions(selectedOptions.filter(o => o.id !== option.id))
    } else {
      setSelectedOptions([...selectedOptions, option])
    }
  }

  const handleLoadMore = async (searchText: string) => {
    const variables = { id: campaignId || '', limit: PAGE_SIZE, cursor: options?.[options?.length - 1]?.id, searchText }
    await (pickerType === EcommDiscountAppliesToEnum.Product
      ? fetchMoreProducts({
          variables,
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev

            return {
              campaign: {
                ...prev.campaign,
                program: {
                  ...prev.campaign?.program,
                  products: [
                    ...(prev.campaign?.program?.products || []),
                    ...(fetchMoreResult.campaign?.program?.products || []),
                  ],
                },
              },
            } as CampaignProgramProductsQuery
          },
        })
      : fetchMoreCollections({
          variables,
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev

            return {
              campaign: {
                ...prev.campaign,
                program: {
                  ...prev.campaign?.program,
                  collections: [
                    ...(prev.campaign?.program?.collections || []),
                    ...(fetchMoreResult.campaign?.program?.collections || []),
                  ],
                },
              },
            } as CampaignProgramCollectionsQuery
          },
        }))
  }

  useEffect(() => {
    setSelectedOptions([])
  }, [pickerType])

  useEffect(() => {
    loadData()
  }, [loadData])

  useEffect(() => {
    setSelectedOptions(selectedItems)
  }, [selectedItems])

  const options =
    pickerType === EcommDiscountAppliesToEnum.Product
      ? productData?.campaign?.program?.products
      : collectionData?.campaign?.program?.collections

  return (
    <MultiSelectSearch<OptionType>
      label={
        pickerType === EcommDiscountAppliesToEnum.Product
          ? 'Products'
          : integrationType === IntegrationType.CommerceCloud
          ? 'Categories'
          : 'Collections'
      }
      options={options || []}
      loadOptions={loadData}
      loadMoreOptions={handleLoadMore}
      anchorEl={anchorEl}
      open={open}
      onCancel={onCancel}
      handleOnClick={handleOnClick}
      handleConfirm={onAdd}
      confirmButtonText={confirmButtonText}
      optionTemplate={(option: OptionType) => {
        return (
          <>
            <Box display="flex" alignItems="center" pl={2}>
              <Checkbox checked={(option && !!selectedOptions.find(o => o.id === option?.id)) || false} />
              {option?.imageSrc ? (
                <img className={classes.image} src={option.imageSrc} alt="preview" />
              ) : (
                <NoMediaImage className={classes.image} />
              )}
              {option?.title}
            </Box>
            <Divider />
          </>
        )
      }}
      selectedItems={selectedOptions}
    />
  )
}

export default ProductCollectionPickerMenu

import { format } from 'date-fns/fp'
import {
  differenceInDays,
  subDays,
  isSameMonth,
  isToday,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  isFuture,
} from 'date-fns'
import { TimeDataPoint, ChartDataPoint, vRefLine, ChartVRefPoint } from './types'
import { TooltipPayload, TooltipProps } from 'recharts'
import { ChartConfigProps } from './types'
import { Theme } from '@material-ui/core'
import { numberFormat, NumberFormatTypes } from '../../utils/number-format'
import { DEFAULT_COLOR } from './constants'

const dateFormat = 'MMM dd'
const monthFormat = 'MMM yyyy'
const formatDate = format(dateFormat)
const formatMonth = format(monthFormat)
const formatDayOfMonth = format('dd')

export function formatChartMonthDay(date: Date): string {
  return formatDate(date)
}

export function convertTimeData<K extends string, T extends TimeDataPoint<K>>(data: T[]): ChartDataPoint<K, T>[] {
  return data.map(d => {
    let s = formatDate(d.startDate)
    const endDay = subDays(d.endDate, 1)
    let partial = isToday(endDay) || isFuture(d.endDate)
    const diffInDays = differenceInDays(endDay, d.startDate)
    if (diffInDays > 0 && diffInDays <= 6) {
      if (isSameMonth(d.startDate, endDay)) {
        s = `${s} - ${formatDayOfMonth(endDay)}`
      } else {
        s = `${s} - ${formatDate(endDay)}`
      }
      partial = partial || diffInDays < 6
    } else if (diffInDays > 6) {
      s = formatMonth(d.startDate)
      partial = partial || !isFirstDayOfMonth(d.startDate) || !isLastDayOfMonth(endDay)
    }
    return {
      ...d,
      dateRange: s,
      partial: partial ? 0 : null,
      diffInDays: diffInDays,
    }
  })
}

export function convertVRefLines(data: vRefLine[]): ChartVRefPoint[] {
  return data.map(d => {
    let s = formatDate(d.x.startDate)
    const endDay = subDays(d.x.endDate, 1)
    const diffInDays = differenceInDays(endDay, d.x.startDate)
    if (diffInDays > 0 && diffInDays <= 6) {
      if (isSameMonth(d.x.startDate, endDay)) {
        s = `${s} - ${formatDayOfMonth(endDay)}`
      } else {
        s = `${s} - ${formatDate(endDay)}`
      }
    } else if (diffInDays > 6) {
      s = formatMonth(d.x.startDate)
    }
    return {
      ...d,
      dateRange: s,
    }
  })
}

export function chartValueFormatter(
  v: number | string,
  props: TooltipPayload,
  dataFormat: NumberFormatTypes | undefined,
  currencyCode?: string,
): string[] {
  let formattedValue = [numberFormat(Number(v), { format: dataFormat, currencyCode: currencyCode })]
  if (props.payload.partial === 0) {
    if (props.payload.projected) {
      formattedValue = [numberFormat(Number(v), { format: dataFormat }), ' (Projected)']
    }
    if (props.payload.actualValue) {
      formattedValue.push(', ')
      formattedValue.push(numberFormat(Number(props.payload.actualValue), { format: dataFormat }))
      formattedValue.push(' (Actual)')
    }
  }
  return formattedValue
}

export function buildTooltipProps({
  theme,
  label,
  dataFormat,
  color = DEFAULT_COLOR,
}: ChartConfigProps & { theme: Theme }): TooltipProps {
  const colorDict = theme.palette[color]

  return {
    label: label,
    formatter(v, n, props) {
      let formattedValue = [numberFormat(Number(v), { format: dataFormat })]
      if (props.payload.partial === 0) {
        if (props.payload.projected) {
          formattedValue = [numberFormat(Number(v), { format: dataFormat }), ' (Projected)']
        }
        if (props.payload.actualValue) {
          formattedValue.push(', ')
          formattedValue.push(numberFormat(Number(props.payload.actualValue), { format: dataFormat }))
          formattedValue.push(' (Actual)')
        }
      }
      return [formattedValue, label]
    },
    animationDuration: 500,
    contentStyle: {
      border: 'none',
      backgroundColor: colorDict.main,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[9],
      color: theme.palette.common.white,
      margin: 0,
      padding: '8px 16px',
    },
    wrapperStyle: { height: '58px', minWidth: '120px' },
    itemStyle: {
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'capitalize',
      margin: 0,
      padding: 0,
    },
    labelStyle: { color: colorDict.light },
    separator: ' ',
  }
}

import React, { useRef, useState } from 'react'
import { Box, Button, Card, createStyles, Grid, makeStyles, Menu, Typography, useTheme } from '@material-ui/core'
import MessageComponent from './MessageComponent'
import { Skeleton } from '@material-ui/lab'
import { secondary, white } from '../loudcrowd-theme'
import { ReactComponent as NoPostsImage } from '../images/no-posts.svg'
import { ReactComponent as ChevronDownIcon } from '../icons/chevron-down_minor.svg'
import ContainerEmptyState from '../components/ContainerEmptyState/ContainerEmptyState'
import ContainerError from '../components/ContainerError'
import { NumberParam, useQueryParam } from 'use-query-params'
import useFilterParams, { Filters } from './use-filter-params'
import { useMessagesUserDataQuery } from './operations/query-messages-user-data.generated'
import {
  MessageTemplatesListDocument,
  useMessageTemplatesListQuery,
} from './operations/query-message-templates-list.generated'
import { useUpdateMessageAutomationMutation } from './operations/update-message-automation.generated'
import { useCreateMessageTemplateMutation } from './operations/create-message-template.generated'
import { CodesLevel, MessageTemplateFilterInput, MessageTemplateInput, RewardTypeEnum } from '../gql-global'
import { NetworkStatus, isApolloError } from '@apollo/client'
import { isTypeName } from '../types/utility'
import { messageTemplateStatusOptions } from './constants'
import MenuHeader from '../components/MenuHeader'
import { SelectionList } from '../components/SelectionPicker/SelectionList'
import useIntercomOnPage from '../hooks/useIntercomOnPage'
import { useContainerDimensions } from '../hooks/useComponentDimensions'
import { useDeleteMessageTemplateMutation } from './operations/delete-message-template.generated'
import BuilderModal from './builder/Modal'
import { useUpdateMessageTemplateMutation } from './operations/update-message-template.generated'
import {
  MessageTemplateFragmentFragment,
  MessageTemplateFragmentFragmentDoc,
} from './operations/message-template-fragment.generated'
import { BuilderStepType } from './builder/use-steps'
import { useToast } from '../components/Alert/ToastProvider'

const PAGE_SIZE = 9

const gapSpacing = 6
const cardWidth = 326

const useStyles = makeStyles(() =>
  createStyles({
    h7: {
      //MUI doesn't have a h7 whereas the mocks did
      paddingLeft: 0,
      fontSize: '1.125rem',
      lineHeight: 1.5,
      fontWeight: 600,
    },
    messageCard: {
      width: 326,
      borderRadius: 16,
      position: 'relative',
      backgroundColor: white,
      padding: '12px 24px 24px 24px',
      display: 'flex',
      flexDirection: 'column',
    },
    activeAutomationCard: {
      boxShadow: '0px 10px 20px rgba(37, 36, 103, 0.1)',
      border: `1px solid ${secondary[400]}`,
      padding: 4,
      borderRadius: 20,
    },
    messageCardHeader: {
      display: 'flex',
      flex: 'column',
      marginBottom: 12,
    },
    menuPaper: {
      minWidth: 272,
    },
  }),
)

function LoadingMessageCard() {
  const classes = useStyles({
    isAutomationEnabled: false,
  })
  return (
    <Card className={`${classes.activeAutomationCard}`}>
      <Box className={classes.messageCard}>
        <Skeleton component="span" height={32} width="100%" />
        <Box height={110} flexGrow={1} mt={4}>
          <Skeleton component="span" height={32} width="100%" />
          <Skeleton component="span" width="100%" />
          <Skeleton component="span" width="100%" />
          <Skeleton component="span" width="100%" />
        </Box>
        <Box mt={4}>
          <Skeleton component="span" height={40} width="100%" />
        </Box>
      </Box>
    </Card>
  )
}

type LoadingMessageComponentProps = {
  loadingMore: boolean
  max: number
}

function LoadingMessageComponent({ loadingMore, max = PAGE_SIZE }: LoadingMessageComponentProps): JSX.Element {
  return (
    <>
      {new Array(loadingMore ? PAGE_SIZE : max).fill(null).map((_, i) => (
        <Grid key={i} item>
          <LoadingMessageCard />
        </Grid>
      ))}
    </>
  )
}

const useWhereFilters = (filters: Filters): MessageTemplateFilterInput => {
  return {
    name: filters.messageKeywords?.all?.[0]?.length ? { keywords: filters.messageKeywords?.all?.[0] } : undefined,
    automationEnabled: filters.automationEnabled,
  }
}

function MessageList(): React.ReactElement {
  const classes = useStyles()
  useIntercomOnPage('MessageList')

  // only used on page load, set when we get data
  const [viewing = PAGE_SIZE] = useQueryParam('viewing', NumberParam)
  const limitRef = useRef(viewing)
  const { filters, setFilters } = useFilterParams()
  const { showToast } = useToast()
  const [builderOpen, setBuilderOpen] = useState(false)
  const [initialStep, setBuilderInitialStep] = useState<BuilderStepType>('messageForm')
  const [editingTemplate, setEditingTemplate] = useState<MessageTemplateFragmentFragment | null>(null)
  const addFilterButtonRef = useRef<HTMLButtonElement>(null)
  const [addFilterDialogOpen, setAddFilterDialogOpen] = useState(false)
  const theme = useTheme()
  const componentRef = useRef<HTMLDivElement>(null)
  const { width } = useContainerDimensions(componentRef)
  const gap = theme.spacing(gapSpacing)
  const cardsInRow = Math.floor((width - gap) / (cardWidth + gap))

  const {
    data: messagesUserData,
    error: messagesUserDataError,
    loading: messagesUserDataLoading,
  } = useMessagesUserDataQuery({
    variables: {
      where: {
        rewardType: {
          any: [RewardTypeEnum.Dm],
        },
      },
    },
  })

  const selectedSocialAccountId = messagesUserData?.whoami?.preferences.selectedSocialAccountId
  const selectedSocialAccount = (messagesUserData?.whoami?.account?.socialAccounts || []).find(
    sa => sa?.id === selectedSocialAccountId,
  )
  const accountRewards = messagesUserData?.whoami?.account?.rewards?.results

  const whereFilters = useWhereFilters(filters)
  const {
    data: messageTemplatesListData,
    error: messageTemplatesListError,
    loading: messageTemplatesListLoading,
    fetchMore,
    networkStatus,
  } = useMessageTemplatesListQuery({
    skip: !selectedSocialAccountId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      socialAccountId: selectedSocialAccountId || '',
      where: whereFilters,
      limit: limitRef.current,
      statsWhere: {
        socialAccountId: selectedSocialAccountId || '',
      },
    },
  })

  const [createMessageTemplate, { loading: formBuilderLoading }] = useCreateMessageTemplateMutation({
    update: (cache, { data }) => {
      const newMessageTemplate = data?.createMessageTemplate?.messageTemplate
      if (newMessageTemplate && selectedSocialAccount) {
        cache.modify({
          id: cache.identify(selectedSocialAccount),
          fields: {
            messageTemplates(existingTemplates) {
              const newRef = cache.writeFragment({
                data: newMessageTemplate,
                fragment: MessageTemplateFragmentFragmentDoc,
                fragmentName: 'MessageTemplateFragment',
              })
              return {
                ...existingTemplates,
                results: [newRef, ...existingTemplates.results],
              }
            },
          },
        })
      }
    },
  })

  const messageTemplates =
    messageTemplatesListData?.socialAccount &&
    isTypeName(messageTemplatesListData?.socialAccount, 'IGSocialAccount') &&
    messageTemplatesListData?.socialAccount?.messageTemplates
  const messageTemplatesList = messageTemplates ? messageTemplates.results : []

  const [updateMessageAutomation] = useUpdateMessageAutomationMutation({
    onError: e => {
      showToast({
        title: 'Error: Updating Message Auto Sending',
        message: 'Something went wrong, please try again. ' + e,
        severity: 'error',
      })
    },
  })

  const [updateMessageTemplate] = useUpdateMessageTemplateMutation({
    update: (cache, { data }) => {
      const updatedMessageTemplate = data?.updateMessageTemplate?.messageTemplate
      if (updatedMessageTemplate && selectedSocialAccount) {
        const cacheId = cache.identify(updatedMessageTemplate)
        cache.evict({ id: cacheId })
        cache.gc()
        cache.modify({
          id: cache.identify(updatedMessageTemplate),
          fields: {
            messageTemplates(existingTemplates) {
              const newRef = cache.writeFragment({
                data: updatedMessageTemplate,
                fragment: MessageTemplateFragmentFragmentDoc,
                fragmentName: 'MessageTemplateFragment',
              })
              return {
                ...existingTemplates,
                results: [newRef, ...existingTemplates.results],
              }
            },
          },
        })
      }
    },

    refetchQueries: [
      {
        query: MessageTemplatesListDocument,
        variables: {
          socialAccountId: selectedSocialAccountId || '',
          where: whereFilters,
          limit: limitRef.current,
          statsWhere: {
            socialAccountId: selectedSocialAccountId || '',
          },
        },
      },
    ],
    onError: e => {
      showToast({
        title: 'Error: Updating Message Auto Sending',
        message: 'Something went wrong, please try again. ' + e,
        severity: 'error',
      })
    },
  })

  const [deleteMessageTemplate] = useDeleteMessageTemplateMutation({
    onError: e => {
      showToast({
        title: 'Error: Deleting Message',
        message: 'Something went wrong, please try again. ' + e,
        severity: 'error',
      })
    },
  })

  function handleCreateMessageClicked(): void {
    setBuilderOpen(true)
  }

  function handleUpdateMessageStatus(value: boolean, id: string, hasAutomation: boolean): void {
    const messageTemplateToUpdate = messageTemplatesList.find(mt => mt.id === id)
    if (!messageTemplateToUpdate) {
      return
    } else if (messageTemplateToUpdate.automation && messageTemplateToUpdate.automation.enabled !== value) {
      void updateMessageAutomation({
        variables: {
          automationId: messageTemplateToUpdate.automation.id,
          enabled: value,
        },
      })
    } else if (!messageTemplateToUpdate.automation && value && !hasAutomation) {
      setBuilderInitialStep('automationBuilder')
      setEditingTemplate(messageTemplateToUpdate)
      setBuilderOpen(true)
    }
  }

  function handleUpdateMessageReward(
    id: string,
    name: string,
    template: string,
    rewardId: string,
    automationEnabled: boolean,
  ): void {
    const reward = accountRewards && accountRewards.find(ar => ar.id.toString() === rewardId)
    if (selectedSocialAccountId) {
      const automationDisabled = reward?.stats.codesLevel === CodesLevel.Empty
      updateMessageTemplate({
        variables: {
          templateId: id,
          messageTemplateInput: {
            name,
            template,
            socialAccountId: selectedSocialAccountId,
            automationEnabled: automationDisabled ? false : automationEnabled,
            rewardId,
          },
          statsWhere: {
            socialAccountId: selectedSocialAccountId || '',
          },
        },
      })
        .then(() => {
          if (reward) {
            return showToast({
              title: 'Success: Added Reward to Message',
              message: `Successfully added reward ${reward.name} to message ${name}`,
              severity: 'success',
            })
          }
        })
        .catch(e => {
          showToast({
            title: 'Error: Adding Reward to Message',
            message: 'Something went wrong when adding this reward to a message , please try again. ' + e,
            severity: 'error',
          })
        })
    }
  }

  function handleOnEdit(messageTemplate: MessageTemplateFragmentFragment): void {
    setBuilderInitialStep('messageForm')
    setEditingTemplate(messageTemplate)
    setBuilderOpen(true)
  }

  function handleOnDelete(id: string): void {
    deleteMessageTemplate({
      variables: {
        id,
      },
      update(cache, { data }) {
        if (!data?.deleteMessageTemplate?.ok || !data?.deleteMessageTemplate?.messageTemplate) {
          return
        }
        const cacheId = cache.identify(data.deleteMessageTemplate.messageTemplate)
        cache.evict({ id: cacheId })
        cache.gc()
      },
    }).catch(e => {
      showToast({
        title: 'Error: Deleting Message',
        message: 'Something went wrong when deleting this message, please try again. ' + e,
        severity: 'error',
      })
    })
  }

  const messageTemplatesCursor = messageTemplates ? messageTemplates.cursor : {}
  function handleLoadMore(): void {
    if (messageTemplatesCursor) {
      void fetchMore({
        variables: {
          cursor: messageTemplatesCursor,
          limit: PAGE_SIZE,
          where: whereFilters,
        },
      })
    }
  }

  function handleOnSubmitForm(messageTemplateInput: MessageTemplateInput, messageTemplateId?: string | null) {
    if (!messageTemplateId) {
      createMessageTemplate({
        variables: {
          messageTemplateInput: messageTemplateInput,
          statsWhere: {
            socialAccountId: selectedSocialAccountId || '',
          },
        },
      })
        .then(() => {
          setBuilderOpen(false)
        })
        .catch(e => {
          let message = 'Something went wrong when creating this message template, please try again.'
          if (isApolloError(e) && e.graphQLErrors?.some(e => e.extensions?.code === 'DUPLICATE_KEY')) {
            message =
              'Message Template with this name already exists, please use that message template or pick a different name.'
          }
          showToast({ title: 'Error: Creating Message Template', message })
        })
    } else {
      updateMessageTemplate({
        variables: {
          templateId: messageTemplateId,
          messageTemplateInput: messageTemplateInput,
          statsWhere: {
            socialAccountId: selectedSocialAccountId || '',
          },
        },
      })
        .then(() => {
          setBuilderOpen(false)
        })
        .catch(() => {
          const message = 'Something went wrong when updating this message template, please try again.'
          showToast({ title: 'Error: Updating Message Template', message })
        })
    }
  }

  const isIGAccount =
    messageTemplatesListData?.socialAccount && isTypeName(messageTemplatesListData?.socialAccount, 'IGSocialAccount')

  const emptySocialAccount = !messageTemplatesListData?.socialAccount

  const hasErrors = messagesUserDataError || messageTemplatesListError
  const isLoadingMoreMessageTemplates = networkStatus === NetworkStatus.fetchMore
  const isLoading = messagesUserDataLoading || (messageTemplatesListLoading && !isLoadingMoreMessageTemplates)
  const isErrorState = hasErrors && !isLoading
  const isReadyState = !hasErrors && !isLoading
  const isLoadingState = !hasErrors && isLoading
  const isDisabledState = isLoadingState || isErrorState || !isIGAccount

  const lastCardStyles =
    messageTemplatesList?.length && messageTemplatesList.length < cardsInRow
      ? {
          gridColumn: `span ${cardsInRow - messageTemplatesList.length + 1}`,
        }
      : undefined

  function handleSelectStatusFilter(value: Set<boolean>): void {
    const newFilters: Filters = {
      ...filters,
      automationEnabled: value.size === messageTemplateStatusOptions.length ? undefined : value.has(true),
    }
    setFilters(newFilters)
    setAddFilterDialogOpen(false)
  }
  function handleMenuClosed(): void {
    setAddFilterDialogOpen(false)
  }

  let statusTypeButtonLabel = 'All Messages'

  const selectedOptions: Set<boolean> = new Set()
  if (filters.automationEnabled !== undefined) {
    selectedOptions.add(filters.automationEnabled)
    const option = messageTemplateStatusOptions.find(o => o.id === filters.automationEnabled)
    if (option) {
      statusTypeButtonLabel = option.label
    }
  }

  return (
    <>
      <Box mb={6} display="flex" justifyContent="space-between" alignItems="center" {...{ ref: componentRef }}>
        <Button
          disabled={isDisabledState}
          ref={addFilterButtonRef}
          onClick={() => setAddFilterDialogOpen(true)}
          endIcon={<ChevronDownIcon height={16} width={16} />}
        >
          <Typography className={classes.h7}>{statusTypeButtonLabel}</Typography>
        </Button>
        <Menu
          open={addFilterDialogOpen}
          anchorEl={addFilterButtonRef.current}
          classes={{ paper: classes.menuPaper }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          getContentAnchorEl={null}
          onClose={handleMenuClosed}
        >
          <MenuHeader title="Status" />
          <SelectionList
            options={messageTemplateStatusOptions}
            onChangeOptions={handleSelectStatusFilter}
            selectedOptions={selectedOptions}
            selectionRequired={true}
          />
        </Menu>
        <Button variant="contained" color="primary" disabled={isDisabledState} onClick={handleCreateMessageClicked}>
          Create message
        </Button>
      </Box>
      {isErrorState && (
        <Box display="flex" justifyContent="center">
          <ContainerError text="Sorry, we had a problem loading messages." />
        </Box>
      )}
      {isReadyState && !messageTemplatesList?.length && (
        <Box display="flex" justifyContent="center" flexDirection="column">
          <ContainerEmptyState
            image={NoPostsImage}
            text={
              isIGAccount || emptySocialAccount
                ? 'You can send direct messages when customers mention you in stories!'
                : 'Messages are not available yet for TikTok. Select an Instagram account.'
            }
            subtext={isIGAccount ? 'Create templates to use later and set up automatic replies' : undefined}
          />
        </Box>
      )}
      <Box
        display="grid"
        gridGap={gap}
        gridTemplateColumns={`repeat(auto-fit, ${cardWidth}px)`}
        justifyContent="flex-start"
      >
        {!isLoading &&
          messageTemplatesList.map((messageTemplate, i) => (
            <MessageComponent
              key={messageTemplate.id}
              messageTemplate={messageTemplate}
              accountRewards={accountRewards}
              style={i === messageTemplatesList.length - 1 ? lastCardStyles : undefined}
              totalRecipients={messageTemplate.stats?.totalRecipients || 0}
              codesRemaining={messageTemplate.reward?.stats.codesRemaining || 0}
              codesTotal={messageTemplate.reward?.stats.codesInCurrentBatch || 0}
              codesLevel={messageTemplate.reward?.stats.codesLevel}
              onDelete={handleOnDelete}
              onEdit={handleOnEdit}
              onUpdateReward={handleUpdateMessageReward}
              onUpdateMessageStatus={handleUpdateMessageStatus}
              hasReward={!!messageTemplate.reward}
              automationEnabled={!!messageTemplate.automation?.enabled}
            />
          ))}
        {(isLoadingState || isLoadingMoreMessageTemplates) && (
          <LoadingMessageComponent max={limitRef.current} loadingMore={isLoadingMoreMessageTemplates} />
        )}
      </Box>
      {isReadyState && messageTemplates && messageTemplatesList.length > 0 && messageTemplatesCursor && (
        <Box display="flex" flexDirection="row" justifyContent="center" mt={8}>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={handleLoadMore}
            disabled={isLoadingMoreMessageTemplates}
          >
            Load more
          </Button>
        </Box>
      )}
      {selectedSocialAccountId && (
        <BuilderModal
          initialStep={initialStep}
          socialAccountId={selectedSocialAccountId}
          messageTemplate={editingTemplate}
          open={builderOpen}
          loading={formBuilderLoading}
          clearTemplate={() => setEditingTemplate(null)}
          onCancel={() => {
            setBuilderOpen(false)
            setEditingTemplate(null)
          }}
          onSubmit={handleOnSubmitForm}
        />
      )}
    </>
  )
}

export default MessageList

import * as Types from '../../../../gql-global';

import { gql } from '@apollo/client';
export type EcommDiscountCodeFragment = { __typename: 'EcommDiscountCodeType', appliesOncePerCustomer?: boolean | null, appliesOnEachItem?: boolean | null, appliesOnOneTimePurchase: boolean, appliesOnSubscription: boolean, codeFormat: Types.EcommDiscountCodeFormatEnum, codeType: Types.EcommDiscountCodeTypeEnum, combinesWithOrderDiscounts: boolean, combinesWithProductDiscounts?: boolean | null, combinesWithShippingDiscounts: boolean, customerEligibility?: Types.EcommDiscountCustomerEligibilityEnum | null, discountClass: Types.EcommDiscountClassEnum, endsAt?: Date | null, id: string, integrationType: Types.IntegrationType, overrideMessage?: boolean | null, usageLimit?: number | null, value: number, valueType: Types.EcommDiscountCodeValueTypeEnum, collections?: Array<{ __typename: 'CollectionType', id: string, imageSrc?: string | null, name: string, title: string }> | null, customerSegments?: Array<{ __typename: 'EcommCustomerSegmentType', id: string, name: string }> | null, products?: Array<{ __typename: 'Product', id: string, imageSrc?: string | null, title?: string | null }> | null };

export const EcommDiscountCodeFragmentDoc = gql`
    fragment EcommDiscountCode on EcommDiscountCodeType {
  appliesOncePerCustomer
  appliesOnEachItem
  appliesOnOneTimePurchase
  appliesOnSubscription
  codeFormat
  codeType
  collections {
    id
    imageSrc
    name
    title
  }
  combinesWithOrderDiscounts
  combinesWithProductDiscounts
  combinesWithShippingDiscounts
  customerEligibility
  customerSegments {
    id
    name
  }
  discountClass
  endsAt
  id
  integrationType
  overrideMessage
  products {
    id
    imageSrc
    title
  }
  usageLimit
  value
  valueType
}
    `;
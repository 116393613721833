import React from 'react'
import { NavLink } from 'react-router-dom'
import { Paper, Link, Theme, Grid, Box, makeStyles, createStyles, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { secondary, primary } from '../loudcrowd-theme'
import { ExpirationDisplay } from '../components/ExpirationDisplay'
import StoriesAvatarV2 from '../components/CustomerAvatar/StoriesAvatarV2'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: 600,
    },
    storyTarget: {
      color: primary[500],
      textDecoration: 'none',
    },
    expirationDisplay: {
      color: theme.palette.secondary.main,
      fontSize: 12,
    },
    timeLeftBlue: {
      color: theme.palette.info.main,
    },
    timeLeftYellow: {
      color: theme.palette.warning.dark,
    },
    timeLeftRed: {
      color: theme.palette.error.main,
    },
    columnTitle: {
      color: secondary[600],
    },
    columnBody: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    row: {
      marginBottom: theme.spacing(1),
      boxShadow: '0px 10px 20px rgba(37, 36, 103, 0.1)',
      border: `1px solid ${secondary[400]}`,
      padding: '16px 24px',
      borderRadius: 8,
      position: 'relative',
    },
    columnBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
    },
    columnPic: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
  }),
)

type RecipientInfoProps = {
  isManual: boolean
  username: string
  isActive: boolean
  storyLink?: string | null
  postedAt: Date
  customerId?: number | null
  onClickPreview(): void
}

function RecipientInfo({
  isManual,
  username,
  isActive,
  storyLink,
  postedAt,
  customerId,
  onClickPreview,
}: RecipientInfoProps): React.ReactElement {
  const classes = useStyles()
  const replyText = isManual ? 'Manual reply' : 'Auto reply'
  return (
    <div className={classes.columnBox}>
      <div>
        <Typography variant="body1" className={classes.bold}>
          <Link className={classes.storyTarget} onClick={onClickPreview}>
            {replyText}
          </Link>
          {' sent to '}
          <NavLink to={`/customer/${customerId}`} className={classes.storyTarget}>
            {username}
          </NavLink>
          &apos;s story
          {isActive && storyLink && (
            <Link className={classes.storyTarget} href={storyLink} target="_blank" rel="noopenner noreferrer">
              &apos;s story
            </Link>
          )}
        </Typography>
      </div>
      <div>
        <ExpirationDisplay postedAt={postedAt} />
      </div>
    </div>
  )
}

type ColumnBoxProps = {
  title: string
  name: string
}

function ColumnBox({ title, name }: ColumnBoxProps): React.ReactElement {
  const classes = useStyles()
  return (
    <Box className={classes.columnBox}>
      <Box>
        <Typography variant="body2" className={classes.columnTitle}>
          {title}
        </Typography>
      </Box>
      <Box>
        <Typography title={name} variant="body2" className={classes.columnBody}>
          {name}
        </Typography>
      </Box>
    </Box>
  )
}

export function LoadingActivityRow(): React.ReactElement {
  const classes = useStyles()
  return (
    <Paper className={classes.row}>
      <Grid container>
        <Grid item xs={1}>
          <Skeleton component="img" width={48} height={48} variant="circle" />
        </Grid>
        <Grid item xs={11}>
          <Skeleton component="span" />
          <Skeleton component="span" />
        </Grid>
      </Grid>
    </Paper>
  )
}

type ActivityComponentProps = {
  username: string
  profilePic: string
  isManual: boolean
  isActive: boolean
  messageName: string
  rewardName: string
  discountCode: string
  postedAt: Date
  storyLink?: string | null
  customerId?: number | null
  onClickPreview(): void
}

function ActivityComponent({
  username,
  profilePic,
  isManual,
  isActive,
  storyLink,
  postedAt,
  messageName,
  rewardName,
  discountCode,
  customerId,
  onClickPreview,
}: ActivityComponentProps): React.ReactElement {
  const classes = useStyles()
  return (
    <Paper className={classes.row}>
      <Grid container>
        <Grid item xs={1}>
          <StoriesAvatarV2 avatarUrl={profilePic} alt={username} hasActiveStory={isActive} />
        </Grid>
        <Grid item xs={5}>
          <RecipientInfo
            customerId={customerId}
            isManual={isManual}
            username={username}
            isActive={isActive}
            storyLink={storyLink}
            postedAt={postedAt}
            onClickPreview={onClickPreview}
          />
        </Grid>
        <Grid item xs={2}>
          <ColumnBox title="Template Name" name={messageName} />
        </Grid>
        <Grid item xs={2}>
          <ColumnBox title="Reward Name" name={rewardName} />
        </Grid>
        <Grid item xs={2}>
          <ColumnBox title="Reward Code" name={discountCode} />
        </Grid>
      </Grid>
    </Paper>
  )
}
export default ActivityComponent

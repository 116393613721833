import React, { ReactElement, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'
import { secondary, white } from '../../loudcrowd-theme'
import { ReactComponent as ShopifyIcon } from '../../icons/shopify.svg'
import { ReactComponent as SalesforceIcon } from '../../icons/salesforce.svg'
import SettingsBlocks from './index'
import { EcommDiscountCodeFragment } from '../../campaign/campaign-detail/campaign-settings/operations/ecomm-discount-code.generated'
import {
  DISCOUNT_CLASS_SELECTION_VALUES,
  DISCOUNT_CODE_FORMAT,
} from '../../campaign/campaign-detail/campaign-settings/constants'
import {
  EcommDiscountClassEnum,
  EcommDiscountCodeTypeEnum,
  EcommDiscountCodeValueTypeEnum,
  EcommDiscountCustomerEligibilityEnum,
  IntegrationType,
} from '../../gql-global'
import { useDeleteAllDiscountRedeemCodesMutation } from '../../campaign/campaign-detail/campaign-settings/operations/delete-all-discount-redeem-codes.generated'
import { useToast } from '../Alert/ToastProvider'
import { ApolloError } from '@apollo/client'
import { useCampaignSettingsUserInfoQuery } from '../../campaign/campaign-detail/campaign-settings/operations/campaign-settings-user-info.generated'

interface AffiliateCodeBlockProps {
  isSettingsExpired?: boolean
  handleOnSettingsEdit: () => void
  deactivateDiscountCode: () => void
  activateDiscountCode: () => void
  ecommDiscountCode: EcommDiscountCodeFragment | null
  discountType: EcommDiscountCodeTypeEnum
}

export type DiscountCodeSettingBlockType = {
  title: string
  items: Array<{ title: string; value: string }>
}

type SettingsBlockContent = {
  title: string
  subtitle: string
}

function DiscountSettingsBlock({
  ecommDiscountCode,
  handleOnSettingsEdit,
  isSettingsExpired,
  deactivateDiscountCode,
  activateDiscountCode,
  discountType,
}: AffiliateCodeBlockProps): ReactElement {
  const { showToast } = useToast()

  const { data: userInfo } = useCampaignSettingsUserInfoQuery()
  const isLoudcrowdInternal = userInfo?.whoami?.email?.endsWith('@loudcrowd.com')

  /**
   * Queries
   */
  const [deleteAllDiscountRedeemCodesMutation] = useDeleteAllDiscountRedeemCodesMutation({
    onError: (e: ApolloError) => {
      showToast({
        title: `Error: Delete all discount redeem codes`,
        message: e.message,
      })
    },
    onCompleted: () => {
      showToast({
        title: 'Success',
        message: `Discount codes have been successfully deleted.`,
        severity: 'success',
      })
    },
  })

  /**
   * Event handlers
   */
  const handleCloseExpireDialog = () => {
    setExpireDialogOpen(false)
  }
  const handleConfirmExpireAllDiscountRedeemCodes = () => {
    deactivateDiscountCode()
    handleCloseExpireDialog()
  }
  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false)
  }
  const handleConfirmDeleteAllDiscountRedeemCodes = () => {
    deleteAllDiscountRedeemCodesMutation({
      variables: {
        ecommDiscountCodeId: ecommDiscountCode?.id || '',
      },
    })
    handleCloseDeleteDialog()
  }

  /**
   * States
   */
  const [expireDialogOpen, setExpireDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [settingsBlockContent, setSettingsBlockContent] = useState<SettingsBlockContent>({ title: '', subtitle: '' })
  const blocks = getDiscountCodeSettingBlocks(ecommDiscountCode)
  const isAffiliateDiscount = discountType === EcommDiscountCodeTypeEnum.Affiliate

  /**
   * Effects
   */
  useEffect(() => {
    switch (discountType) {
      case EcommDiscountCodeTypeEnum.Affiliate:
        setSettingsBlockContent({
          title: 'Code Discount',
          subtitle:
            'Create an affiliate discount code set. Program members can use these codes to give their follows a discount when they purchase from your store.',
        })
        break
      case EcommDiscountCodeTypeEnum.Automatic:
        setSettingsBlockContent({
          title: 'Link Discount',
          subtitle:
            "Create an automatic discount set. This discount is automatically applied to the cart when a customer clicks on an ambassador's link.",
        })
        break
      default:
        setSettingsBlockContent({
          title: 'Shopify',
          subtitle: 'Create a shopify discount',
        })
    }
  }, [discountType, setSettingsBlockContent])

  return (
    <>
      <Box borderRadius={5} bgcolor={white} border={`1px solid ${secondary[400]}`}>
        <Box display="flex" alignItems="center" gridGap={10} borderBottom={`2px solid ${secondary[400]}`}>
          <Box padding="8px 10px" margin="10px 15px" bgcolor={secondary[50]} borderRadius={5}>
            {ecommDiscountCode?.integrationType === IntegrationType.CommerceCloud ? (
              <SalesforceIcon width={36} height={36} />
            ) : (
              <ShopifyIcon width={36} height={36} />
            )}
          </Box>
          <Box flex={1} margin="20px 0">
            <Typography variant="h6">{settingsBlockContent.title}</Typography>
            <Typography variant="caption" color="secondary">
              {settingsBlockContent.subtitle}
            </Typography>
          </Box>
          <Box pr={3}>
            {isSettingsExpired ? (
              <Button onClick={activateDiscountCode} variant="text" color="primary">
                Reactivate
              </Button>
            ) : (
              <>
                {(ecommDiscountCode?.integrationType === IntegrationType.CommerceCloud
                  ? isLoudcrowdInternal
                  : true) && (
                  <Button onClick={handleOnSettingsEdit} variant="text" color="primary">
                    Edit
                  </Button>
                )}

                <Button
                  onClick={() => {
                    setExpireDialogOpen(true)
                  }}
                  variant="text"
                  color="primary"
                >
                  Mark all expired
                </Button>
              </>
            )}
          </Box>
          {isAffiliateDiscount && (
            <Box pr={3}>
              <Button
                onClick={() => {
                  setDeleteDialogOpen(true)
                }}
                variant="text"
                color="primary"
              >
                Delete all
              </Button>
            </Box>
          )}
        </Box>
        <SettingsBlocks blocks={blocks} />
      </Box>
      {isAffiliateDiscount && (
        <DeleteAllCodesDialog
          open={deleteDialogOpen}
          handleOnClose={handleCloseDeleteDialog}
          handleConfirm={handleConfirmDeleteAllDiscountRedeemCodes}
          integrationType={ecommDiscountCode?.integrationType as IntegrationType}
        />
      )}
      <ExpireDiscountCodeDialog
        open={expireDialogOpen}
        handleOnClose={handleCloseExpireDialog}
        isAffiliateDiscount={isAffiliateDiscount}
        handleConfirm={handleConfirmExpireAllDiscountRedeemCodes}
        integrationType={ecommDiscountCode?.integrationType as IntegrationType}
      />
    </>
  )
}

function getDiscountCodeSettingBlocks(
  ecommDiscountCode: EcommDiscountCodeFragment | null,
): DiscountCodeSettingBlockType[] {
  if (!ecommDiscountCode) return []

  const discountClassSelectionValue = DISCOUNT_CLASS_SELECTION_VALUES?.find(
    ({ value }) => value === ecommDiscountCode?.discountClass,
  )

  const combineWith = `
      ${ecommDiscountCode.combinesWithShippingDiscounts ? 'Free shipping\n' : ''}
      ${ecommDiscountCode.combinesWithProductDiscounts ? 'Products\n' : ''}
      ${ecommDiscountCode.combinesWithOrderDiscounts ? 'Orders\n' : ''}
  `

  const itemSelectionSettingBlockItem =
    ecommDiscountCode.discountClass === EcommDiscountClassEnum.Product
      ? [
          {
            title: 'Applies to',
            value: `${ecommDiscountCode.products?.length || ecommDiscountCode?.collections?.length} ${
              ecommDiscountCode.products?.length
                ? 'products'
                : ecommDiscountCode.integrationType === IntegrationType.CommerceCloud
                ? 'categories'
                : 'collections'
            }`,
          },
        ]
      : []

  const itemSelectionOverrideMessage =
    ecommDiscountCode.codeType === EcommDiscountCodeTypeEnum.Automatic
      ? [
          {
            title: 'Override Message',
            value: ecommDiscountCode?.overrideMessage ? 'Yes' : 'No',
          },
        ]
      : []

  const codeFormatBlockItem =
    ecommDiscountCode.codeType === EcommDiscountCodeTypeEnum.Affiliate
      ? [
          {
            title: 'Code format',
            value: DISCOUNT_CODE_FORMAT.filter(format => format.value === ecommDiscountCode.codeFormat)[0]?.label || '',
          },
        ]
      : []

  const customerEligibilityBlockItem =
    ecommDiscountCode.codeType === EcommDiscountCodeTypeEnum.Affiliate
      ? ecommDiscountCode.customerEligibility === EcommDiscountCustomerEligibilityEnum.AllCustomers
        ? [
            {
              title: 'Customer eligibility',
              value: 'All customers',
            },
          ]
        : [
            {
              title: 'Customer eligibility',
              value: `${ecommDiscountCode.customerSegments?.length || 0} customer segments`,
            },
          ]
      : []

  const valueBlockItem =
    ecommDiscountCode.valueType === EcommDiscountCodeValueTypeEnum.Percentage
      ? [{ title: 'Discount percentage', value: `${ecommDiscountCode.value}%` }]
      : [{ title: 'Discount amount', value: `$${ecommDiscountCode.value}` }]

  const codeUsageLimitBlockItem =
    ecommDiscountCode.codeType === EcommDiscountCodeTypeEnum.Affiliate
      ? [
          {
            title: 'Max usage limit',
            value: !!ecommDiscountCode?.usageLimit ? ecommDiscountCode?.usageLimit.toString() : 'No limit',
          },
          {
            title: 'One use per customer',
            value: !!ecommDiscountCode?.appliesOncePerCustomer ? 'Yes' : 'No',
          },
        ]
      : []

  const automaticUsageLimit =
    ecommDiscountCode.codeType === EcommDiscountCodeTypeEnum.Automatic
      ? [
          {
            title: 'Usage limit',
            value: 'One use per order',
          },
        ]
      : []

  return [
    {
      title: 'Your settings',
      items: [
        ...codeFormatBlockItem,
        { title: 'Discount type', value: discountClassSelectionValue?.label || '' },
        ...valueBlockItem,
        ...itemSelectionSettingBlockItem,
        ...codeUsageLimitBlockItem,
        ...customerEligibilityBlockItem,
        {
          title: 'Combine with',
          value:
            combineWith
              .trim()
              .split('\n\n')
              .map(s => s.trim())
              .join(', ') || 'Nothing',
        },
        ...itemSelectionOverrideMessage,
      ],
    },
    {
      title: ecommDiscountCode.codeType === EcommDiscountCodeTypeEnum.Affiliate ? 'Code defaults' : 'Discount defaults',
      items: [
        { title: 'Minimum purchase requirements', value: 'None' },
        ...automaticUsageLimit,
        {
          title: 'Active date',
          value:
            ecommDiscountCode.codeType === EcommDiscountCodeTypeEnum.Affiliate
              ? 'Member approval date'
              : 'Applied at checkout',
        },
        { title: 'End date', value: 'No end date' },
      ],
    },
  ]
}

interface ExpireDiscountCodeDialogProps {
  open: boolean
  handleOnClose(): void
  handleConfirm(): void
  isAffiliateDiscount: boolean
  integrationType: IntegrationType
}

function ExpireDiscountCodeDialog({
  open,
  handleOnClose,
  isAffiliateDiscount,
  handleConfirm,
  integrationType,
}: ExpireDiscountCodeDialogProps): ReactElement {
  return (
    <Dialog open={open} onClose={handleOnClose}>
      <Box width={500}>
        {isAffiliateDiscount ? (
          <>
            <DialogTitle>Expire all Affiliate Codes?</DialogTitle>
            <DialogContent>
              <Box py={5}>
                All codes will be marked as expired in{' '}
                {integrationType === IntegrationType.Shopify ? 'Shopify' : 'Salesforce'}. Customers will no longer be
                able to use these codes at checkout.
              </Box>
            </DialogContent>
          </>
        ) : (
          <>
            <DialogTitle>Expire all Automatic Discounts?</DialogTitle>
            <DialogContent>
              <Box py={5}>
                All automatic discounts will be marked as expired in Shopify. Customers will no longer be able to use
                these discounts at checkout.
              </Box>
            </DialogContent>
          </>
        )}
        <DialogActions>
          <Button variant="contained" onClick={handleOnClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleConfirm}>
            OK
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    acknoledgementContainer: {
      '& label': {
        '& span': {
          fontSize: '13px',
        },
      },
    },
  }),
)

interface DeleteAllCodesDialogProps {
  open: boolean
  handleOnClose(): void
  handleConfirm(): void
  integrationType: IntegrationType
}

function DeleteAllCodesDialog({
  open,
  handleOnClose,
  handleConfirm,
  integrationType,
}: DeleteAllCodesDialogProps): ReactElement {
  const classes = useStyles()

  const [acknowledgeState, setAcknowledgeState] = useState({ checkbox1: false, checkbox2: false, checkbox3: false })

  const modalClose = () => {
    setAcknowledgeState({ checkbox1: false, checkbox2: false, checkbox3: false })
    handleOnClose()
  }

  return (
    <Dialog open={open} onClose={modalClose}>
      <Box width={600}>
        <DialogTitle>Delete all Affiliate Codes?</DialogTitle>
        <DialogContent>
          <Box pb={5}>
            Are you sure you want to delete all discount codes for this program? This action cannot be undone!
          </Box>
          <Box mb={2}>To confirm this action, please acknowledge the following:</Box>
          <div className={classes.acknoledgementContainer}>
            <FormControlLabel
              name="checkbox1"
              control={
                <Checkbox
                  checked={acknowledgeState.checkbox1}
                  onChange={e => {
                    setAcknowledgeState({ ...acknowledgeState, checkbox1: e.target.checked })
                  }}
                />
              }
              label={`All codes will be removed from ${
                integrationType === IntegrationType.Shopify ? 'Shopify' : 'Salesforce'
              } and can no longer be used at checkout.`}
            />
            <FormControlLabel
              name="checkbox2"
              control={
                <Checkbox
                  checked={acknowledgeState.checkbox2}
                  onChange={e => {
                    setAcknowledgeState({ ...acknowledgeState, checkbox2: e.target.checked })
                  }}
                />
              }
              label="Codes will no longer display on members' CRM profiles."
            />
            <FormControlLabel
              name="checkbox3"
              control={
                <Checkbox
                  checked={acknowledgeState.checkbox3}
                  onChange={e => {
                    setAcknowledgeState({ ...acknowledgeState, checkbox3: e.target.checked })
                  }}
                />
              }
              label="Historical sales using these codes will still be attributed to members who owned the codes."
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={modalClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            disabled={!(acknowledgeState.checkbox1 && acknowledgeState.checkbox2 && acknowledgeState.checkbox3)}
          >
            OK
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default DiscountSettingsBlock

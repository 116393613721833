export const paymentPeriodsDateFormat = (date: Date, format: 'long' | 'short' = 'short') => {
  date.setUTCHours(0, 0, 0, 0)
  return `${date.toLocaleString('en-us', { month: format })} ${date.getDate()}, ${date.getFullYear()}`
}

export const paymentPeriodsNextPayoutFormat = (date?: Date) => {
  if (!date) return ''
  date.setUTCHours(0, 0, 0, 0)
  return `${date.getMonth() + 1}/${date.getDate()}`
}

export const formatUTCDateToClientLocalTime = (date?: Date, locale = navigator.language): string => {
  if (!date) return ''
  const options: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  }
  return date.toLocaleDateString(locale, options)
}

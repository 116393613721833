import React from 'react'
import { Box, Button, createStyles, Dialog, makeStyles, Typography } from '@material-ui/core'
import { error } from '../../../loudcrowd-theme'
import { CustomerDetailsFragment } from '../../../customer/operations/customer-details.generated'
import { useDeleteCommissionTierMutation } from './operations/delete-commission-tier.generated'
import { useToast } from '../../../components/Alert/ToastProvider'

const useStyles = makeStyles(() =>
  createStyles({
    dialogPaper: {
      width: '30%',
      minWidth: '400px',
      height: '35%',
      minHeight: '280px',
      display: 'flex',
      flexDirection: 'column',
    },
    dialogField: { width: '100%', marginBottom: '20px' },
    dialogButton: { width: '80px' },
    cancelButton: { marginRight: '15px' },
    deleteButton: {
      color: error[600],
      borderColor: error[600],
      marginLeft: '-16px',
    },
  }),
)

type CommissionTierType = NonNullable<
  CustomerDetailsFragment['programParticipants'][number]['program']['commissionTiers']
>[number]

interface DeleteCommissionTierModalProps {
  open: boolean
  close: () => void
  deletingCommissionTier: CommissionTierType | null
  defaultCommissionTier: Omit<CommissionTierType, 'ratePercentage'>
  refreshAfterMutation: () => Promise<void>
}
const DeleteCommissionTierModal: React.FC<DeleteCommissionTierModalProps> = ({
  open,
  close,
  deletingCommissionTier,
  defaultCommissionTier,
  refreshAfterMutation,
}) => {
  const classes = useStyles()
  const { showToast } = useToast()

  const [deleteCommissionTierMutation] = useDeleteCommissionTierMutation({
    onError: () => {
      showToast({
        title: 'Error: Deleting Commission Tier',
        message: 'Something went wrong while deleting the Commission Tier, please try again.',
        severity: 'error',
      })
    },
    onCompleted: () => {
      showToast({
        title: 'Success',
        message: 'Commission Tier successfully deleted',
        severity: 'success',
      })
    },
  })

  const confirm = async () => {
    await deleteCommissionTierMutation({
      variables: {
        id: deletingCommissionTier?.id!,
      },
    })
    await refreshAfterMutation()
    close()
  }

  return (
    <Dialog classes={{ paper: classes.dialogPaper }} open={open} onClose={close}>
      <Box
        style={{ width: '100%', height: '100%', display: 'grid', gridTemplateRows: '9fr 1fr', padding: '3% 5% 3% 5%' }}
      >
        <Box>
          <Typography variant="h5" style={{ paddingBottom: '10px' }}>
            Delete Commission Tier
          </Typography>
          <Typography variant="body1">
            Are you sure you want to delete <b>{deletingCommissionTier?.name}</b>? All members assigned to this tier
            will be placed back to <b>{defaultCommissionTier.name}</b>.
          </Typography>
        </Box>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Box style={{ textAlign: 'end', width: '100%' }}>
            <Button
              className={`${classes.dialogButton} ${classes.cancelButton}`}
              onClick={close}
              color="secondary"
              size="medium"
            >
              Cancel
            </Button>
            <Button
              className={classes.dialogButton}
              onClick={confirm}
              color="primary"
              size="medium"
              variant="contained"
            >
              Done
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

export default DeleteCommissionTierModal

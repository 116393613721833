import { Box, FormHelperText, TextField, Typography, createStyles, makeStyles } from '@material-ui/core'
import { PaymentDayEnum, PaymentPeriodEnum, TremendousIntegration } from '../../../gql-global'
import React, { useState } from 'react'
import theme, { primary } from '../../../loudcrowd-theme'
import { Field, useFormikContext } from 'formik'
import { ProgramSettingsFields } from './CampaignSettings'
import { SelectField, SelectFieldOption } from '../../../components/SelectField/SelectField'
import SwitchComponent from '../../../components/Switch/Switch'
import useHasFeature from '../../../hooks/useHasFeature'
import { CampaignSettingsQuery } from './operations/campaign-settings.generated'

import CommissionTierList from './CommissionTierList'
import { useSetCampaignCommissionsEnabledMutation } from './operations/set-campaign-commissions-enabled.generated'
import { useToast } from '../../../components/Alert/ToastProvider'

const PAYOUT_PERIOD_OPTIONS = [{ id: PaymentPeriodEnum.Monthly, label: 'Monthly' }]
const PAYOUT_DAY_OPTIONS = [
  {
    id: PaymentDayEnum.FirstOfMonth,
    label: 'First of every month',
  },
]

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      overflowX: 'hidden',
    },
    field: {
      marginTop: 20,
      marginBottom: 20,
    },
    label: {
      color: theme.palette.primary.main,
    },
    input: {
      width: '100%',
    },
    caption: {
      color: primary[800],
    },
    switchMargin: {
      marginTop: 'auto',
      marginBottom: 'auto',
      marginLeft: '15px',
    },
  }),
)

interface ShoppableAmbassadorPayoutSettingsProps {
  payoutIntegrations: TremendousIntegration[]
  isSet: boolean
  program: NonNullable<NonNullable<CampaignSettingsQuery['campaign']>['program']>
  refreshAfterMutation: () => Promise<void>
}

export default function ShoppableAmbassadorPayoutSettings({
  payoutIntegrations,
  isSet,
  program,
  refreshAfterMutation,
}: ShoppableAmbassadorPayoutSettingsProps): React.ReactElement {
  const classes = useStyles()
  const { hasFeature: hasNetCommission } = useHasFeature('netCommission')

  const { values, setFieldValue, errors, touched } = useFormikContext<ProgramSettingsFields>()
  const [commissionsEnabled, setCommissionsEnabled] = useState(program.commissionsEnabled)

  const { showToast } = useToast()

  const [setCampaignCommissionsEnabled] = useSetCampaignCommissionsEnabledMutation({
    onError: () =>
      showToast({
        title: 'Error: Commissions Settings',
        message: 'Something went wrong while saving the Commissions Settings, please try again.',
        severity: 'error',
        autoHideDuration: 5000,
      }),
    onCompleted: () => {
      setCommissionsEnabled(!commissionsEnabled)
      showToast({
        title: 'Success: Commissions Settings Updated',
        message: 'Commissions settings were successfully saved.',
        severity: 'success',
        autoHideDuration: 5000,
      })
    },
  })

  const payoutIntegrationOptions: SelectFieldOption[] = payoutIntegrations.map(integration => ({
    id: integration.id,
    label: integration.name,
  }))

  const tremendousCampaignOptions: SelectFieldOption[] = values.payoutIntegrationId
    ? payoutIntegrations
        .find(integration => integration.id === values.payoutIntegrationId)
        ?.tremendousCampaigns.map(campaign => ({
          id: campaign.code,
          label: campaign.name,
        })) || []
    : []

  const commissionTiers = program.commissionTiers ?? []

  return (
    <Box className={classes.container}>
      <Box>
        <Box display="flex" className={classes.field}>
          <Typography variant="h5">Set Up Commission Structure</Typography>
          <SwitchComponent
            checked={commissionsEnabled}
            onChange={() =>
              setCampaignCommissionsEnabled({ variables: { value: !commissionsEnabled, programId: program.id } })
            }
            className={classes.switchMargin}
          />
        </Box>
        {commissionsEnabled && (
          <CommissionTierList
            commissionTiers={commissionTiers}
            program={program}
            refreshAfterMutation={refreshAfterMutation}
          ></CommissionTierList>
        )}
        {hasNetCommission && commissionsEnabled && (
          <Box>
            <Box display="flex" className={classes.field}>
              <SwitchComponent
                checked={values.commissionsIncludeShipping}
                onChange={() => setFieldValue('commissionsIncludeShipping', !values.commissionsIncludeShipping)}
              />
              <Box ml={3}>
                <Typography noWrap>Commissions Include Shipping</Typography>
              </Box>
            </Box>
            <Box display="flex" className={classes.field}>
              <SwitchComponent
                checked={values.commissionsIncludeTax}
                onChange={() => setFieldValue('commissionsIncludeTax', !values.commissionsIncludeTax)}
              />
              <Box ml={3}>
                <Typography noWrap>Commissions Include Tax</Typography>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {commissionsEnabled && (
        <>
          <Typography variant="h5">Affiliate Commissions Settings</Typography>
          <Box>
            <Box className={classes.field}>
              <Typography className={classes.label} variant="body1">
                Tremendous Integration
              </Typography>
              <SelectField
                hideSearch
                value={values.payoutIntegrationId}
                setValue={(value: string | number) => setFieldValue('payoutIntegrationId', value)}
                options={payoutIntegrationOptions}
                disabled={payoutIntegrationOptions.length === 1 && !!values.payoutIntegrationId}
              />
              {errors && !!touched.payoutIntegrationId && (
                <FormHelperText error>{errors?.payoutIntegrationId}</FormHelperText>
              )}
            </Box>
            {!!values.payoutIntegrationId && (
              <Box className={classes.field}>
                <Typography className={classes.label} variant="body1">
                  Tremendous Campaign
                </Typography>
                <SelectField
                  hideSearch
                  value={values.tremendousCampaignId}
                  setValue={(value: string | number) => {
                    setFieldValue('tremendousCampaignId', value)
                  }}
                  options={tremendousCampaignOptions}
                  disabled={tremendousCampaignOptions.length === 1 && !!values.tremendousCampaignId}
                />
                {errors && !!touched.tremendousCampaignId && (
                  <FormHelperText error>{errors?.tremendousCampaignId}</FormHelperText>
                )}
              </Box>
            )}
            <Box className={classes.field}>
              <Typography className={classes.label} variant="body1">
                Wait Time to Mark Orders as Approved for Commissions (Days)
              </Typography>
              <Field className={classes.input} as={TextField} name="payoutOrderMaturationPeriodDays" />
              {errors && !!touched.payoutOrderMaturationPeriodDays && (
                <FormHelperText error>{errors?.payoutOrderMaturationPeriodDays}</FormHelperText>
              )}
              <Typography variant="caption" className={classes.caption}>
                Set a wait time between 0 and 60 days between order date and approving it for commissions. Wait time
                helps prevent paying commissions on fraudulent, cancelled, or returned orders.
              </Typography>
            </Box>
            <Box display="flex" className={classes.field}>
              <SwitchComponent
                checked={values.autoCommissionsEnabled}
                onChange={() => setFieldValue('autoCommissionsEnabled', !values.autoCommissionsEnabled)}
              />
              <Box ml={3}>
                <Typography noWrap>Automate commissions payment</Typography>
              </Box>
            </Box>
            {values.autoCommissionsEnabled && (
              <>
                <Box className={classes.field}>
                  <Typography className={classes.label} variant="body1">
                    Commissions Period
                  </Typography>
                  <SelectField
                    hideSearch
                    value={values.payoutPeriod}
                    setValue={(value: string | number) => setFieldValue('payoutPeriod', value)}
                    options={PAYOUT_PERIOD_OPTIONS}
                    disabled={true}
                  />
                  {errors && !!touched.payoutPeriod && <FormHelperText error>{errors?.payoutPeriod}</FormHelperText>}
                  <Typography variant="caption" className={classes.caption}>
                    Commissions on all orders that were approved during this period will be paid out on the selected
                    payout day.
                  </Typography>
                </Box>
                <Box className={classes.field}>
                  <Typography className={classes.label} variant="body1">
                    Payout Day
                  </Typography>
                  <SelectField
                    hideSearch
                    value={values.payoutDay}
                    setValue={(value: string | number) => setFieldValue('payoutDay', value)}
                    options={PAYOUT_DAY_OPTIONS}
                    disabled={true}
                  />
                  <Typography variant="caption" className={classes.caption}>
                    Commissions will be paid out at 12 AM UTC
                  </Typography>
                  {errors && !!touched.payoutDay && <FormHelperText error>{errors?.payoutDay}</FormHelperText>}
                </Box>
              </>
            )}
          </Box>
        </>
      )}
    </Box>
  )
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Dialog,
  DialogContent,
  Box,
  Button,
  Typography,
  DialogActions,
  ListItem,
  List,
  TextField,
  CardMedia,
  DialogTitle,
} from '@material-ui/core'

import { ReactComponent as ShopifyIcon } from '../icons/shopify.svg'
import { ReactComponent as GoogleMerchantCenterIcon } from '../icons/google-merchant-center.svg'
import { ReactComponent as Ecommerce } from '../icons/ecommerce.svg'
import theme, { primary, secondary } from '../loudcrowd-theme'
import { ReactComponent as Salesforce } from '../icons/salesforce.svg'
import { makeStyles } from '@material-ui/core/styles'
import { useAuth0 } from '@auth0/auth0-react'
import * as yup from 'yup'
import { useDropzone } from 'react-dropzone'
import useHasFeature from '../hooks/useHasFeature'
import { useToast } from '../components/Alert/ToastProvider'
import { IntegrationRowFragment, IntegrationRowFragmentDoc } from './operations/integration-row.generated'
import { SelectField } from '../components/SelectField/SelectField'
import { useCreateIntegrationMutation } from './operations/create-integration.generated'
import { useUpdateIntegrationMutation } from './operations/update-integration.generated'
import {
  IntegrationType,
  ClientSftpObjectType,
  SegmentType,
  ShopifyIntegration,
  CustomStoreIntegration,
  CommerceCloudIntegration,
} from '../gql-global'
import { UserIntegrationInfoDocument } from './operations/user-integration-info.generated'
import { ShopIntegration } from './EcommerceIntegrations'
import { uploadFileToS3 } from '../utils/aws'
import { ReactComponent as CrossMark } from '../icons/cross.svg'
import { usePresignedUploadUrlStoreLogoLazyQuery } from './operations/presigned-upload-url-store-logo.generated'
import { useInvalidateEcommIntegrationMutation } from './operations/invalidate-ecomm-integration.generated'
import { isTypeName } from '../types/utility'
import SwitchComponent from '../components/Switch'

export const CustomSourceIntegrationTypeNames = ['SFTPPullIntegration'] as const
type CustomSourceIntegrationTypes = (typeof CustomSourceIntegrationTypeNames)[number]
export type CustomSourceIntegration = Extract<IntegrationRowFragment, { __typename: CustomSourceIntegrationTypes }>

interface EcommIntegrationModalProps {
  open: boolean
  onCancel(): void
  onClose(): void
  segments: SegmentType[]
  integrations: CustomSourceIntegration[]
  accountId?: string | null
  editingIntegration?: ShopIntegration
}

const validateDomainSchema = yup.string().matches(/^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+([a-zA-Z]{2,6})$/)

const useStyles = makeStyles({
  dropzone: {
    backgroundColor: theme.palette.secondary.light,
    border: '2px dashed #BCBBD1',
    boxSizing: 'border-box',
    borderRadius: 8,
  },
  looksLikeLink: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    fontWeight: theme.typography.fontWeightBold,
  },
  selected: {
    backgroundColor: secondary[50],
    borderLeft: `5px solid ${primary[500]}`,
  },
  successText: {
    color: theme.palette.success.main,
  },
  errorText: {
    color: theme.palette.error.main,
    float: 'left',
  },
  errorContainer: {
    display: 'flex',
    paddingLeft: '50px',
  },
  circle: {
    display: 'flex',
    width: '22px',
    height: '22px',
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.error.main,
    marginRight: '4px',
  },
  errorIcon: {
    color: theme.palette.error.main,
    margin: 'auto',
  },
  instructions: {
    margin: '0 0 10px',
  },
  ecommIcon: {
    transition: 'all 1s',
    transformOrigin: '50% 50%',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.2)',
    },
  },
  sidebar: {
    backgroundColor: secondary[200],
  },
  ecommSelector: {
    width: '100%',
    '& svg': {
      color: primary[500],
    },
  },
  media: {
    background: theme.palette.common.white,
  },
  mediaContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '16px',
    alignItems: 'flex-end',
  },
  removeButtonContainer: {
    marginRight: 'auto',
  },
  removeButton: {
    color: theme.palette.error.dark,
    border: `1px solid ${theme.palette.error.dark}`,
  },
  selectSegment: {
    '& p': {
      margin: 0,
      transform: 'scale(0.75) translateX(-84px)',
    },
  },
})

type EcommIntegrationTypes =
  | IntegrationType.Shopify
  | IntegrationType.Google
  | IntegrationType.CustomStore
  | IntegrationType.CommerceCloud

type IntegrationModalData = {
  icon: React.FC<React.SVGProps<SVGSVGElement>>
  title: string
  description: string[]
  authSteps?: string[]
  connectHandler: () => void
}

const shopifyLink = process.env.REACT_APP_SHOPIFY_APP_LISTING

type CustomStoreForm = {
  storeUrl: string
  productIdRegex: string
  customSource?: string
  lcRelativeLogoUrl?: string
  storeLogoUrl?: string
  displayName?: string
  keepUnattributedOrders?: boolean
  ilpFilterSegmentId?: string
}

type CustomStoreErrors = {
  storeUrl?: boolean
  productIdRegex?: boolean
  productUrlTemplate?: boolean
  shortCode?: boolean
  organizationId?: boolean
  siteId?: boolean
  clientId?: boolean
  clientSecret?: boolean
  storeLogoUrl?: boolean
  lcRelativeLogoUrl?: string
}

type CommerceCloudStoreForm = {
  storeUrl: string
  productIdRegex: string
  productUrlTemplate: string
  shortCode: string
  organizationId: string
  siteId: string
  clientId: string
  clientSecret: string
  lcRelativeLogoUrl?: string
  storeLogoUrl?: string
  displayName?: string
  keepUnattributedOrders?: boolean
  ilpFilterSegmentId?: string
}

type ShopifyForm = {
  storeLogoUrl: string
  lcRelativeLogoUrl: string
  displayName?: string
  keepUnattributedOrders?: boolean
  ilpFilterSegmentId?: string
}

const defaultSegment = { id: 'everyone', label: 'Everyone' }

const initialStateCustomStoreForm: CustomStoreForm = {
  storeUrl: '',
  productIdRegex: '',
  customSource: '',
  lcRelativeLogoUrl: '',
  storeLogoUrl: '',
  displayName: '',
  keepUnattributedOrders: true,
  ilpFilterSegmentId: defaultSegment.id,
}

const initialStateCommerceCloudForm: CommerceCloudStoreForm = {
  storeUrl: '',
  productIdRegex: '/(\\w+)\\.html',
  productUrlTemplate: '',
  shortCode: '',
  organizationId: '',
  siteId: '',
  clientId: '',
  clientSecret: '',
  lcRelativeLogoUrl: '',
  displayName: '',
  keepUnattributedOrders: true,
  ilpFilterSegmentId: defaultSegment.id,
}

const initialStateShopifyForm: ShopifyForm = {
  storeLogoUrl: '',
  lcRelativeLogoUrl: '',
  displayName: '',
  keepUnattributedOrders: true,
  ilpFilterSegmentId: defaultSegment.id,
}

function EcommIntegrationModal({
  open,
  onCancel,
  onClose,
  segments,
  integrations,
  accountId,
  editingIntegration,
}: EcommIntegrationModalProps): React.ReactElement {
  const styles = useStyles()
  const [errors, setErrors] = useState<CustomStoreErrors>({})
  const [selectedIntegrationType, setSelectedIntegrationType] = useState<EcommIntegrationTypes>(
    (editingIntegration?.integrationType as EcommIntegrationTypes) || IntegrationType.Shopify,
  )

  const { getAccessTokenSilently } = useAuth0()
  const authToken = getAccessTokenSilently()
  const { showToast } = useToast()

  const [presignedUploadUrlStoreLogo] = usePresignedUploadUrlStoreLogoLazyQuery()

  const [createIntegration] = useCreateIntegrationMutation({
    update(cache, { data }): void {
      const customerIntegration = data?.createIntegration?.integration
      if (!customerIntegration) {
        return
      }
      cache.modify({
        id: cache.identify({ __typename: 'AccountType', id: accountId }),
        fields: {
          integrations(existing = []) {
            const newRef = cache.writeFragment({
              data: customerIntegration,
              fragment: IntegrationRowFragmentDoc,
            })
            return [...existing, newRef]
          },
        },
      })
    },
  })
  const [updateIntegration] = useUpdateIntegrationMutation()
  const [invalidateEcommIntegration] = useInvalidateEcommIntegrationMutation()

  const { hasFeature: hasGoogleMarketIntegration } = useHasFeature('enterpriseGoogleMarket')
  const { hasFeature: hasKeepAttributtedOrderSwitch } = useHasFeature('keepAttributtedOrderSwitch')

  const [removeIntegrationDialogOpen, setRemoveIntegrationDialogOpen] = useState(false)

  const [googleMerchantId, setGoogleMerchantId] = useState('')

  const [customStoreForm, setCustomStoreForm] = useState<CustomStoreForm>({
    ...initialStateCustomStoreForm,
    ...(editingIntegration?.__typename === 'CustomStoreIntegration' && {
      storeUrl: editingIntegration.shopUrl,
      productIdRegex: editingIntegration.productIdRegex,
      customSource: editingIntegration.productSourceIntegration?.id,
      storeLogoUrl: editingIntegration.storeLogoUrl || '',
      lcRelativeLogoUrl: editingIntegration.lcRelativeLogoUrl || '',
      displayName: editingIntegration.displayName || '',
      keepUnattributedOrders: editingIntegration.keepUnattributedOrders ?? true,
      ilpFilterSegmentId: editingIntegration.ilpFilterSegment?.id.toString() ?? defaultSegment.id,
    }),
  })
  const [commerceCloudForm, setCommerceCloudForm] = useState<CommerceCloudStoreForm>({
    ...initialStateCommerceCloudForm,
    ...(editingIntegration?.__typename === 'CommerceCloudIntegration' && {
      storeUrl: editingIntegration.shopUrl,
      productIdRegex: editingIntegration.productIdRegex,
      productUrlTemplate: editingIntegration.productUrlTemplate,
      shortCode: editingIntegration.shortCode,
      organizationId: editingIntegration.organizationId,
      siteId: editingIntegration.siteId,
      clientId: editingIntegration.clientId || '',
      storeLogoUrl: editingIntegration.storeLogoUrl || '',
      lcRelativeLogoUrl: editingIntegration.lcRelativeLogoUrl || '',
      displayName: editingIntegration.displayName || '',
      keepUnattributedOrders: editingIntegration.keepUnattributedOrders ?? true,
      ilpFilterSegmentId: editingIntegration.ilpFilterSegment?.id.toString() ?? defaultSegment.id,
    }),
  })

  const [shopifyForm, setShopifyForm] = useState({
    ...initialStateShopifyForm,
    ...(editingIntegration?.__typename === 'ShopifyIntegration' && {
      storeLogoUrl: editingIntegration.storeLogoUrl || '',
      lcRelativeLogoUrl: editingIntegration.lcRelativeLogoUrl || '',
      displayName: editingIntegration.displayName || '',
      keepUnattributedOrders: editingIntegration.keepUnattributedOrders ?? true,
      ilpFilterSegmentId: editingIntegration.ilpFilterSegment?.id.toString() ?? defaultSegment.id,
    }),
  })

  const handleCustomStoreFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'storeUrl') {
      setErrors({ ...errors, [event.target.name]: !validateDomainSchema.isValidSync(event.target.value) })
    }
    if (event.target.name === 'productIdRegex') {
      setErrors({ ...errors, [event.target.name]: !event.target.value })
    }

    setCustomStoreForm({
      ...customStoreForm,
      [event.target.name]: event.target.value,
    })
  }

  const handleShopifyFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShopifyForm({
      ...shopifyForm,
      [event.target.name]: event.target.value,
    })
  }

  const handleCommerceCloudFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'storeUrl') {
      setErrors({ ...errors, [event.target.name]: !event.target.value })
    }
    if (event.target.name === 'productIdRegex') {
      setErrors({ ...errors, [event.target.name]: !event.target.value })
    }
    if (event.target.name === 'productUrlTemplate') {
      setErrors({ ...errors, [event.target.name]: !event.target.value })
    }
    if (event.target.name === 'shortCode') {
      setErrors({ ...errors, [event.target.name]: !event.target.value })
    }
    if (event.target.name === 'organizationId') {
      setErrors({ ...errors, [event.target.name]: !event.target.value })
    }
    if (event.target.name === 'siteId') {
      setErrors({ ...errors, [event.target.name]: !event.target.value })
    }
    if (event.target.name === 'clientId') {
      setErrors({ ...errors, [event.target.name]: !event.target.value })
      if (!!editingIntegration) {
        setCommerceCloudForm({
          ...commerceCloudForm,
          clientSecret: '',
        })
      }
    }
    if (event.target.name === 'clientSecret') {
      setErrors({ ...errors, [event.target.name]: !event.target.value })
    }

    setCommerceCloudForm({
      ...commerceCloudForm,
      [event.target.name]: event.target.value,
    })
  }

  const handleInvalidateIntegration = async () => {
    try {
      if (editingIntegration) {
        await invalidateEcommIntegration({
          variables: {
            id: editingIntegration.id,
          },
          refetchQueries: [{ query: UserIntegrationInfoDocument }],
        })
      }

      showToast({
        title: 'Success',
        message: 'Integration successfully removed.',
        severity: 'success',
        autoHideDuration: 5000,
      })
    } catch {
      showToast({
        title: 'Failed to remove Integration',
        message: 'Please try again',
        severity: 'error',
        autoHideDuration: 5000,
      })
    }

    handleReset()
  }

  useEffect(() => {
    if (editingIntegration && editingIntegration?.__typename === 'CustomStoreIntegration') {
      setCustomStoreForm({
        storeUrl: editingIntegration.shopUrl,
        productIdRegex: editingIntegration.productIdRegex,
        customSource: editingIntegration.productSourceIntegration?.id || '',
        storeLogoUrl: editingIntegration.storeLogoUrl || '',
        lcRelativeLogoUrl: editingIntegration.lcRelativeLogoUrl || '',
        displayName: editingIntegration.displayName || '',
        keepUnattributedOrders: editingIntegration.keepUnattributedOrders ?? true,
        ilpFilterSegmentId: defaultSegment.id,
      })

      setSelectedIntegrationType(IntegrationType.CustomStore)
    }
    if (editingIntegration && editingIntegration?.__typename === 'CommerceCloudIntegration') {
      setCommerceCloudForm({
        storeUrl: editingIntegration.shopUrl,
        productIdRegex: editingIntegration.productIdRegex,
        productUrlTemplate: editingIntegration.productUrlTemplate,
        shortCode: editingIntegration.shortCode,
        organizationId: editingIntegration.organizationId,
        siteId: editingIntegration.siteId,
        clientId: editingIntegration.clientId || '',
        storeLogoUrl: editingIntegration.storeLogoUrl || '',
        lcRelativeLogoUrl: editingIntegration.lcRelativeLogoUrl || '',
        clientSecret: '',
        displayName: editingIntegration.displayName || '',
        keepUnattributedOrders: editingIntegration.keepUnattributedOrders ?? true,
        ilpFilterSegmentId: defaultSegment.id,
      })

      setSelectedIntegrationType(IntegrationType.CommerceCloud)
    }
    if (editingIntegration && editingIntegration?.__typename === 'ShopifyIntegration') {
      setShopifyForm({
        storeLogoUrl: editingIntegration.storeLogoUrl || '',
        lcRelativeLogoUrl: editingIntegration.lcRelativeLogoUrl || '',
        displayName: editingIntegration.displayName || '',
        keepUnattributedOrders: editingIntegration.keepUnattributedOrders ?? true,
        ilpFilterSegmentId: defaultSegment.id,
      })

      setSelectedIntegrationType(IntegrationType.Shopify)
    }
  }, [editingIntegration])

  const handleReset = () => {
    onClose()
    setCustomStoreForm(initialStateCustomStoreForm)
    setCommerceCloudForm(initialStateCommerceCloudForm)
    setShopifyForm(initialStateShopifyForm)
    setErrors({})
  }

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const fileUploaded = acceptedFiles[0]
      if (!fileUploaded) {
        return
      }

      setErrors({ ...errors, storeLogoUrl: false })

      try {
        const data = await presignedUploadUrlStoreLogo({
          fetchPolicy: 'no-cache',
          variables: {
            accountId: accountId?.toString() || '',
            mimetype: fileUploaded.type,
          },
        })

        const presignedUrl = data?.data?.account?.presignedUrlForStoreLogoUpload
        const keyField = data?.data?.account?.presignedUrlForStoreLogoUpload?.fields?.find(f => f.key === 'key')

        if (presignedUrl && keyField) {
          await uploadFileToS3(presignedUrl, fileUploaded)

          if (
            editingIntegration?.integrationType === IntegrationType.CustomStore ||
            selectedIntegrationType === IntegrationType.CustomStore
          ) {
            setCustomStoreForm({
              ...initialStateCustomStoreForm,
              ...(editingIntegration?.__typename === 'CustomStoreIntegration' && {
                productIdRegex: editingIntegration.productIdRegex || '',
                customSource: editingIntegration.productSourceIntegration?.id || '',
                storeUrl: editingIntegration.shopUrl || '',
                keepUnattributedOrders: editingIntegration.keepUnattributedOrders ?? true,
              }),
              lcRelativeLogoUrl: keyField.value,
              storeLogoUrl: fileUploaded ? URL.createObjectURL(fileUploaded) : '',
            })
          }

          if (
            editingIntegration?.integrationType === IntegrationType.CommerceCloud ||
            selectedIntegrationType === IntegrationType.CommerceCloud
          ) {
            setCommerceCloudForm({
              ...initialStateCommerceCloudForm,
              ...(editingIntegration?.__typename === 'CommerceCloudIntegration' && {
                storeUrl: editingIntegration.shopUrl,
                productIdRegex: editingIntegration.productIdRegex || '',
                productUrlTemplate: editingIntegration.productUrlTemplate || '',
                shortCode: editingIntegration.shortCode || '',
                organizationId: editingIntegration.organizationId,
                siteId: editingIntegration.siteId,
                clientId: editingIntegration.clientId || '',
                keepUnattributedOrders: editingIntegration.keepUnattributedOrders ?? true,
              }),
              lcRelativeLogoUrl: keyField.value,
              storeLogoUrl: fileUploaded ? URL.createObjectURL(fileUploaded) : '',
            })
          }

          if (
            editingIntegration?.integrationType === IntegrationType.Shopify ||
            selectedIntegrationType === IntegrationType.Shopify
          ) {
            setShopifyForm({
              ...initialStateShopifyForm,
              ...(editingIntegration?.__typename === 'ShopifyIntegration' && {
                displayName: editingIntegration.displayName || '',
                keepUnattributedOrders: editingIntegration.keepUnattributedOrders ?? true,
              }),
              storeLogoUrl: fileUploaded ? URL.createObjectURL(fileUploaded) : '',
              lcRelativeLogoUrl: keyField.value,
            })
          }
        } else {
          throw new Error('Failed to upload file')
        }
      } catch (e) {
        showToast({
          title: 'Uh Oh! It looks like we had a problem uploading that file.',
          message: 'Please try uploading that again.',
          severity: 'error',
        })
        setErrors({ ...errors, storeLogoUrl: true })
      }
    },
    [accountId, presignedUploadUrlStoreLogo, editingIntegration, selectedIntegrationType, showToast, errors],
  )

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/*', 'image/svg+xml'],
    onDrop,
  })

  const integrationModalDataMap: { [key in EcommIntegrationTypes]: IntegrationModalData } = {
    [IntegrationType.Shopify]: {
      icon: ShopifyIcon,
      title: 'Set up your Shopify Integration',
      description:
        editingIntegration && editingIntegration.__typename === 'ShopifyIntegration'
          ? [`You are editing your Shopify integration.`]
          : [
              `Shopify lets you set up an online store. You can manage products, process payments and coordinate shipments.`,
              `Connect LoudCrowd to your Shopify account so that your customers can use their rewards when they shop your online store.`,
            ],
      authSteps:
        editingIntegration && editingIntegration.__typename === 'ShopifyIntegration'
          ? undefined
          : [
              'Click the “Connect to Shopify” button.',
              'You’ll be automatically navigated to the Shopify install page.',
              'Click the “Install app” button.',
              'Success! Shopify has been installed. You’ll be automatically navigated back to LoudCrowd.',
            ],
      connectHandler: async () => {
        if (editingIntegration && editingIntegration.__typename === 'ShopifyIntegration') {
          await updateIntegration({
            variables: {
              ...editingIntegration,
              lcRelativeLogoUrl: shopifyForm.lcRelativeLogoUrl,
              displayName: shopifyForm.displayName,
              keepUnattributedOrders: shopifyForm.keepUnattributedOrders,
              ilpFilterSegmentId:
                shopifyForm.ilpFilterSegmentId !== defaultSegment.id ? shopifyForm.ilpFilterSegmentId : null,
            },
            refetchQueries: [{ query: UserIntegrationInfoDocument }],
          })
            .then(() => {
              showToast({
                title: 'Shopify Integration Updated',
                message: 'Shopify Integration updated successfully',
                severity: 'success',
                autoHideDuration: 5000,
              })
            })
            .catch(error => {
              showToast({
                title: 'Failed to Update Shopify Integration',
                message: error.message,
                severity: 'error',
                autoHideDuration: 5000,
              })
            })
        } else {
          // Original Shopify connection logic
          if (shopifyLink) {
            window.location.href = shopifyLink
          }
        }

        handleReset()
      },
    },
    [IntegrationType.Google]: {
      icon: GoogleMerchantCenterIcon,
      title: 'Set up your Google Merchant Center Account Integration',
      description: [
        `Google Merchant Center allows you to attract more customers by listing your products and business on Google Search,
         Maps, YouTube, and more.`,
        `Connect LoudCrowd to your Google Merchant Center account so that your products and other related data becomes available
         to LoudCrowd for use on your Online Store and Storefronts.`,
      ],
      authSteps: [
        'Fill your Google Merchant Id in the field above.',
        'Click the “Connect to Google Merchant Center” button.',
        'You’ll be automatically navigated to the Google Authentication page.',
        'Login using your Google Account credentials.',
        'Success! Google Merchant Center integration is now configured! You’ll be automatically navigated back to LoudCrowd.',
      ],
      connectHandler: async () => {
        if (!googleMerchantId) {
          showToast({
            title: "Can't connect to Google Merchant Center",
            message: 'You must fill your Google Merchant Id.',
            severity: 'error',
            autoHideDuration: 5000,
          })
          return
        }
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/api/google_market_oauth?google_merchant_id=${googleMerchantId}`,
          {
            method: 'GET',
            headers: { Authorization: `Bearer ${await authToken}` },
          },
        )
        if (!response.ok) throw response
        const data = await response.json()
        if (data.auth_url) {
          window.location.href = data.auth_url
        }

        handleReset()
      },
    },
    [IntegrationType.CustomStore]: {
      icon: Ecommerce,
      title: 'Set up your Custom Store Integration',
      description: [
        `Connecting LoudCrowd to your ecommerce site lets you build influencer storefronts into your shopping experience.`,
      ],
      connectHandler: async () => {
        if (!customStoreForm.storeUrl) {
          showToast({
            title: "Can't connect to Custom Store",
            message: 'You must fill your Store Url.',
            severity: 'error',
            autoHideDuration: 5000,
          })

          setErrors({ ...errors, storeUrl: true })
          return
        }

        if (!customStoreForm.productIdRegex) {
          showToast({
            title: "Can't connect to Custom Store",
            message: 'You must fill your Product Id Regex.',
            severity: 'error',
            autoHideDuration: 5000,
          })

          setErrors({ ...errors, productIdRegex: true })
          return
        }
        if (editingIntegration) {
          await updateIntegration({
            variables: {
              ...editingIntegration,
              lcRelativeLogoUrl: customStoreForm.lcRelativeLogoUrl,
              productIdRegex: customStoreForm.productIdRegex,
              productSourceIntegrationId: customStoreForm.customSource,
              shopUrl: customStoreForm.storeUrl,
              displayName: customStoreForm.displayName,
              keepUnattributedOrders: customStoreForm.keepUnattributedOrders,
              ilpFilterSegmentId:
                customStoreForm.ilpFilterSegmentId !== defaultSegment.id ? customStoreForm.ilpFilterSegmentId : null,
            },
            refetchQueries: [{ query: UserIntegrationInfoDocument }],
          })
        } else {
          await createIntegration({
            variables: {
              accountId,
              name: customStoreForm.storeUrl,
              integrationType: IntegrationType.CustomStore,
              igUsernameFields: [],
              shopUrl: customStoreForm.storeUrl,
              productIdRegex: customStoreForm.productIdRegex,
              key: customStoreForm.storeUrl,
              productSourceIntegrationId: customStoreForm.customSource,
              lcRelativeLogoUrl: customStoreForm.lcRelativeLogoUrl,
              displayName: customStoreForm.displayName,
              keepUnattributedOrders: customStoreForm.keepUnattributedOrders,
              ilpFilterSegmentId:
                customStoreForm.ilpFilterSegmentId !== defaultSegment.id
                  ? customStoreForm.ilpFilterSegmentId
                  : undefined,
            },
            update(cache, { data }): void {
              const customerIntegration = data?.createIntegration?.integration
              if (!customerIntegration) {
                return
              }
              cache.modify({
                id: cache.identify({ __typename: 'AccountType', id: accountId }),
                fields: {
                  integrations(existing = []) {
                    const newRef = cache.writeFragment({
                      data: customerIntegration,
                      fragment: IntegrationRowFragmentDoc,
                    })
                    return [...existing, newRef]
                  },
                },
              })
            },
            refetchQueries: [{ query: UserIntegrationInfoDocument }],
          })
        }

        showToast({
          title: 'Custom Store Integration',
          message: editingIntegration
            ? 'Custom Store Integration updated successfully'
            : 'Custom Store Integration created successfully',
          severity: 'success',
          autoHideDuration: 5000,
        })
        handleReset()
      },
    },
    [IntegrationType.CommerceCloud]: {
      icon: Salesforce,
      title: 'Set up your Salesforce Commerce Cloud Integration',
      description: [`Salesforce Commerce Cloud integration`],
      connectHandler: async () => {
        if (!commerceCloudForm.storeUrl) {
          showToast({
            title: "Can't connect to Salesforce Commerce Cloud",
            message: 'You must fill your Store Url.',
            severity: 'error',
            autoHideDuration: 5000,
          })

          setErrors({ ...errors, storeUrl: true })
          return
        }

        if (!commerceCloudForm.productIdRegex) {
          showToast({
            title: "Can't connect to Salesforce Commerce Cloud",
            message: 'You must fill your Product Id Regex.',
            severity: 'error',
            autoHideDuration: 5000,
          })

          setErrors({ ...errors, productIdRegex: true })
          return
        }

        if (!commerceCloudForm.productUrlTemplate) {
          showToast({
            title: "Can't connect to Salesforce Commerce Cloud",
            message: 'You must fill your Product URL Template.',
            severity: 'error',
            autoHideDuration: 5000,
          })

          setErrors({ ...errors, productUrlTemplate: true })
          return
        }

        if (!commerceCloudForm.shortCode) {
          showToast({
            title: "Can't connect to Salesforce Commerce Cloud",
            message: 'You must fill your Short Code.',
            severity: 'error',
            autoHideDuration: 5000,
          })

          setErrors({ ...errors, shortCode: true })
          return
        }

        if (!commerceCloudForm.organizationId) {
          showToast({
            title: "Can't connect to Salesforce Commerce Cloud",
            message: 'You must fill your Organization ID.',
            severity: 'error',
            autoHideDuration: 5000,
          })

          setErrors({ ...errors, organizationId: true })
          return
        }

        if (!commerceCloudForm.siteId) {
          showToast({
            title: "Can't connect to Salesforce Commerce Cloud",
            message: 'You must fill your Site ID.',
            severity: 'error',
            autoHideDuration: 5000,
          })

          setErrors({ ...errors, siteId: true })
          return
        }

        if (!commerceCloudForm.clientId) {
          showToast({
            title: "Can't connect to Salesforce Commerce Cloud",
            message: 'You must fill your Client ID.',
            severity: 'error',
            autoHideDuration: 5000,
          })

          setErrors({ ...errors, clientId: true })
          return
        }

        if (!editingIntegration && !commerceCloudForm.clientSecret) {
          showToast({
            title: "Can't connect to Salesforce Commerce Cloud",
            message: 'You must fill your Client Secret.',
            severity: 'error',
            autoHideDuration: 5000,
          })

          setErrors({ ...errors, clientSecret: true })
          return
        }

        if (editingIntegration) {
          await updateIntegration({
            variables: {
              ...editingIntegration,
              shopUrl: commerceCloudForm.storeUrl,
              productIdRegex: commerceCloudForm.productIdRegex,
              productUrlTemplate: commerceCloudForm.productUrlTemplate,
              shortCode: commerceCloudForm.shortCode,
              organizationId: commerceCloudForm.organizationId,
              siteId: commerceCloudForm.siteId,
              lcRelativeLogoUrl: commerceCloudForm.lcRelativeLogoUrl,
              displayName: commerceCloudForm.displayName,
              keepUnattributedOrders: commerceCloudForm.keepUnattributedOrders,
              ...(editingIntegration?.__typename === 'CommerceCloudIntegration' &&
                (editingIntegration.clientId !== commerceCloudForm.clientId || !!commerceCloudForm.clientSecret) && {
                  clientId: commerceCloudForm.clientId,
                  clientSecret: commerceCloudForm.clientSecret,
                }),
              ilpFilterSegmentId:
                commerceCloudForm.ilpFilterSegmentId !== defaultSegment.id
                  ? commerceCloudForm.ilpFilterSegmentId
                  : null,
            },
            refetchQueries: [{ query: UserIntegrationInfoDocument }],
          }).catch(e => {
            showToast({
              title: 'Failed to update Salesforce Commerce Cloud Integration',
              message: e.message,
              severity: 'error',
              autoHideDuration: 5000,
            })
          })
        } else {
          await createIntegration({
            variables: {
              accountId,
              name: commerceCloudForm.storeUrl,
              integrationType: IntegrationType.CommerceCloud,
              igUsernameFields: [],
              shopUrl: commerceCloudForm.storeUrl,
              productIdRegex: commerceCloudForm.productIdRegex,
              productUrlTemplate: commerceCloudForm.productUrlTemplate,
              key: commerceCloudForm.storeUrl,
              shortCode: commerceCloudForm.shortCode,
              organizationId: commerceCloudForm.organizationId,
              siteId: commerceCloudForm.siteId,
              clientId: commerceCloudForm.clientId,
              clientSecret: commerceCloudForm.clientSecret,
              lcRelativeLogoUrl: commerceCloudForm.lcRelativeLogoUrl,
              displayName: commerceCloudForm.displayName,
              keepUnattributedOrders: commerceCloudForm.keepUnattributedOrders,
              ilpFilterSegmentId:
                commerceCloudForm.ilpFilterSegmentId !== defaultSegment.id
                  ? commerceCloudForm.ilpFilterSegmentId
                  : undefined,
            },
            update(cache, { data }): void {
              const customerIntegration = data?.createIntegration?.integration
              if (!customerIntegration) {
                return
              }
              cache.modify({
                id: cache.identify({ __typename: 'AccountType', id: accountId }),
                fields: {
                  integrations(existing = []) {
                    const newRef = cache.writeFragment({
                      data: customerIntegration,
                      fragment: IntegrationRowFragmentDoc,
                    })
                    return [...existing, newRef]
                  },
                },
              })
            },
            refetchQueries: [{ query: UserIntegrationInfoDocument }],
          }).catch(e => {
            showToast({
              title: 'Failed to create Salesforce Commerce Cloud Integration',
              message: e.message,
              severity: 'error',
              autoHideDuration: 5000,
            })
          })
        }

        showToast({
          title: 'Custom Store Integration',
          message: editingIntegration
            ? 'Custom Store Integration updated successfully'
            : 'Custom Store Integration created successfully',
          severity: 'success',
          autoHideDuration: 5000,
        })
        handleReset()
      },
    },
  }

  function generateSampleProductUrl(productUrlTemplate: string): string {
    const exampleProductId = '123456'
    // check if "{{productId}} in the template"
    if (productUrlTemplate.includes('{{productId}}')) {
      return productUrlTemplate.replace('{{productId}}', exampleProductId)
    }
    // check if the product url template ends with "/"
    else if (productUrlTemplate.endsWith('/')) {
      return `${productUrlTemplate}${exampleProductId}.html`
    } else {
      return `${productUrlTemplate}/${exampleProductId}.html`
    }
  }
  const exampleProductUrlTemplateHelper = !!commerceCloudForm.productUrlTemplate
    ? generateSampleProductUrl(commerceCloudForm.productUrlTemplate)
    : generateSampleProductUrl(commerceCloudForm.storeUrl || 'https://www.mystorename.com')

  const uploadLogoComponent = useMemo(() => {
    const storeLogoUrl = customStoreForm.storeLogoUrl || commerceCloudForm.storeLogoUrl || shopifyForm.storeLogoUrl
    return (
      <Box marginTop={2} flexDirection="column" display="flex">
        <Typography variant="caption" color={errors?.storeLogoUrl ? 'error' : 'primary'}>
          Store Logo
        </Typography>
        <Typography variant="caption" className={styles.instructions}>
          Logo dimensions would be the maximum height 33px and width 143px if your logo is larger, it will be resized.
        </Typography>
        {((editingIntegration &&
          (isTypeName(editingIntegration, 'CustomStoreIntegration') ||
            isTypeName(editingIntegration, 'CommerceCloudIntegration') ||
            isTypeName(editingIntegration, 'ShopifyIntegration'))) ||
          [IntegrationType.Shopify, IntegrationType.CommerceCloud, IntegrationType.CustomStore].includes(
            selectedIntegrationType,
          )) &&
        storeLogoUrl ? (
          <Box className={styles.mediaContainer}>
            <CardMedia
              component={'img'}
              className={styles.media}
              src={storeLogoUrl}
              onError={() => setErrors({ ...errors, storeLogoUrl: true })}
            />
            <Button
              onClick={() => {
                if (editingIntegration?.__typename === 'CustomStoreIntegration') {
                  setCustomStoreForm(prev => ({ ...prev, storeLogoUrl: '', lcRelativeLogoUrl: '' }))
                }
                if (editingIntegration?.__typename === 'CommerceCloudIntegration') {
                  setCommerceCloudForm(prev => ({
                    ...prev,
                    lcRelativeLogoUrl: '',
                    storeLogoUrl: '',
                  }))
                }
                if (editingIntegration?.__typename === 'ShopifyIntegration') {
                  setShopifyForm(prev => ({
                    ...prev,
                    lcRelativeLogoUrl: '',
                    storeLogoUrl: '',
                  }))
                }
              }}
              color="primary"
            >
              Remove
            </Button>
          </Box>
        ) : (
          <Box
            {...getRootProps()}
            width={348}
            height={132}
            px={8}
            className={styles.dropzone}
            display="flex"
            alignItems="center"
            textAlign="center"
            justifyContent="center"
          >
            {!errors?.storeLogoUrl && (
              <Box display="flex" flexDirection="column">
                <input {...getInputProps()} />
                <Typography variant="body1">
                  Drag and drop your Store Logo or <span className={styles.looksLikeLink}>browse</span> your files.
                </Typography>
              </Box>
            )}
            {errors?.storeLogoUrl && (
              <Box
                className={styles.errorContainer}
                onClick={() => {
                  setErrors({ ...errors, storeLogoUrl: false })
                }}
              >
                <Box>
                  <div className={styles.circle}>
                    <CrossMark className={styles.errorIcon} />
                  </div>
                </Box>
                <Box>
                  <Typography variant="body1" className={styles.errorText}>
                    Upload unsuccessful.
                  </Typography>
                  <Typography variant="body1" className={styles.errorText}>
                    Please try again.
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    )
  }, [
    editingIntegration,
    errors,
    getRootProps,
    getInputProps,
    setCustomStoreForm,
    setCommerceCloudForm,
    setShopifyForm,
    styles,
    selectedIntegrationType,
    customStoreForm,
    commerceCloudForm,
    shopifyForm,
  ])

  const renderKeepUnattributtedOrdersSwitch = (
    form: ShopifyForm | CommerceCloudStoreForm | CustomStoreForm,
    setForm: () => void,
  ) => {
    if (!hasKeepAttributtedOrderSwitch) return null
    return (
      <Box marginTop={2}>
        <Typography variant="caption" color="primary" style={{ marginRight: 4 }}>
          Keep Unattributed Order Data
        </Typography>
        <SwitchComponent checked={form.keepUnattributedOrders} onChange={setForm} />
        <Box>
          <Typography variant="caption" className={styles.instructions}>
            Turn this setting off to discard all order data that can't be attributed to a program member.
          </Typography>
        </Box>
        <Box>
          <Typography variant="caption" className={styles.instructions}>
            Some LoudCrowd analytics and reporting features might be unavailable without this data.
          </Typography>
        </Box>
      </Box>
    )
  }

  const renderSegmentSelector = (setValue: (value: string | number) => void) => (
    <Box marginTop={2} className={styles.selectSegment}>
      <Typography variant="subtitle2">Influencer List Page</Typography>
      <SelectField
        setValue={setValue}
        options={[defaultSegment, ...segments.map(i => ({ id: i.id, label: i.name }))]}
        value={
          (editingIntegration as ShopifyIntegration | CustomStoreIntegration | CommerceCloudIntegration)
            ?.ilpFilterSegment?.id ?? defaultSegment.id
        }
        label="Select which storefront creators to display on your page"
        name="segment"
      />
    </Box>
  )

  return (
    <Dialog
      open={open}
      onClose={() => {
        onCancel()
        handleReset()
      }}
      maxWidth="lg"
    >
      <DialogContent style={{ padding: 0, width: 720 }}>
        <Box>
          <Box display="flex">
            <Box minWidth={'20%'} paddingTop={10} className={styles.sidebar}>
              {Object.keys(integrationModalDataMap).map(ecommTypeStr => {
                const ecommType = ecommTypeStr as EcommIntegrationTypes
                if (ecommType === IntegrationType.Google && !hasGoogleMarketIntegration) return null
                const Icon = integrationModalDataMap[ecommType].icon
                return (
                  <Box
                    key={ecommTypeStr}
                    className={selectedIntegrationType === ecommType ? styles.selected : ''}
                    display="flex"
                    justifyContent="center"
                    paddingTop={5}
                    paddingBottom={5}
                    onClick={() => {
                      setSelectedIntegrationType(ecommType)
                      setCustomStoreForm(initialStateCustomStoreForm)
                      setCommerceCloudForm(initialStateCommerceCloudForm)
                      setShopifyForm(initialStateShopifyForm)
                      setErrors({})
                    }}
                  >
                    <Icon className={styles.ecommIcon} width={42} height={42} />
                  </Box>
                )
              })}
            </Box>
            <Box width="100%" padding="2em" display="flex" flexDirection="column" justifyContent="center">
              <Box display="flex" flexDirection="column" width={504}>
                <Box marginTop={3} marginBottom={2}>
                  <Typography variant="h5">{integrationModalDataMap[selectedIntegrationType].title}</Typography>
                </Box>
                <Box>
                  {integrationModalDataMap[selectedIntegrationType].description.map((paragraph: string) => (
                    <Typography key={JSON.stringify(paragraph)}>{paragraph}</Typography>
                  ))}
                  {selectedIntegrationType === IntegrationType.Shopify && editingIntegration && (
                    <Box marginTop={2}>
                      <TextField
                        label="Store Display Name"
                        type="text"
                        value={shopifyForm.displayName}
                        onChange={handleShopifyFormChange}
                        name="displayName"
                        required
                        className={styles.ecommSelector}
                        helperText="This is the name of the store your ambassadors will see."
                      />
                    </Box>
                  )}
                  {selectedIntegrationType === IntegrationType.Shopify &&
                    editingIntegration &&
                    renderKeepUnattributtedOrdersSwitch(shopifyForm, () =>
                      setShopifyForm(sf => ({ ...sf, keepUnattributedOrders: !shopifyForm.keepUnattributedOrders })),
                    )}
                  {selectedIntegrationType === IntegrationType.Shopify && editingIntegration && (
                    <>
                      {uploadLogoComponent}

                      {renderSegmentSelector(value =>
                        setShopifyForm(sf => ({ ...sf, ilpFilterSegmentId: value as string })),
                      )}
                    </>
                  )}
                  {selectedIntegrationType === IntegrationType.Google && (
                    <TextField
                      label="Google Merchant Id"
                      type="text"
                      value={googleMerchantId}
                      onChange={({ target: { value } }) => setGoogleMerchantId(value)}
                    />
                  )}
                  {selectedIntegrationType === IntegrationType.CustomStore && (
                    <Box display="flex" flexDirection="column" width={504}>
                      <Box>
                        <TextField
                          label="Store Url"
                          type="text"
                          value={customStoreForm.storeUrl}
                          onChange={handleCustomStoreFormChange}
                          name="storeUrl"
                          required
                          className={styles.ecommSelector}
                          helperText="This is the base URL of your website, ex. https://www.mystorename.com"
                          error={errors?.storeUrl || false}
                        />
                        {errors?.storeUrl && (
                          <Typography variant="caption" color="error">
                            Please enter a valid URL
                          </Typography>
                        )}
                      </Box>
                      <Box marginTop={2}>
                        <TextField
                          label="Store Display Name"
                          type="text"
                          value={customStoreForm.displayName}
                          onChange={handleCustomStoreFormChange}
                          name="displayName"
                          required
                          className={styles.ecommSelector}
                          helperText="This is the name of the store your ambassadors will see."
                        />
                      </Box>
                      {renderKeepUnattributtedOrdersSwitch(customStoreForm, () =>
                        setCustomStoreForm(csf => ({
                          ...csf,
                          keepUnattributedOrders: !customStoreForm.keepUnattributedOrders,
                        })),
                      )}
                      <Box marginTop={2}>
                        <TextField
                          label="Product Id Regex"
                          type="text"
                          value={customStoreForm.productIdRegex}
                          onChange={handleCustomStoreFormChange}
                          name="productIdRegex"
                          required
                          className={styles.ecommSelector}
                          helperText="Ensure you escape backslash with another backslash, ex. \\\\d+"
                          error={errors?.productIdRegex || false}
                        />
                        {errors?.productIdRegex && (
                          <Typography variant="caption" color="error">
                            Please enter a valid regex
                          </Typography>
                        )}
                      </Box>
                      <Box marginTop={2}>
                        <SelectField
                          setValue={value => setCustomStoreForm({ ...customStoreForm, customSource: value as string })}
                          options={integrations
                            ?.filter(i => i.objectType === ClientSftpObjectType.Products)
                            .map(i => ({ id: i.id, label: i.name }))}
                          value={customStoreForm.customSource}
                          label="Custom Product Source"
                          name="customSource"
                          hideSearch
                        />
                      </Box>
                      {uploadLogoComponent}
                      {renderSegmentSelector(value =>
                        setCustomStoreForm(csf => ({ ...csf, ilpFilterSegmentId: value as string })),
                      )}
                    </Box>
                  )}
                  {selectedIntegrationType === IntegrationType.CommerceCloud && (
                    <Box display="flex" flexDirection="column" width={504}>
                      <Box>
                        <TextField
                          label="Store Url"
                          type="text"
                          value={commerceCloudForm.storeUrl}
                          onChange={handleCommerceCloudFormChange}
                          name="storeUrl"
                          required
                          className={styles.ecommSelector}
                          helperText="This is the base URL of your website, ex. https://www.mystorename.com"
                          error={errors?.storeUrl || false}
                        />
                        {errors?.storeUrl && (
                          <Typography variant="caption" color="error">
                            Please enter a valid URL
                          </Typography>
                        )}
                      </Box>
                      <Box marginTop={2}>
                        <TextField
                          label="Store Display Name"
                          type="text"
                          value={commerceCloudForm.displayName}
                          onChange={handleCommerceCloudFormChange}
                          name="displayName"
                          required
                          className={styles.ecommSelector}
                          helperText="This is the name of the store your ambassadors will see."
                        />
                      </Box>
                      {renderKeepUnattributtedOrdersSwitch(commerceCloudForm, () =>
                        setCommerceCloudForm(ccf => ({
                          ...ccf,
                          keepUnattributedOrders: !commerceCloudForm.keepUnattributedOrders,
                        })),
                      )}
                      <Box marginTop={2}>
                        <TextField
                          label="Product Id Regex"
                          type="text"
                          value={commerceCloudForm.productIdRegex}
                          onChange={handleCommerceCloudFormChange}
                          name="productIdRegex"
                          required
                          className={styles.ecommSelector}
                          helperText="Ensure you escape backslash with another backslash, ex. \\\\d+"
                          error={errors?.productIdRegex || false}
                        />
                        {errors?.productIdRegex && (
                          <Typography variant="caption" color="error">
                            Please enter a valid regex
                          </Typography>
                        )}
                      </Box>
                      <Box marginTop={2}>
                        <TextField
                          label="Product URL Template"
                          type="text"
                          value={commerceCloudForm.productUrlTemplate}
                          onChange={handleCommerceCloudFormChange}
                          name="productUrlTemplate"
                          required
                          className={styles.ecommSelector}
                          helperText={`Template used to generate Product URLs from Product IDs. ${exampleProductUrlTemplateHelper}`}
                          error={errors?.productUrlTemplate || false}
                        />
                        {errors?.productUrlTemplate && (
                          <Typography variant="caption" color="error">
                            Please enter a valid template
                          </Typography>
                        )}
                      </Box>
                      <Box marginTop={2}>
                        <TextField
                          label="Short Code"
                          type="text"
                          value={commerceCloudForm.shortCode}
                          onChange={handleCommerceCloudFormChange}
                          name="shortCode"
                          required
                          className={styles.ecommSelector}
                          helperText="Login to business manager and go to Administration > Site Development >  Salesforce Commerce API Settings. Copy the Short Code in this page."
                          error={errors?.shortCode || false}
                        />
                      </Box>
                      <Box marginTop={2}>
                        <TextField
                          label="Organization ID"
                          type="text"
                          value={commerceCloudForm.organizationId}
                          onChange={handleCommerceCloudFormChange}
                          name="organizationId"
                          required
                          className={styles.ecommSelector}
                          helperText="Login to business manager and go to Administration > Site Development >  Salesforce Commerce API Settings. Copy the Organization ID in this page."
                          error={errors?.organizationId || false}
                        />
                      </Box>
                      <Box marginTop={2}>
                        <TextField
                          label="Site ID"
                          type="text"
                          value={commerceCloudForm.siteId}
                          onChange={handleCommerceCloudFormChange}
                          name="siteId"
                          required
                          className={styles.ecommSelector}
                          helperText="Login to business manager and go to Administration > Sites > Manage Sites. Copy the ID of the site."
                          error={errors?.siteId || false}
                        />
                      </Box>
                      <Box marginTop={2}>
                        <TextField
                          label="Client ID"
                          type="text"
                          value={commerceCloudForm.clientId}
                          onChange={handleCommerceCloudFormChange}
                          name="clientId"
                          required
                          className={styles.ecommSelector}
                          helperText="The Client ID of the API Client you created in Salesforce Account Manager."
                          error={errors?.clientId || false}
                        />
                      </Box>
                      <Box marginTop={2}>
                        <TextField
                          label="Client Secret"
                          type="password"
                          value={commerceCloudForm.clientSecret}
                          onChange={handleCommerceCloudFormChange}
                          name="clientSecret"
                          required
                          className={styles.ecommSelector}
                          helperText="The Client Secret of the API Client you created in Salesforce Account Manager."
                          error={errors?.clientSecret || false}
                          placeholder={!editingIntegration ? undefined : '*****************************'}
                          focused={!!editingIntegration}
                        />
                      </Box>
                      {uploadLogoComponent}
                      {renderSegmentSelector(value =>
                        setCommerceCloudForm(ccf => ({ ...ccf, ilpFilterSegmentId: value as string })),
                      )}
                    </Box>
                  )}
                  {integrationModalDataMap[selectedIntegrationType].authSteps && (
                    <>
                      <Typography style={{ marginTop: 10, fontWeight: 'bold' }}> Authentication Steps </Typography>
                      <List style={{ marginLeft: 20 }}>
                        {integrationModalDataMap[selectedIntegrationType].authSteps?.map(
                          (step: string, idx: number) => (
                            <ListItem disableGutters key={idx}>
                              <Typography>{`${idx + 1}. ${step}`}</Typography>
                            </ListItem>
                          ),
                        )}
                      </List>
                    </>
                  )}
                </Box>
              </Box>
              <Box marginTop={5}>
                <DialogActions>
                  {!!editingIntegration && isTypeName(editingIntegration, 'CommerceCloudIntegration') && (
                    <Box className={styles.removeButtonContainer} onClick={() => setRemoveIntegrationDialogOpen(true)}>
                      <Button className={styles.removeButton}>Remove Integration</Button>
                    </Box>
                  )}
                  <Button
                    onClick={() => {
                      onCancel()
                      handleReset()
                    }}
                    size="large"
                    variant="outlined"
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={integrationModalDataMap[selectedIntegrationType].connectHandler}
                    disabled={
                      selectedIntegrationType === IntegrationType.CustomStore &&
                      (errors?.productIdRegex || errors?.storeUrl)
                    }
                  >
                    {editingIntegration ? 'Update' : 'Connect'}
                  </Button>
                </DialogActions>
              </Box>
            </Box>
          </Box>
        </Box>
        <RemoveIntegrationDialog
          open={removeIntegrationDialogOpen}
          name={editingIntegration?.name || ''}
          handleOnClose={() => setRemoveIntegrationDialogOpen(false)}
          handleConfirm={() => handleInvalidateIntegration()}
        />
      </DialogContent>
    </Dialog>
  )
}

interface RemoveIntegrationDialogProps {
  open: boolean
  name: string
  handleOnClose(): void
  handleConfirm(): void
}

function RemoveIntegrationDialog({ open, name, handleConfirm, handleOnClose }: RemoveIntegrationDialogProps) {
  const modalClose = () => {
    handleOnClose()
  }

  const handleSubmit = () => {
    handleConfirm()
    handleOnClose()
  }

  return (
    <Dialog open={open} onClose={modalClose}>
      <Box width={600}>
        <DialogTitle>Remove Integration?</DialogTitle>
        <DialogContent>
          <Box pb={5}>Are you sure you want to remove {name}?</Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={modalClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            OK
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default EcommIntegrationModal
